import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import { keyUri, config } from '../key';
import {logoutVendor} from './auth'

const initialState = {
    
    offer:null,
    loading:false,
    hasError:false,
    currentOffer:[],
    alloffer:[]

}


export const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
  
      getOffer: state => {
        state.loading = true;
      },

      getOfferSuccess: (state, {payload}) => {

        state.loading = false
        state.alloffer = payload
      },
  
      getCurrentOffer:(state, {payload}) => {
        state.loading = false
        state.offer = payload.offer
      },
  
      getOfferFailure: (state) => {
  
        state.loading = false
        state.hasError = true
      },
  
    },
  });
  

export const { getOffer , getCurrentOffer, getOfferSuccess, getOfferFailure } = offerSlice.actions;
export const offerSelector = state => state.offer;


  
  export const fetchOffer = (id) => async dispatch => {
 
    dispatch(getOffer())
   try {
    const {data} = await axios.get(keyUri.BACKEND_URI + `/offers/${id}`, config)
    dispatch(getOfferSuccess(data));
     
   } catch (error) {
  dispatch(getOfferFailure())
   }
  }
  

  export const fetchCurrentOffer = (id) => async dispatch => {
      dispatch(getOffer())
      try {
     
       const {data} = await axios.get(keyUri.BACKEND_URI +`/offer/${id}`, config)
    
       dispatch(getCurrentOffer(data));
      } catch (error) {
    
     dispatch(getOfferFailure())
     
      }
     };
    



  export const createOffer = (values, id) => async dispatch => {
    dispatch(getOffer())  
    const key = 'create';
      message.loading({ content: 'loading...', key })
   
    try {
   
     const {data} = await axios.post(keyUri.BACKEND_URI +`/offer/${id}`,values,config)
     data && message.success({content:data.msg, key, duration:2})
     dispatch(fetchOffer(data))
     window.location.reload()
  
    } catch ({response}) {

 
   response.data && message.error({content:response.data.msg, key, duration:2})
   response.data && dispatch(logoutVendor())

    }
   }
  


export const deleteOffer = (id) => async dispatch =>{
    dispatch(getOffer())

    const key = 'create';
    message.loading({ content: 'loading...', key })

try {
    const {data} = await axios.delete(keyUri.BACKEND_URI +`/offer/${id}`, config)
    window.location.reload()
    data && message.success({content:data.msg, key, duration:2})

} catch (response) {
  dispatch(getOfferFailure())
  response.data && message.error({content:response.data.msg, key, duration:2})
  response.data && dispatch(logoutVendor())

}
}


export const  updateOffer = (id, values) => async dispatch =>{


    const key = "offer"
    dispatch(getOffer())
    message.loading({ content: 'loading...', key })
  
  try {
      const {data} = await axios.put(keyUri.BACKEND_URI +`/offer/${id}`, values, config);
      data && message.success({ content: data.msg, key, duration: 2 });
      dispatch(fetchOffer())
  
  } catch ({response}) {
      dispatch(getOfferFailure())
      response.data && message.error({ content: response.data.msg, key, duration: 2 });
      response.data && dispatch(logoutVendor())
  
  }
  }
  
  export default offerSlice.reducer;
  