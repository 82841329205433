import React, {useEffect, useState} from 'react'
import socketIOClient from "socket.io-client";
import { Form, Input, Button, Checkbox } from 'antd';
import styled from 'styled-components'
import { MdSend } from 'react-icons/md';
import moment from 'moment'

const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };



export default function Chat({orderId}) {

  const id = localStorage.getItem('id') ?
  JSON.parse(localStorage.getItem('id')) : null

    const socket = socketIOClient('http://localhost:5000',{ transports: ['websocket', 'polling', 'flashsocket'] });

const [msg, setMsg] = useState([])
 const [cid, setCid] = useState(null)

const [form] = Form.useForm();



    useEffect(() => {
      
      orderId && socket.on(orderId._id, data => {

          setMsg(prev => [...prev, data])
          setCid(data.customer)
         
          
        });
      }, []);


      const onFinish = (values) => {
        
        let data = {

          isVendor:true,
          store:id,
          customer:cid,
          time:moment().format(' h:mm a'),
          msg:values.msg

        }

        socket.emit("message", data)

        form.resetFields();

    
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };


    return (
        <ChatWrap>
            <h3 className="text-secondary">Customer Chat!</h3>
<div className="chat px-3 py-2">
{

    msg.map((item, i)=>{

      return <div  key={i} >

<div className="msgBox my-2" style={{
  float:item.isVendor? "left" : "right",
       backgroundColor:item.isVendor? "rgb(230 243 255)" : "#e0f3e3",
       clipPath:item.isVendor? "polygon(50% 0%, 100% 0px, 100% 13%, 100% 100%, 4% 100%, 4% 24%, 0px 0px)" : 
       "polygon(50% 0%, 100% 0, 94% 29%, 94% 100%, 0 100%, 0% 60%, 0 0)",
       
   padding:item.isVendor? "0px 0.5rem 0px 1rem" : "0 1rem 0 0.5rem",


  }} >

   
<h6 className="text-secondary mb-0  p-1" >
    <small>{item.isVendor? "vendor" : "customer"}</small>: &nbsp;{item.msg}</h6>
    <small>{item.time}</small>

   </div>
  </div>

    })
}

</div>
            <Form
      {...layout}
      style={{width:"600px"}}
      form={form}
      className="textchat"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="msg"
        className="mt-3"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input
        className="p-0 pl-2 text"
        
        suffix={
                   <Button style={{height:"100%"}}   type="primary" htmlType="submit">

            <MdSend  style={{ color: 'white', fontSize:"1.5rem" }} />
         </Button>
        
        }
        
        
        placeholder="text here"/>
      </Form.Item>


      {/* <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item> */}
    </Form>

        </ChatWrap>
    )
}


const ChatWrap = styled.div`
height:100%;

.textchat {

position:absolute;
bottom:-6%;
left:0;
width:100%;

}

overflow:hidden;

@media(max-width:960px) {

.chat{
  width:100%;
  background-color:#f5f5f5;

}
    
}
/* .chat{
  width:600px;
  background-color:#f5f5f5;

} */


.ant-input-affix-wrapper {

    color: rgba(0, 0, 0, 0.85);
    font-size: 30px;

    box-shadow: 0px -1px 4px 1px #f5eaea;
    border: none;

}


.msgBox{


  display:inline-block;
    border-radius: 0.2rem;
    /* padding: 0 1.5rem 0 0.5rem; */
    margin: 0.5rem;
    clear: both;
}
`