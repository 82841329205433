import React from 'react'
import { Table,  Space, Image,Row } from 'antd';
import {useDispatch} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
import {deleteOffer} from '../../../api/offer'
import { Descriptions } from 'antd';
import { keyUri, config } from '../../../key';

export default function Datatable({data, intialdata}) {

        const dispatch = useDispatch()

        const confirm = (e, id) => {
            dispatch(deleteOffer(id._id))
           
          }
          
          const cancel = (e) =>{
            return null
          }



    const columns = [
        {
          title: 'Offer Title',
          dataIndex: 'offer_title',
          key: 'offer_title',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        {
          title: 'Current price',
          dataIndex: 'current_price',
          key: 'current_price',
          render:(current_price) =>{

            return <p>&#x20B9;{current_price} </p>
          }
        },
        {
          title: 'Offer %',
          dataIndex: 'offer',
          key: 'offer',
          render:(offer) =>{
            return <p>{offer}%</p>
          }
        },

        {
          title: 'Offer Price',
          dataIndex: 'offer_price',
          key: 'offer_price',
          render:(offer, total) =>{
            return <p>&#x20B9;{parseInt((total.current_price*((100- total.offer)/(100))))} </p>
          }  
        },

        {
          title: 'Offer Image',
          dataIndex: 'offer_image',
          key: 'offer_image',
          render:(offer_image) =>{

            return  <Image
              
              width="30px"
              src={keyUri.BACKEND_URI +`/offer-image/${offer_image}`}
                placeholder={
                  <Image
                    preview={false}
                    src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif"
                    width="100%"
                    
                  />
                }
              />
          }               
        },

        {
          title: 'Action',
          key: 'action',
          render: (id) => {


          return  <Space size="middle">
              
              <h5> 
               <Link to={`/dashboard/edit-offer/${id._id}`}>
                 <div >
                <FaRegEdit/> 
                 </div>
                 </Link> 
              </h5>

             <h5 className="text-danger">
                <DeleteConfirm confirm={(e)=>confirm(e, id)} title="offer" cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];


    return (
        <Fade duration = {2000}>
          { <Table  
           rowKey={record => record._id} 
           columns={columns}
           style={{overflowY:'auto', height:'75vh', }}
          

           expandable={{
            expandedRowRender: record => {
              return <div>
              <Descriptions title="Product">
  
        {
          record.offer_product.map((item,i)=>{

              return <div key={i} width="400px" className="d-flex shadow-sm p-2 my-2 align-items-center justify-content-between" >
              
                <div style={{paddingRight:'20px' }}>
                <Image width="20px" src={keyUri.BACKEND_URI +`/product-image/${item.image}`}
                  placeholder={
                   <Image
                    preview={false}
                    src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif"
                    width="100%"
                   />
              }
               />
                </div>
                <div style={{paddingRight:'40px' }}>
                    <b> {item.product_name || "null"}</b>
                </div>
                <div style={{ position: 'relative', align: 'right'  }}>
                      <b>&#x20B9; {item.sale_price|| "null"}</b>
                </div>
              </div>       
           
})
  }
    
<div style={{paddingLeft:'40px'}}>
<b><h6>Offer Description: </h6> {record.offer_Discription } </b>
</div>

<div style={{paddingLeft:'40px' }}>
<b> <h6>Offer Link: </h6>{record.link }</b>
</div>



              </Descriptions>
              </div>
          }, 
            rowExpandable: record => record.combo  !== null,
          }}
      
           dataSource={data.length < 1 ? intialdata : data } />}  
        </Fade>
    )
}
