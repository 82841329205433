import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../shared/loader'
import DataTable from './datatable'
import {vendorAuthSelector} from '../../../api/auth'
import { productsSelector,fetchAllOrders, fetchUsers} from '../../../api/product'


export default function Customer() {
    const {vendor} = useSelector(vendorAuthSelector)

    const {users, loading} = useSelector(productsSelector)

    users && console.log(users);

    const dispatch = useDispatch()
    
    useEffect(()=>{
      dispatch(fetchUsers(vendor._id))
      vendor && dispatch(fetchAllOrders(vendor._id))

    },[dispatch])
  

    return (
        <div>
            {/* <div className="d-flex  mb-4 align-items-center justify-content-between">
                <h5>Customers</h5>
                </div>           */}
                  {      
                    loading ? <Loader/> : <DataTable intialdata={users} data={ users }/>
                  } 
    </div>
    )
}
