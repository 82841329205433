import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Avatar, Badge} from 'antd'
import socketIOClient from "socket.io-client";
import {useParams} from 'react-router-dom'
import {notification} from 'antd'

import { WechatOutlined } from '@ant-design/icons';
// import Chat from './components/pages/chat';
import { Form, Input,  Drawer, Button } from 'antd';
import styled from 'styled-components'
import { MdSend } from 'react-icons/md';
import moment from 'moment'
import {keyUri} from '../../../key';
import {productsSelector, fetchChatNotification} from '../../../api/product'
import { FaWhatsapp } from 'react-icons/fa';


const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 24,
    },
  };



export default function Vendorchat() {

    const [visible, setVisible] = useState(false);
    const socket = socketIOClient(keyUri.SOCKET_URI,{ transports: ['websocket', 'polling', 'flashsocket'] });
    const [msg, setMsg] = useState([])
    const [cid, setCid] = useState(null)
   const [count, setCount] = useState(0)
   const [form] = Form.useForm();
   const {id} = useParams()
   
   const dispatch = useDispatch()
   const {chatNotication } = useSelector(productsSelector)

   useEffect(() => {
     socket.on(`${id}`, data => {
   

     setCount(prev=> prev + 1)
       setVisible(true)
       dispatch(fetchChatNotification(data));
       setMsg(prev => [...prev, data])
       setCid(data.customer)
       
        
      });
    }, []);


  
    return (
        <VendorChatWrap>
          
      <div className="vendorChat" >

      <a href="https://api.whatsapp.com/send?phone=8431787968">
<Avatar className="chatbtn bg-success"  size={45} icon={<FaWhatsapp 
 style={{transform:"translateY(-1.5px)", fontSize:"2rem"}}/>} />

 </a>


   </div>

        </VendorChatWrap>
    )
}


const VendorChatWrap = styled.div`

.chat{
   height: 50vh;
    overflow: auto;
    width:300px;
 

}

/* position:relative; */


.ant-input-affix-wrapper {

color: rgba(0, 0, 0, 0.85);
font-size: 30px;

box-shadow: 0px -1px 4px 1px #f5eaea;
border: none;

}


.msgBox{


display:inline-block;
border-radius: 0.2rem;
/* padding: 0 1.5rem 0 0.5rem; */
margin: 0.5rem;
clear: both !important;
}
`





  
            {/* <Drawer
        title="Chat Section vendor"
        placement="right"
        width={320}
        closable={false}
        onClose={onClose}
        visible={visible}
      >


<h3 className="text-secondary"></h3>
<div className="chat px-3 py-2">
{

msg.map((item, i)=>{

      return <div  key={i} >

<div className="msgBox my-2" style={{
//   float:item.isVendor? "left" : "right",
       backgroundColor:item.isVendor? "rgb(230 243 255)" : "#e0f3e3",
       clipPath:item.isVendor? "polygon(50% 0%, 100% 0px, 100% 13%, 100% 100%, 4% 100%, 4% 24%, 0px 0px)" : 
       "polygon(50% 0%, 100% 0, 94% 29%, 94% 100%, 0 100%, 0% 60%, 0 0)",
       
   padding:item.isVendor? "0px 0.5rem 0px 1rem" : "0 1rem 0 0.5rem",


  }} >

   
<h6 className="text-secondary mb-0  p-1" >
    <small>{item.isVendor? "vendor" : "customer"}</small>: &nbsp;{item.msg}</h6>
    <small>{item.time}</small>

   </div>
  </div>

    })
}

</div>   
      
      <Form
      {...layout}
    
      form={form}
      name="basic"
     style={{position:'absolute', bottom:"5%", width:"90%"}}

      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="msg"
        className="mt-3"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input
        className="p-0 pl-2 text"
        
        suffix={
                   <Button style={{height:"100%"}}   type="primary" htmlType="submit">

            <MdSend  style={{ color: 'white', fontSize:"1.5rem" }} />
         </Button>
        
        }
        
        
        placeholder="text here"/>
      </Form.Item>


      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item> 
    </Form>




      </Drawer> */}
