import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import { keyUri, config } from '../key';


let token = localStorage.getItem('token')? localStorage.getItem('token'): null

const initialState = {

    vendor:null,
    loading:false,
    hasError:false,
    categories: [],
    token:token ,
    isValid:false,
    isAuthenticate:token ? true : false,
 

}


export const vendorAuthSlice = createSlice({
  name: 'vendorAuth',
  initialState,
  reducers: {

    getVendorLogin: state => {
      state.loading = true;
    },
    getVendorLoginSuccess: (state, {payload}) => {

      state.loading = false
      state.token = payload.token
      state.isValid = payload.isValid
      state.isAuthenticate = payload.isValid ? true : false
 

    },
getVendorProfile: (state, {payload}) =>{


  state.loading = false
  state.vendor = payload.store;
  state.isValid = payload.isValid
  state.isAuthenticate = payload.isValid ? true : false

},

    getVendorLoginFailure: (state) => {

      state.loading = false
      state.hasError = true
    },

  },
});

export const { getVendorLogin , getVendorProfile, getVendorLoginSuccess,   getVendorLoginFailure } = vendorAuthSlice.actions;
export const vendorAuthSelector = state => state.vendor_auth;




export const fetchVendorlogin = (values) => async dispatch => {

  dispatch(getVendorLogin())

  const key = 'login';
    message.loading({ content: 'loading...', key })

 try {

  const {data} = await axios.post(keyUri.BACKEND_URI + '/storeAuth', values, {   headers: { Accept: "application/json"
}})
 data && message.success({content:data.msg, key, duration:2})

    localStorage.setItem("token", data.token)
 

  dispatch(getVendorLoginSuccess(data));
   
 } catch ({response}) {


    response.data && message.error({content:response.data.msg, key, duration:2})

dispatch(getVendorLoginFailure())

   
 }
}

export const logoutVendor = ()=> async dispatch=>{
    try {
        localStorage.removeItem('token')

        window.location.reload()
    } catch (error) {
        dispatch(getVendorLoginFailure())
    }
}


export const fetchVendorProfile = (token)=> async dispatch=>{

  const loginConfig  = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

   dispatch(getVendorLogin())
    try {
      
      const {data} = await axios.get(keyUri.BACKEND_URI + '/store-profile', loginConfig)


      dispatch(getVendorProfile(data));


    } catch (error) {
    
    
            dispatch(logoutVendor())

        dispatch(getVendorLoginFailure())
    }
}



export default vendorAuthSlice.reducer;



