const keyUri = {

    BACKEND_URI:"https://piklocal-server-yr7b5.ondigitalocean.app/api",
    // BACKEND_URI:"http://localhost:5000/api",

    SOCKET_URI:"https://piklocal-server-yr7b5.ondigitalocean.app",
    // SOCKET_URI:"http://localhost:5000",

    BACKEND_IMAGE_API : "https://piklocal-server-yr7b5.ondigitalocean.app/static/images"
    // BACKEND_IMAGE_API : "http://localhost:5000/static/images"

}


let token = localStorage.getItem('token')

const config = {
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    }
  };



  export  {keyUri, config }
  
