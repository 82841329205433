import React,{useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import { productsSelector} from '../../../api/product'
import { Modal, Button } from 'antd';
import ProductTable from '../../shared/insidetableview'
import { AiFillEye } from 'react-icons/ai';


export default function Insidetableview({data, store}) {

   
    const dispatch = useDispatch();
    
    const {orders} = useSelector(productsSelector)
    const [product, setProduct] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const of_user = orders.filter((item)=>{
        return item.offline_userPhone === data
    })

    const showModal = (item) => {
      setIsModalVisible(true);
      setProduct(item.productOrder)
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };





    return (
        <div>
           <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Order ID </th>
      <th scope="col">Date</th>
    </tr>
  </thead> 
  <tbody>
      {
of_user.map((item, i)=>{

return  <tr onClick={()=> showModal(item)}>
<th scope="row">{i+1}</th>
<td>{item.orderId}</td>
<td>{ moment(item.createdAt).format("MMM Do YYYY")}</td>
<td  style={{color:'gray'}}>{<AiFillEye/>}</td>

</tr>

})

      }
  </tbody>
</table>

    <Modal title="Products"
    visible={isModalVisible} 
    onOk={handleOk}
    onCancel={handleCancel}
    footer={false}
    >
        <ProductTable  isOffline={true} data = {product} />

      </Modal>



        </div>
    )
}
