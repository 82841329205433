import React, { useEffect, useState } from 'react'
import { Table,Button, Space, Image, Row,InputNumber, Col,Form, Modal } from 'antd';
import {useDispatch, useSelector} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import { productsSelector, posArrayProduct} from '../../../api/product'

import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
import { Descriptions } from 'antd';
import {keyUri, config} from '../../../key'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

export default function Datatable({data, click}) {

  const {arrayProducts} = useSelector(productsSelector)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stock, setStock] = useState(0)
  const [product, setProduct] = useState(null)

  const [unit, setUnit] = useState(0)
  const [salePrice, setSalePrice] = useState(0)
  const [discount, setDiscount] = useState(0)

  const [form] = Form.useForm();


        const dispatch = useDispatch()

        // const confirm = (e, id, i, k) => {
        //   click(i)
        //   } 

          const confirm = (e, id) => {
            click(id._id)
            } 
               
          const cancel = (e) =>{
            return null
          }

 
          const showModal = (id) => {
             
            setIsModalVisible(true);
            setProduct(id)
            setStock(id.stock)
            setUnit(id.unit)
            // data.filter((item, j)=>{

            //   console.log(j);

            //   return  i !== j ? setUnit( id.unit) : item.unit
            // })
            setSalePrice(id.sale_price)
            setDiscount(id.discount)
            form.resetFields();
          };
          

          const closeModal = () => {
            setIsModalVisible(false);
            setProduct(null)
            setUnit(null)
            setSalePrice(null)
            setDiscount(null)
            form.resetFields();
      
          }
          
    
          const onFinish = async (values) =>{   
            let p={
              ...product, 
              unit:values.unit,
              sale_price:values.sale_price,
              discount:values.discount ? values.discount : 0
            }
            
            const afterEdit =  arrayProducts.map((item)=> {
                    if(item._id === p._id){                  
                       item = p 
                    }
                    return item
                     
          })
          dispatch(posArrayProduct(afterEdit))
          setProduct(null)
          setUnit(null)
          setSalePrice(null)
          setDiscount(null)
          form.resetFields();
              closeModal()
          }
      
 
      
        const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        };
      


    const columns = [
        {
          title: 'Product ID',
          dataIndex: 'barcode',
          key: 'barcode',
          render: text => <h6 style={{color:'var(--acentColor)'}} >{text}</h6>,
        },
        {
          title: 'Product Name',
          dataIndex: 'product_name',
          key: 'product_name',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity', 
        },
        
        {
          title: 'Units',
          dataIndex: 'unit',
          key: 'unit',
        },
        {
          title: 'Unit Price',
          dataIndex: 'sale_price',
          key: 'sale_price',
          render:(sale_price) =>{
            return <p>&#x20B9;{sale_price} </p>
          }   
        },
        // {
        //   title: 'MRP',
        //   dataIndex: 'MRP',
        //   key: 'MRP',
        //   render:(MRP) =>{
        //     return <p>&#x20B9;{MRP} </p>
        //   }

        // },

        {
          title: 'Discount',
          dataIndex: 'discount',
          key: 'discount',
          render:(discount) =>{
            return <p>{(discount ) }%</p>
          }  
        },

        {
          title: 'Tax',
          dataIndex: 'GST',
          key: 'GST',
          render:(GST) =>{
            return <p>&#x20B9;{GST} </p>
          }
 
        },
       
        {
          title: 'Net Amount',
          dataIndex: 'net_amount',
          key: 'net_amount',
          render:(unit, net) =>{


            return <p>&#x20B9;{parseInt(net.sale_price*(((100 - net.discount)/100)*net.unit) )}
             </p>

          }   
        },

        {
          title: 'Action',
          key: 'action',
          render: (id, k, i) => {
            
          return  <Space size="middle">             

              <h5 className="text-secondary" onClick={()=>showModal(id)}><FaRegEdit /> </h5>
          

            <h5 className="text-danger">
                <DeleteConfirm confirm={(e)=>confirm(e, id)} title="blog" cancel={cancel} >
                    <FaRegTrashAlt  style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];

      return (
        <>
        <Fade duration = {2000} bottom distance="10px">
          { <Table  
           rowKey={record => record._id} 
           columns={columns}
           dataSource={data.length < 1 ? data : data } 
           />}  
        </Fade>


        <Modal 
      title="Update Product"
      footer={false}
      visible={isModalVisible} 
      onCancel={closeModal} 
      closable = {false}
      >
        {/* <div className="d-flex mb-3 align-items-start justify-content-around"> */}
        {

     product && <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
         
          onFinishFailed={onFinishFailed}
          form={form}
            >

            <div className="d-flex mb-1 align-items-center justify-content-around">
                  <Image 
                    width="40px"
                    src={keyUri.BACKEND_URI +`/product-image/${product.image}`}
                    
                      placeholder={
                        <Image
                          preview={false}
                          src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif"
                          width="100%"
                        />
                      }
                    />
                    <h6>{product.product_name}</h6>
              <p>Current Stock : <b className="text-success">{ product.stock}</b></p>
            </div>
            <Form.Item
              label="Quantity"
              name="unit"
              style={{paddingTop:'20px'}}
              initialValue={unit}
              rules={[{ required: true, message: 'Enter Quantity' }]}
            >
              <InputNumber  min={1} max={stock} style={{ width: '30%' }}/>
            </Form.Item>

            <Form.Item
            label="Sales price"
            name="sale_price"
            initialValue={salePrice}
            rules={[{ required: true, message: 'Enter Sales price' }]}
            >
            <InputNumber style={{ width: '30%' }}/>
            </Form.Item>

            <Form.Item
              label="Discount %"
              name="discount"
              initialValue={discount}
              // rules={[{ required: true, message: 'Enter Discount' }]}

            >
              <InputNumber style={{ width: '30%' }}/>
            </Form.Item>

            <Form.Item {...tailLayout}>
            <Button type="primary"  htmlType="submit" > Submit</Button>
            </Form.Item>
              
            </Form>
}
      </Modal>
        </>
    )
}