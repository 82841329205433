import React from 'react'
import moment from 'moment'

export default function Insidetableview({data, isOffline=false}) {

  
    return (
        <div>
           <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Product</th>
      <th scope="col">Quanty</th>
      <th scope="col">Date</th>
    </tr>
  </thead> 
  <tbody>
      {
data.map((item, i)=>{
return  <tr>
<th scope="row">{i+1}</th>
<td>{isOffline ? item.name : item.product_name}</td>
<td> {item.quantity}</td>
<td>{ moment(item.createdAt).format("MMM Do YYYY")}</td>
</tr>

})

      }
  </tbody>
</table>
        </div>
    )
}
