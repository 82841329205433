import React, { useState } from 'react'
import {Table,Select, Space,Modal } from 'antd'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import { FaRegFilePdf, FaStreetView } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import timeFormate from 'moment'

import {useDispatch, useSelector} from 'react-redux'
import {createOrderPdf, updateOrder, productsSelector} from '../../../api/product'

const { Option } = Select;

export default function Datatable({data}) {

    const dispatch = useDispatch()

    
   
    const columns = [
        {
            title:"Name",
            render: (value)=>{
                if(value.user){ 
                    return  <p>Online User</p> 
                }     
               if(value.offline_userName) { 
                   return <p className="mb-0">{value.offline_userName}</p>
                }
                return null
            }
            
        },
        {
            title:"Order ID",
            dataIndex:"orderId",
            render:(orderId)=>{

                return <p className="text-secondary mb-0"><b>{orderId}</b></p>

            } 
           
        },
        {
            title:"Date",
            dataIndex:"createdAt",
            render:(createdAt)=>{

                return <p className="mb-0">{timeFormate(createdAt).format('MMM Do YY, h:mm a')}</p>

            }   
        },
       
        {
            title:"Phone No.",
            render: (value)=>{
                return <p className="mb-0">{value.offline_userPhone ? value.offline_userPhone : value.user ? value.user.phone_number : 'null'}</p> 

        
            }
        },  
        {
            title:"Payment Method",
            dataIndex:"payment_Method",

        },
        {
            title:"Delivery Method",
            render: (value)=>{
              
                if(value.offline_userPhone){

                    return <p>offline user</p>
                }
                return value.isTakeAway ==='homedelivery' ? <p className="mb-0 text-capitalize" style={{color:'red'}}>{value.isTakeAway}</p> :
            <p className="mb-0 text-capitalize" style={{color:'green'}}>{value.isTakeAway}</p> 
        
            }

        },
 
        {
            title:"Payment Status",
            dataIndex:"paymentStatus",
            render: (paymentStatus)=>{

                return paymentStatus !=='Paid' ? <p className="mb-0" style={{color:'red'}}>{paymentStatus}</p> :<p className="mb-0" style={{color:'green'}}>{paymentStatus}</p>
                }
             
        },
        {
            title:"Order Status",
            dataIndex:"orderStatus",
            render: (orderStatus)=>{

                return orderStatus ==='Unfulfilled' ? <p className="mb-0" style={{color:'red'}}>{orderStatus}</p> :<p className="mb-0" style={{color:'green'}}>{orderStatus}</p>
                }
        
        },


        {
            title:'Invoice',
            render: (value)=> {

                return  <Space size="middle">
                <h5><Link to={`/dashboard/order-view/${value._id}`}><div className="text-secondary"> <AiFillEye/> </div></Link></h5>



        <h5 className="text-danger"><FaRegFilePdf   onClick={()=>dispatch(createOrderPdf(value))}/>
       </h5>
              </Space>
                
            
            } 
         
        }
    ]



    return (
        <>
        <Fade duration = {1000} >
        <SDataModelWrap>
             <Table dataSource={data}
              columns={columns}      
            rowKey = {(record => record._id)}

            /> 

        </SDataModelWrap>

        </Fade>


        </>
   
    )
}


const SDataModelWrap = styled.div`


`