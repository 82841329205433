import { configureStore } from '@reduxjs/toolkit';

import supplierReducer from './supplier';
import productsReducer from './product'
import vendorReducer from './auth'

import comboReducer from './combo'
import offerReducer from './offer'
import settingsReducer from './settings'


export default configureStore({
  reducer: {
        suppliers:supplierReducer,
        products:productsReducer,
        vendor_auth:vendorReducer,
        settings: settingsReducer,
        combo:comboReducer,
        offer:offerReducer


  },
});
