import React from 'react'
import { Menu, Dropdown, Button } from 'antd';
import {logoutVendor} from '../../api/auth'
import {LogoutOutlined  } from '@ant-design/icons';

import {useDispatch} from 'react-redux'

export default function DropdownSec({username, userIcon, icon, history}) {

const dispatch = useDispatch()



    const menu = (
        <Menu>

<Menu.Item >
            <Button type="text" className="text-success text-capitalize" >
          Welcome &nbsp; <b>{username}</b> 
            </Button>
          </Menu.Item>

     <Menu.Item >
    
        <Button icon={<LogoutOutlined style={{ transform:'translateY(-2.5px)'}}/>} onClick={()=>{  dispatch(logoutVendor()) }} type="link" danger  >
      Logout
      </Button>
    


    {/* {
      isAdminAuthenticate && <Button onClick={()=>{

        dispatch(logoutAdmin())
      }} type="link" danger  >
     Logout Admin
      </Button>
    } */}
          
          </Menu.Item>
          
         
        </Menu>
      );



    return (
        <Dropdown overlay={menu}>
        <Button type="primary"  shape="circle" onClick={e => e.preventDefault()}>
       {icon}
        </Button>
      </Dropdown>
    )
}
