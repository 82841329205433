import React, { useEffect, useState } from 'react'
// import {fetchProducts, productsSelector} from '../../../../api/adminPik/stores'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../shared/loader'
import DataTable from './datatable'
import {Button,Spin, Image} from 'antd'
import {Link} from 'react-router-dom'
import {PlusOutlined, UploadOutlined} from '@ant-design/icons'
import {vendorAuthSelector} from '../../../api/auth'
import { productsSelector, fetchAllOrders} from '../../../api/product'
import {fetchCurrentStore, fetchOffer, offerSelector} from '../../../api/offer'

import { keyUri, config } from '../../../key';

import axios from 'axios'

export default function VenderIndex() {

  const {vendor} = useSelector(vendorAuthSelector)
  const {alloffer, offer, loading} = useSelector(offerSelector)

const [load, setLoading] = useState(false)

  const dispatch = useDispatch()
    
  useEffect(()=>{
    dispatch(fetchOffer(vendor._id))
    vendor && dispatch(fetchAllOrders(vendor._id))

  },[dispatch])


    return (
        <div>

 <div className="d-flex  mb-4 align-items-center justify-content-between">
    {/* <h5>Offers</h5> */}

    <Link to="/dashboard/create-offer">
      <Button type="ghost" icon={<PlusOutlined />}>
            Create Offer </Button></Link>
    </div>
           {
              load ? <Loader/> : 
            loading ? <Loader/> : <DataTable intialdata={alloffer} data={ alloffer }/>
            } 
    </div>

    )
}

