import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {fetchOffer, offerSelector , createOffer} from '../../../api/offer'
import {fetchAllOrders} from '../../../api/product'
import {productsSelector} from "../../../api/product"
import { Form,Empty, Input,Button, Select, InputNumber,Radio, Upload, Row, Col, Spin, Image, message  } from 'antd';
import { UploadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../api/auth'
import { keyUri, config } from '../../../key';
import axios from 'axios'
import Fade from 'react-reveal'
import redImage from './red.PNG'
import blueImage from './blue.PNG'
import yellowImage from './yellow.JPG'

const { Option } = Select;


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };



export default function CreateOffer() {
  const { vendor} = useSelector(vendorAuthSelector)
  const [isTheme, setTheme] = useState() 
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [offerProduct, setofferProduct] = useState([])
  const [categories, setCategories] = useState([])

  const {alloffer} = useSelector(offerSelector)
 const {currentCategories,allOffer} = useSelector(productsSelector)
    const dispatch = useDispatch();
  


let totalPrice = offerProduct.reduce((total, num )=>{
  return total+num.sale_price
}, 0)


const addItem = (item) => {
let isExists = offerProduct.findIndex((p)=>{
  return(item._id === p._id)
})
if(isExists !== -1){
  return alert("product is already added")
}

if(offerProduct.length < 1){
setofferProduct([...offerProduct, item])
}
}



const removeItem = (id) =>{
let s = offerProduct.filter((item)=>{
return item._id !== id

})
setofferProduct(s)
 }

  

   
useEffect(()=>{
  axios.get(keyUri.BACKEND_URI + `/current-store-categories/${vendor._id}`, config).then((data=>{
    setCategories(data.data.categories)
  }))
  dispatch(fetchOffer(vendor._id))

  vendor && dispatch(fetchAllOrders(vendor._id))

}, [dispatch])
  

const  onChange = async (value) => {
  setLoading(true)
      const {data} = await axios.get(keyUri.BACKEND_URI +`/current-category/${value}`, config)
      data && setProducts(data.products);
      data && setLoading(false)
  }

  const onRadioChange = e => {
    setTheme(e.target.value)
  };

const onFinish = (values) => {

  if(alloffer.length >=5 ){
    return message.info('Maximum 5 Offers can be created')
  }

    const offerdata = {  
        offer_title:values.offer_title,
        offer_image:values.offer_image ? values.offer_image.response.file.id : null,
        offer:values.offer,
        current_price:totalPrice,
        offer_Discription:values.offer_Discription,
        link:values.link,
        offer_product: offerProduct,
        store: vendor._id,
        offer_theme: values.offer_theme
     
    }

dispatch(createOffer(offerdata, vendor._id))

};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const normFile = e => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
    return e && e.file;
  };


  const  onSelectCategory = (value) => {
    console.log(`selected ${value}`);
  }

  const onSearch = (val) => {
  }
    return (
        <CreateBlogWrap >
          <h5 >Create Offer</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>

            <Row>
            <Col span={15} style={{overflowY:'auto', height:'75vh', overflowX:'hidden' }}>
            <div className="container ml-3">
             
             <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
       label="Offer Title"
       name='offer_title'
       rules={[{ required: true, message: 'offer title is required' }]}
          >
            <Input style={{ width: '60%' }} placeholder="Offer Title" />
      </Form.Item>

   

<Form.Item
       label="Image"
       rules={[{ required: true, message: 'Offer image is required!' }]}
      >
<Input.Group compact>
        <Form.Item
              name='offer_image'
              style={{ width: '22.7%' }}
              valuePropName="file"
              getValueFromEvent={normFile}
              // rules={[
              //   {
              //   required:true,
              //   message:'image is required'
              //   }
              // ]}
            >
        
              <Upload  name="offer_image" action= {keyUri.BACKEND_URI +"/offer-image"}  listType="picture">
                <Button icon={<UploadOutlined />}> Offer Image</Button>
              </Upload>

              </Form.Item>
      </Input.Group>
      </Form.Item>

      <Form.Item name="offer_theme" initialValue={isTheme} 
       rules={[{ required: true, message: ' Template is required' }]}
      label="Select Template">
                 <Radio.Group onChange={onRadioChange} value={isTheme} >
                 <Radio value="blue"><img src={blueImage} alt="blue" width="160px"/></Radio>
                 <Radio value="red"><img src={redImage} alt="red" width="160px"/></Radio>
                 <Radio value="text"><img src={yellowImage} alt="text" width="160px" height="55px"/></Radio>

                 </Radio.Group>
          </Form.Item>



      <Form.Item
       label="Category"
       rules={[{ required: true, message: 'category is required' }]}
          >
    
<Select
    showSearch
    style={{ width: 200 }}
    placeholder="Select Category"
    optionFilterProp="children"
    onChange={onChange}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
     {
categories && categories.map((item, i)=>{

  return  <Option key={i} value={item._id}>{item.category_name}</Option>
})
    }
  </Select>
  {
    loading ?  <h5 className=" my-3"><Spin className="ml-5" tip="Loading..."/> </h5> : 
    
    products.map((item,i)=>{

      let productExist = offerProduct.findIndex((pr)=>{
        return pr._id === item._id
    })


  return <Fade bottom distance="50px" key={i}>  
      <Row align="middle" justify="start" className="my-2 shadow-sm p-2" style={{ width: '80%' }}> 

      <Col span={10}> 

      <Image
        width="40px"
        src={keyUri.BACKEND_URI + `/product-image/${item.image}`}
        placeholder={
          <Image
            preview={false}
            src="https://i.pinimg.com/originals/2c/bb/5e/2cbb5e95b97aa2b496f6eaec84b9240d.gif"
            width="100%"
          />
        }
      />


      {/* <img src={keyUri.BACKEND_URI + `/product-image/${item.image}`} alt="product-image" width="40px" /> */}
      <span style={{fontSize:"1.2rem", marginLeft:"1.5rem"}}>{ item.product_name} </span>
       </Col>

      <Col span={8}><h6> &#x20B9; { item.sale_price}</h6> </Col>
    {
      
      (productExist === -1)  ? <Col span={2}><Button type="ghost" style={{ color: "green", borderColor: "green" }} icon={<PlusOutlined />} onClick={()=>{ addItem(item) }} > Select </Button></Col> :
      <Col span={2}><Button type="primary" style={{ color: "red", background: "transparent", borderColor: "red" }} icon={<DeleteOutlined />} onClick={()=>{ removeItem(item._id) }} > Remove </Button></Col>
    }

      </Row> </Fade>
    })
  }

      </Form.Item>
<div>
</div>

      <Form.Item
       label="Offer"
       rules={[{ required: true, message: 'Please input Offer!' }]}

      >

      <Input.Group compact>
        
    
          <Form.Item
            name='offer'
       
            rules={[{ required: true, message: 'offer price is required' }]}
           
          >
            <InputNumber style={{ width: '90%' }} placeholder="Offer %" />
          </Form.Item>

          <Form.Item
            name='current_price'
            className="ml-4"
          >
            <InputNumber style={{ width: '90%' }} placeholder={totalPrice} disabled />
          </Form.Item>    
      </Input.Group>
      </Form.Item>
      
   
      <Form.Item
       label="Offer Description"
       name='offer_Discription'
       rules={[{ message: 'Offer Description is required' }]}
          >
            <Input.TextArea style={{ width: '60%' }} placeholder="Offer Description" />
      </Form.Item>

      {/* <Form.Item
       label="External Link"
       name='link'
      
          >
            <Input style={{ width: '60%' }} placeholder="external link" />
      </Form.Item>   */}

<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div>
      </Col>

      <Col span={8} className="shadow-sm rounded" style={{ backgroundColor:"#fbfbfb", padding:'20px 10px 20px 50px', height:'18vh' }}>
      <h6>Selected Product:</h6>
      {
               (offerProduct.length<1) ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : offerProduct.map((item,i)=>{
                    return <Fade bottom distance="50px" key={i}>  
               
                 <Row align="middle" justify="start" className="my-2 shadow-sm p-2" style={{ width: '90%' }}> 

                 <Col span={16}> 

                <Image
                  width="40px"
                  src={keyUri.BACKEND_URI + `/product-image/${item.image}`}
                  placeholder={
                    <Image
                      preview={false}
                      src="https://i.pinimg.com/originals/2c/bb/5e/2cbb5e95b97aa2b496f6eaec84b9240d.gif"
                      width="100%"
                    />
                  }
                />

                    <span style={{fontSize:"1.2rem", marginLeft:"1.5rem"}}>{ item.product_name} </span>
                    </Col>

                    <Col span={8}><h6> &#x20B9; { item.sale_price}</h6> </Col>

                   
                    </Row> </Fade>

                })
              }
      </Col>
        </Row>
        </CreateBlogWrap>
    )
}
const CreateBlogWrap = styled.div`
`