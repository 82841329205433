import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../shared/loader'
import DataTable from './datatable'
import {PlusOutlined} from '@ant-design/icons'
import {Button, Modal, Form, InputNumber ,Upload, Input} from 'antd'
import {vendorAuthSelector} from '../../../api/auth'
import {fetchOneStore, settingsSelector } from '../../../api/settings'
import {fetchCategory, categorySelector, createSingleCategory} from '../../../api/category'
import {fetchAllOrders} from '../../../api/product'
import { keyUri, config } from '../../../key';
import { UploadOutlined } from '@ant-design/icons';


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 18 },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

export default function VenderIndex() {

const {vendor} = useSelector(vendorAuthSelector)


const [isModalVisible, setIsModalVisible] = useState(false);

const {current_store, categories, loading} = useSelector(settingsSelector)


console.log(categories);

  const dispatch = useDispatch()
  const [form] = Form.useForm();
        
  useEffect(()=>{
    dispatch(fetchOneStore(vendor._id))
    vendor && dispatch(fetchAllOrders(vendor._id))

  },[dispatch])
  
  const showAddModal = () => {
    setIsModalVisible(true);
  };
  
  const closeAddModal = () => {
    setIsModalVisible(false);
    
  }
  const onAddFinish = async (values) =>{

     ;

    const data = {
      category_name:values.category_name,
      category_image:values.category_image.response.file.id
    }

    vendor && dispatch(createSingleCategory(data,vendor._id))
    closeAddModal()
    
    form.resetFields();
    window.location.reload()
  }

  
  const onFinishFailed = (errorInfo) => {
  

    console.log('Failed:', errorInfo);
    };

    
const normFile = e => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.file;
};



  return (
    <div>

<div className="d-flex  mb-4 align-items-center justify-content-between">
<h5>Category</h5>

  <Button type="ghost" onClick={showAddModal} icon={<PlusOutlined />}>
        Add Category </Button>

        <Modal 
  title="Add Category"
  footer={false}
  visible={isModalVisible} 
  onCancel={closeAddModal} 
  closable = {false}
  >
    {/* <div className="d-flex mb-3 align-items-start justify-content-around"> */}
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onAddFinish}
     
      onFinishFailed={onFinishFailed}
      form={form}
        >

        <Form.Item
          label="Category Name"
          name="category_name"
        
          rules={[{ required: true, message: 'Enter category_name' }]}
        >
          <Input   style={{ width: '70%' }}  placeholder="Enter category_name"/>
        </Form.Item>

        <Form.Item
           label="Category Image"
          style={{ width: '100%' }}
              name='category_image'

              valuePropName="file"
              getValueFromEvent={normFile}
              rules={[
                {
                required:true,
                message:'Image is required'
                }
              ]}
            >
              <Upload  name="category_image" action= {keyUri.BACKEND_URI +"/category-image"}  listType="picture">
                <Button icon={<UploadOutlined />}> Upload Category Image</Button>
              </Upload>
            </Form.Item> 
     

  <Form.Item {...tailLayout}>
        <Button type="primary"  htmlType="submit" > Submit</Button>
      </Form.Item> 

 </Form> 
  </Modal>
   
    </div>
          <DataTable loading={loading}  data={categories }/>
           
    </div> 

    )
}



