import React, { useEffect, useState } from 'react'
// import {fetchProducts, productsSelector} from '../../../../api/adminPik/stores'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../shared/loader'
import DataTable from './datatable'
import {Button,Spin, Image} from 'antd'
import {Link} from 'react-router-dom'
import {PlusOutlined, UploadOutlined} from '@ant-design/icons'
import {vendorAuthSelector} from '../../../api/auth'
import { productsSelector, fetchAllOrders} from '../../../api/product'
import {fetchCurrentStore, fetchCombo, comboSelector} from '../../../api/combo'


import axios from 'axios'


export default function VenderIndex() {

  const {vendor} = useSelector(vendorAuthSelector)
  const {allcombo, combo, loading} = useSelector(comboSelector)

const [load, setLoading] = useState(false)

  const dispatch = useDispatch()
    
  useEffect(()=>{
    dispatch(fetchCombo(vendor._id))
    vendor && dispatch(fetchAllOrders(vendor._id))

  },[dispatch])


    return (
        <div>

 <div className="d-flex  mb-4 align-items-center justify-content-between">
    {/* <h5>Combo offers</h5> */}

    <Link to="/dashboard/create-combo">
      <Button type="ghost" icon={<PlusOutlined />}>
            Create Combo </Button></Link>
    </div>
 
           {
            
            loading ? <Loader/> : <DataTable intialdata={allcombo} data={ allcombo }/>
            } 
    </div>

    )
}

