import React, {useEffect} from 'react'
import DataTable from './datatable'
import {useDispatch, useSelector} from 'react-redux'
import {fetchSupplier, supplierSelector} from '../../../api/supplier'
import {fetchAllOrders} from '../../../api/product'

import Loader from '../../shared/loader'
import {Link} from 'react-router-dom'
import {PlusOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import {vendorAuthSelector} from '../../../api/auth'


export default function SupplierIndex() {
    const { vendor} = useSelector(vendorAuthSelector)
const dispatch = useDispatch()
const {suplliers, loading} = useSelector(supplierSelector)

 useEffect(()=>{

dispatch(fetchSupplier(vendor._id))
vendor && dispatch(fetchAllOrders(vendor._id))
 }, [dispatch])

 
    return (
        <div>

<div className="d-flex  mb-4 align-items-center justify-content-between">
    {/* <h5>Suppliers</h5> */}
    
    <Link to="/dashboard/create-supplier">
      <Button type="ghost" icon={<PlusOutlined />}>
            Add Supplier </Button></Link>
</div>    

            {

                loading?  <Loader/> : <DataTable data={suplliers}/>
            }
            
       
        </div>
    )
}
