import React, { useEffect } from 'react';
import './App.css';
import 'antd/dist/antd.css'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import VendorDashboard from './components'
import VendorLogin from './components/auth/login'
import PrivateVendorRoute from './components/auth/privateRoute'
// import ChatSec from './notification';
import MobileStatic from './mobilestatic'
import {useMedia} from 'react-use';




function App() {

  const isWide = useMedia('(min-width: 480px)');

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  
  
  };


  useEffect(()=>{

    clearCacheData()

    if(!isWide) {

     return window.location.href = 'https://mobile-vendor-4a11b.web.app/'

    }

  }, [isWide])
  
  return (  
  
  <Router>
{  isWide?  <main className="App">

   <PrivateVendorRoute path="/dashboard" component={VendorDashboard} />
   <Route path="/" component={VendorLogin} exact/>

   
 
    </main> : <MobileStatic/>}
    </Router>
  );
}

export default App;
