import React, { useEffect }  from 'react';
import { Route, Redirect } from 'react-router-dom';
import {vendorAuthSelector, fetchVendorProfile} from '../../api/auth'
import {useDispatch, useSelector} from 'react-redux'

const PrivateRoute = ({ component: Component, ...rest }) => {

const {loading, isAuthenticate} = useSelector(vendorAuthSelector)

 return (
    <Route
      {...rest}
      render={props =>
        
        !isAuthenticate &&  !loading? (
          <Redirect to='/' />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;


