import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux'
import { Table,  Space, Image, Switch , Radio, message} from 'antd';
import DeleteConfirm from '../../shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
import {deleteProducts} from '../../../api/product'
import { keyUri, config } from '../../../key';
import {updateProduct } from '../../../api/product'




export default function Datatable2({products, pagination, loading, handleTableChange }) {
 

  const dispatch = useDispatch()

  const confirm = (e, id) => {
      dispatch(deleteProducts(id._id))
     
    }
    
    const cancel = (e) =>{
      return null
    }

    const onChange = (checked, id) => {

     if(checked){
    const p = products.filter(item=>{
      return item.isRecommended 
    })
    if(p.length >= 10){
      return  message.info("Can't add more than 10 recommended products")
    }
   }
      const dataUpdate ={
        isRecommended: checked
      }
      dispatch(updateProduct(id, dataUpdate))
   
    }



  const columns = [
    {
      title: 'Product',
      dataIndex: 'product_name',
      key: 'product_name',
      render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
    },
    {
      title: 'Sale Price',
      dataIndex: 'sale_price',
      key: 'sale_price',
      render:(sale_price) =>{

        return <p>&#x20B9;{sale_price} </p>
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render:(image) =>{
return <Image
   
  width="20px"
   src={keyUri.BACKEND_URI +`/product-image/${image}`}
    placeholder={
      <Image
        preview={false}
        src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif"
        width="100%"
        
      />
    }
  />
        
      }              
      
    },

    {
      title: 'Recommended',
      key: 'recommended',
      render: (id) => {                  
        return <Switch className="mx-5" defaultChecked={id.isRecommended} onChange={(e)=>onChange(e, id._id)} />

      }
    },

    {
      title: 'Action',
      key: 'action',
      render: (id) => {


      return  <Space size="middle">
          
          <h5 className="text-secondary">
           <Link to={`/dashboard/edit-products/${id._id}`}>
            <FaRegEdit/> 
             </Link> 
            
            
            </h5>
        <h5 className="text-danger">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="product" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer"}} />
            </DeleteConfirm>
        </h5>
        </Space>
    },
    },
  ];



return(  <Table
  columns={columns}
  rowKey= {(record)=>record._id}
  dataSource={products}
  pagination={pagination}
  loading={loading}
  onChange={handleTableChange}
/>)

}
