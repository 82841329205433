import React, {useState, useEffect} from 'react'
import ExportYearExcel from '../yearExcel'
import YearSalesGraph from '../yearSalesGraph'
import MonthSalesGraph from '../monthSalesGraph'
import YearOrderGraph from '../yearOrderGraph'

import WeekSalesGraph from '../weekSalesGraph'
import WeekOrderGraph from '../weekOrderGraph'
import ExportWeekExcel from '../weekExcel'

import ExportMonthExcel from '../monthExcel'
import styled from 'styled-components'
import axios from 'axios'
import {keyUri} from '../../../../key'
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux'
import {fetchAllOrders} from '../../../../api/product'
import {Button,Avatar, Row, Col, Drawer} from 'antd'
import { BarChartOutlined, GiftOutlined, ShoppingOutlined, DiffOutlined, TeamOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../../api/auth'
import {fetchCombo, comboSelector} from '../../../../api/combo'
import {fetchOffer, offerSelector} from '../../../../api/offer'

export default function Anaytics() {
    const {vendor} = useSelector(vendorAuthSelector)
    const {allcombo} = useSelector(comboSelector)
    const {alloffer} = useSelector(offerSelector)

    const [yearChart, setYearChartData] = useState([])
    const [weekChart, setWeekChartData] = useState([])
    const [monthChart, setMonthChartData] = useState([])
    const [onlineOrder, Setonline_orders] = useState([])
    const [TodayOnlineOrder, SetTodayOnline_orders] = useState([])
    const [weekOnlineOrder, SetWeekOnline_orders] = useState([])
    const [MonthOnlineOrder, SetMonyhOnline_orders] = useState([])


    const [comboOrder, SetComboOrders] = useState([])

    const [visible, SetVisible] = useState(false)
    const [title, SetTitle] = useState(null)

        const dispatch = useDispatch()


    useEffect(() => {
        vendor && dispatch(fetchAllOrders(vendor._id))

        axios.get(keyUri.BACKEND_URI + `/fetch-yearchart/${vendor._id}`).then((data=>{
            setYearChartData(data.data)
        })) 

        axios.get(keyUri.BACKEND_URI + `/fetch-weekchart/${vendor._id}`).then((data=>{
            setWeekChartData(data.data)
        })) 

        axios.get(keyUri.BACKEND_URI + `/fetch-monthchart/${vendor._id}`).then((data=>{
            setMonthChartData(data.data)
        })) 
      
        axios.get(keyUri.BACKEND_URI + `/sales-data/${vendor._id}`).then((data=>{
            Setonline_orders(data.data.online_order) 
            SetTodayOnline_orders(data.data.today_online_order) 
            SetWeekOnline_orders(data.data.week_online_order)
            SetMonyhOnline_orders(data.data.month_online_order)
            SetComboOrders(data.data.combo_order)
          })) 

          dispatch(fetchCombo(vendor._id))
          dispatch(fetchOffer(vendor._id))

    }, [dispatch])


   
      
      

    let dashcard = [
        {
            title:"Orders",
            icon:<BarChartOutlined/>,
            stat:onlineOrder && onlineOrder.length,
            desc: <span >Total Online Orders</span> ,
            backColor:"#263055",
            data: ''

        },

        {
            title:"Combo",
            icon:<ShoppingOutlined/>,
            stat:allcombo.reduce((total, num )=>{
                        return total+num.weekOrders.ordersByWeek
                    }, 0),
            desc: <span >Combo Orders this week</span> ,
            backColor:"#F83245",
            data: allcombo

        },
        {
            title:"Offer",
            icon:<GiftOutlined/>,
            stat:alloffer.reduce((total, num )=>{
                return total+num.weekViews.viewsByWeek
            }, 0),
            desc: <span > Offers Viewed this week</span> ,
            backColor:"#5383FF",
            data: alloffer

        },
        {
            title:"Users",
            icon:<TeamOutlined/>,
            stat:vendor.weekViews && vendor.weekViews.week == moment().format('W/YYYY') ? vendor.weekViews.viewsByWeek : '0',
            desc: <span > Users visited this week </span> ,
            backColor:"#1BC943",
            data: ''

        },
    ]


    const showDrawer = (e, item) => {
        SetTitle(item.title)
      SetVisible(true)
      }
    
    const onClose = () => {
        SetVisible(false)
        SetTitle(null)

      }

    return (<>
 <HomwWrap>
        <Row gutter={20} >          
               {
                 dashcard.map((item, i)=>{
                
                    return <Col key={i} span={6}>
                    <div className=" customcard ">
             
                        <div className="d-flex justify-content-between">
                        <h6 className="text-secondary">
                            <Avatar size="large" className="mr-2" style={{backgroundColor:item.backColor}} icon={item.icon} /> {item.title}</h6> 
                            <Button type="primary" type="link" onClick={(e)=>showDrawer(e, item)}> View  </Button>
                        </div>

                        <div className="text-center">
                        <h1 className="mb-2">{item.stat}</h1>
                        <p className="text-secondary">{item.desc}</p>
                        </div>

                      </div>

                    </Col>

                 })
               }
            </Row>


            </HomwWrap>   


        <Drawer
        // title="Basic Drawer"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={400}
      >
                   <DrawerWrap>         

          <h5 className="text-secondary  "> {title === 'Offer'? 'Offer Views' : title === 'Combo'? "Combo Orders" : title === 'Users'? "Users Visited" : "Online Orders"} </h5> <hr/>
                   {
                                  title === 'Offer' &&                             
                                  alloffer.map((offer)=>{
                                        return  <div className="  shadow-sm  card"> 
                                            <h6 className=" text-capitalize">{offer.offer_title}</h6>
                                            <div className=" d-flex justify-content-between px-3 ">
                                            <p><b >{offer.dayView.date == moment().format('DD/MM/YYYY') ? offer.dayView.viewsByDay : '0'}</b>  Views Today</p>
                                            <p><b > {offer.weekViews.week == moment().format('W/YYYY') ?  offer.weekViews.viewsByWeek : '0' }</b> Views this Week</p>
                                            </div>

                                        </div>
                                    })
                     }

                    {
                                  title === 'Combo' &&                             
                                  allcombo.map((combo)=>{
                                        return  <div className=" shadow-sm card"> 
                                             <h6 className=" text-capitalize">{combo.combo_title}</h6>
                                            <div className=" d-flex justify-content-between px-3 ">
                                            <p><b >{combo.dayOrders.date == moment().format('DD/MM/YYYY') ? combo.dayOrders.ordersByDay : '0'}</b>  Orders Today</p>
                                            <p><b > {combo.weekOrders.week == moment().format('W/YYYY') ? combo.weekOrders.ordersByWeek : '0'}</b> Orders this Week</p>
                                            </div>

                                        </div>
                                    })
                     }

                    {
                                  title === 'Users' &&                             
                                <>
                                 <div className=" shadow-sm pl-3 pt-2 card"> 
                                <p><b>{vendor.dayView && vendor.dayView.date == moment().format('DD/MM/YYYY') ? vendor.dayView.viewsByDay: '0'} </b>Users Visited Store Today</p>
                                </div>

                                <div className=" shadow-sm pl-3 pt-2 card"> 
                                <p><b>{vendor.weekViews && vendor.weekViews.week == moment().format('W/YYYY') ? vendor.weekViews.viewsByWeek : '0'}</b> Users Visited Store this Week</p>
                                </div>
                                                                
                                </>
                     }

{

                                  title === 'Orders' &&                             
                                <>
                                <div className=" shadow-sm pt-2 pl-3  card"> 
                                <p><b>{TodayOnlineOrder.length}</b> Orders Today</p>
                                </div>

                                <div className=" shadow-sm pt-2 pl-3  card"> 
                                <p><b>{weekOnlineOrder.length} </b>Oders this Week</p>
                                </div>

                                <div className=" shadow-sm pt-2 pl-3 card"> 
                                <p><b>{MonthOnlineOrder.length} </b>Orders this month</p>
                                </div>
                                            
                                </>
                     }
                </DrawerWrap>         

      </Drawer>
    

        <div className="container-fluid pl-0 pr-4 mt-5">
        <AnlyticsWrap className="row">

            {/* year graph */}

            <div className="col-sm-6">
            <div className="mr-2 w-100  graph">
                    <h3> Year Sales</h3>
                    <YearSalesGraph data={yearChart} />
                   
              </div>
              <div className="ml-5">
                                <ExportYearExcel data={yearChart}/>

              </div>

             
            </div>
            <div className="col-sm-6">

            <div className="mr-2 w-100  graph">
                <h3>Year Orders</h3>
                <YearOrderGraph data={yearChart} />
                </div>
                <div className="ml-5">

                                    <ExportYearExcel data={yearChart}/>

                </div>
                </div>

                {/* week graph */}

                <div className="col-sm-6 mt-5">
            <div className="mr-2 w-100  graph">
                    <h3> Week Sales</h3>
                    <WeekSalesGraph data={weekChart} />
                   
              </div>
              <div className="ml-5">
                     <ExportWeekExcel data={weekChart}/>

              </div>

             
            </div>
            <div className="col-sm-6 mt-5">

            <div className="mr-2 w-100  graph">
                <h3>Week Orders</h3>
                <WeekOrderGraph data={weekChart} />
                </div>
                <div className="ml-5">

                     <ExportWeekExcel data={weekChart}/>

                </div>
                </div>

                {/* month graph */}

                <div className="col-sm-12 mt-4">   

                <div className="   graph mt-2">
                <h3 className="ml-3">{moment().format('MMMM')} Month Sales</h3>
                <MonthSalesGraph data={monthChart} />
                </div>

                <div className="ml-5">
                <ExportMonthExcel data={monthChart}/>
                </div>

                </div>
            
        </AnlyticsWrap>
        </div>
        </>
    )
}


const DrawerWrap =  styled.div`
.card {
    padding:0.4rem 0 0 0.4rem;
    border:none;
    margin-bottom: 18px;
    background-color: #e9e9e9;

    b{
        font-size:1rem;  
        font-weight:500;
        padding-right:0.3rem;

    }
}
`
const HomwWrap =  styled.div`
.customcard {
    padding:1rem;
    border-radius:0.5rem;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    h1{
        font-weight:300;
        color:#070919;
    }
    
    svg {
        color:white;
        transform:translateY(-5px);
        font-size:1.1rem;
    }    
    }

    `

const AnlyticsWrap = styled.div`

.graph{

overflow: hidden;
h3{

color:#000000;
font-size:1.2rem;
text-transform:uppercase;
transform:translateX(1rem);
}
}  
`


