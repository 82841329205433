import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import { Table, Tag,Image, Space, Descriptions, Button, Modal, Form, Upload , Input } from 'antd';
import DeleteConfirm from '../../shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
import {deleteCurrentCategory} from '../../../api/product'
import { keyUri, config } from '../../../key';
import { UploadOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../api/auth'

import { updateCategory } from '../../../api/category';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 18 },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};


export default function Datatable({data, loading}) {

        const dispatch = useDispatch()
        const [category, setCategory] = useState();
        const [isEditModalVisible, setIsEditModalVisible] = useState(false);
        const {vendor} = useSelector(vendorAuthSelector)
        const [imgStatus, setImgStatus] = useState(false)

        const [form] = Form.useForm();


        useEffect(()=>{
          form.setFieldsValue({
            category_name:category && category.category_name,
            // category_image:category && category.category_image,           
            });
      }, [category])


        const showEditModal = (id) => {
            setIsEditModalVisible(true);
          
            // axios.get(keyUri.BACKEND_URI + `/current-category/${id._id}`).then((data=>{
            //   console.log(data.data);
            //   }))
          
            setCategory(id)
          
          };


          const closeEditModal = () => {
            setIsEditModalVisible(false);
            setCategory(null)
            // form.resetFields();
          
          }
          
          
          //on edit finish
          const onEditFinish = async (values) =>{
          
            const data = {
                category_name:values.category_name,
                category_image: values.category_image && values.category_image.response ? values.category_image.response.file.id : category.category_image,

                

            }
            dispatch(updateCategory(data, category && category._id, vendor._id))
            closeEditModal()
            setCategory(null)
            form.resetFields();
          }
          
          
          
          const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
            };

        const confirm = (e, id) => {
        
            dispatch(deleteCurrentCategory(id._id))
           
          }
          
          const cancel = (e) =>{
            return null
          }

          const fileList =  [  
            {
              uid: '-1',
              name: 'Current image',
              status: 'done',
              url:keyUri.BACKEND_URI + `/category-image/${category && category.category_image}`,
              thumbUrl: keyUri.BACKEND_URI + `/category-image/${category && category.category_image}`,
            }];


          const normFile = e => {
            console.log('Upload event:', e);

            if(e.file.status === 'removed')
            {
              setImgStatus(true)
            }
            if(e.file.status === 'done')
            {
              setImgStatus(false)
            }

            if (Array.isArray(e)) {
              return e;
            }
            return e && e.file;
          };


    const columns = [
        {
          title: 'Category Name',
          dataIndex: 'category_name',
          key: 'category_name',
          render: category_name => {
 
            return <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{category_name}</h6>
        }
        },
        {
          title: 'No. of Products',
          dataIndex: 'products',
          key: 'products',
          render:(products) =>{
            console.log(products);
            return <h6>{products.length} </h6>
          }
        },
        {
          title: 'Image',
          dataIndex: 'category_image',
          key: 'category_image',
          render:(category_image) =>{         

return <Image
       
      width="20px"
      src={keyUri.BACKEND_URI+`/category-image/${category_image}`}
        placeholder={
          <Image
            preview={false}
            src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif"
            width="100%"
            
          />
        }
      />
            
          }              
          
        },
        {
          title: 'Action',
          key: 'action',
          render: (id) => {


          return  <Space size="middle">

<h5 className="text-secondary" onClick={()=>showEditModal(id)}> <FaRegEdit/>  </h5>

 

            <h5 className="text-danger">
                <DeleteConfirm confirm={(e)=>confirm(e, id)} title="Category" cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];

    return (<><Table  
           rowKey={(record) => record._id} 
           loading={loading}
           columns={columns}
           style={{overflowY:'auto', height:'75vh' }}
           
           expandable={{
            expandedRowRender:(record,data)=> {
           
          
              return <div>
              <Descriptions title="Products" >
  
        {
          
          record.products.map((item,i)=>{
          

              return <div key={i} width="400px" className="d-flex shadow-sm p-2 my-2 align-items-center justify-content-between">
              
                <div style={{paddingRight:'20px' }}>
                {/* <Image width="20px" src={keyUri.BACKEND_URI +`/product-image/${item.image}`}
                  placeholder={
                   <Image
                    preview={false}
                    src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif"
                    width="100%"
                   />
              }
               /> */}
                </div>
                <div style={{paddingRight:'40px' }}>
                    <b> {item.product_name || "null"}</b>
                </div>
                <div style={{ position: 'relative', align: 'right'  }}>
                      <b>&#x20B9; {item.sale_price|| "null"}</b>
                </div>
              </div>
           
           
})
}

              </Descriptions>
              </div>
          }, 
            rowExpandable: record => record.product  !== null,
          }}
           dataSource={data } />
           
         
          
          <Modal 
          title="Edit Category"
          footer={false}
          visible={isEditModalVisible} 
          onCancel={closeEditModal} 
          closable = {false}
          >
          {/* <div className="d-flex mb-3 align-items-start justify-content-around"> */}
          <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onEditFinish}

          onFinishFailed={onFinishFailed}
          form={form}
          >

          <Form.Item
          label="Category Name"
          name="category_name"
          // initialValue={category && category.category_name}

          rules={[{ required: true, message: 'Enter category_name' }]}
          >
          <Input   style={{ width: '70%' }}  placeholder="Enter category_name"/>
          </Form.Item>


          <Form.Item
                    label="Category Image"
                    style={{ width: '100%' }}
                        name='category_image'

                        valuePropName="file"
                        getValueFromEvent={normFile}
                        // rules={[
                        //   {
                        //   required:true,
                        //   message:'Image is required'
                        //   }
                        // ]}
                      >
                        {category &&             
                        <Upload  name="category_image" action= {keyUri.BACKEND_URI +"/category-image"}
                        maxCount={1}
                        defaultFileList={[...fileList]}
                        listType="picture"  >
                          <Button
                           disabled={imgStatus ? false : true}
                             icon={<UploadOutlined />}> Upload Category Image</Button>
                        </Upload>
                        }
                      </Form.Item> 


          <Form.Item {...tailLayout}>
          <Button type="primary"  htmlType="submit" > Submit</Button>
          </Form.Item> 

          </Form> 
          </Modal> </> )
}
