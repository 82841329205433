import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import { keyUri, config } from '../key';
import {logoutVendor} from './auth'

const initialState = {

    suplliers:[],
    current_supplier:null,
    loading:false,
    hasError:false,
    supplier:null,


}


export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {

    getSupplier: state => {
      state.loading = true;
    },
    getSupplierSuccess: (state, {payload}) => {

      state.loading = false
      state.suplliers = payload
    },


    getSupplierFailure: (state) => {

      state.loading = false
      state.hasError = true
    },

    getCurrentSupplier:(state, {payload}) => {
      state.loading = false
      state.supplier = payload.supplier
    },

  },
});

export const { getSupplier , getSupplierSuccess, getCurrentSupplier,  getSupplierFailure } = supplierSlice.actions;
export const supplierSelector = state => state.suppliers;



export const fetchSupplier = (id) => async dispatch => {

  dispatch(getSupplier())

 try {
   
console.log(config)

  const {data} = await axios.get(keyUri.BACKEND_URI +`/suppliers/${id}`, config)

  dispatch(getSupplierSuccess(data.supplier));
   
 } catch (error) {

dispatch(getSupplierFailure())
  
 }
}


export const createSupplier = (values, id) => async dispatch =>{
    dispatch(getSupplier())
    const key = 'create';
    message.loading({ content: 'loading...', key })

try {
    const {data} = await axios.post(keyUri.BACKEND_URI +`/supplier/${id}`, values, config)
 data && message.success({content:data.msg, key, duration:2})
dispatch(fetchSupplier())
} catch ({response}) {

  response.data && message.error({content:response.data.msg, key, duration:2})
  response.data && dispatch(logoutVendor())
}
}


export const deleteSupplier = (id) => async dispatch =>{

    dispatch(getSupplier())

    const key = 'delete';
    message.loading({ content: 'loading...', key })

try {
    const {data} = await axios.delete(keyUri.BACKEND_URI +`/supplier/${id}`,config)
 data && message.success({content:data.msg, key, duration:2})
dispatch(fetchSupplier())
} catch ({response}) {

    response.data && dispatch(logoutVendor())

}


}


export const fetchCurrentSupplier = (id) => async dispatch => {
   
    dispatch(getSupplier())
    try {
     const {data} = await axios.get(keyUri.BACKEND_URI +`/supplier/${id}`,config)
  
     dispatch(getCurrentSupplier(data));
    } catch (error) {
  
      dispatch(getSupplierFailure())
   
    }
   };
  


   
export const  updateSupplier = (id, values) => async dispatch =>{
    const key = "supplier"
    dispatch(getSupplier())
    message.loading({ content: 'loading...', key })
  
  try {
      const {data} = await axios.put(keyUri.BACKEND_URI +`/supplier/${id}`, values, config);
      data && message.success({ content: data.msg, key, duration: 2 });
      dispatch(fetchCurrentSupplier(id))
  
  } catch ({response}) {
      dispatch(getSupplierFailure())
      response.data && message.error({ content: response.data.msg, key, duration: 2 });
      response.data && dispatch(logoutVendor())

  }
  }

export default supplierSlice.reducer;
