import React from 'react'
import { Table,  Space, Image, Row, Col } from 'antd';
import {useDispatch} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import Fade from 'react-reveal/Fade'
import {Link} from 'react-router-dom'
import {deleteCombo} from '../../../api/combo'
import { Descriptions } from 'antd';
import { keyUri, config } from '../../../key';
import DescTable from './insideCustomerTable'
import moment from 'moment'


export default function Datatable({data, intialdata}) {
        const dispatch = useDispatch()

        const confirm = (e, id) => {
            dispatch(deleteCombo(id._id))
           
          } 
          const cancel = (e) =>{
            return null
          }


    const columns = [
        {
          title: 'User Name',
          dataIndex: 'user_name',
          key: 'user_name',
          render:(value,order) =>{
            return <p>{order.user ? order.user.user_name : order.offline_userName} </p>

          }
        },
        {
          title: 'Email',
          dataIndex: 'email',
          render:(value,order) =>{
            return <p>{order.user ? order.user.email : order.offline_user} </p>
          }
        },

        {
          title: 'phone No.',
          dataIndex: 'phone',
          render:(value,order) =>{
            return <p>{order.user ? ' ' : order.offline_userPhone} </p>
          }
        },
      
      ];

    return (
    
        <Fade duration = {1000} >
            <div>
 
         <Table  
           rowKey={record => record._id} 
           columns={columns}
         
           expandable={{
            expandedRowRender: record => {
              return <div style={{width:'500px'}}>
             
          <DescTable data = {record.offline_userPhone} store={record.store}/>
        
              
              </div>
          }, 
            rowExpandable: record => record.combo  !== null,
          }}


           dataSource = {data.length < 1 ? intialdata : data } />  
           </div>

        </Fade>
    )
}




