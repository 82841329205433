import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {fetchCurrentOffer, offerSelector ,updateOffer} from '../../../api/offer'
import {fetchAllOrders} from '../../../api/product'

import {productsSelector} from "../../../api/product"
import {useParams} from 'react-router-dom'
import Loader from '../../shared/loader';
import { Form,Empty, Input,Button, Select, InputNumber, Upload, Row,Radio, Col, Spin, Image  } from 'antd';
import { UploadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../api/auth'
import redImage from './red.PNG'
import blueImage from './blue.PNG'
import yellowImage from './yellow.JPG'

import { keyUri, config } from '../../../key';
import axios from 'axios'
import Fade from 'react-reveal'
import SkeletonImage from 'antd/lib/skeleton/Image'

const { Option } = Select;


const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };



export default function EditOffer() {
  
    const dispatch = useDispatch()
    const {vendor} = useSelector(vendorAuthSelector)
  
    const [isTheme, setTheme] = useState() 
    // const {offer} = useSelector(offerSelector)
    const [offer, setCurrOffer] = useState(null)
    const [form] = Form.useForm();
    const {id} = useParams()
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)

    const [offerProduct, setofferProduct] = useState(offer && offer.offer_product.length > 0 ? offer.offer_product : [])
    const [categories, setCategories] = useState([])

    const [img, setimg] = useState(null)
    const [imgStatus, setImgStatus] = useState(false)
    


let totalPrice = offerProduct.reduce((total, num )=>{
  return total+num.sale_price
}, 0)


const addItem = (item) => {
let isExists = offerProduct.findIndex((p)=>{
  return(item._id === p._id)
})
if(isExists !== -1){
  return alert("product is already added")
}

if(offerProduct.length < 1){
setofferProduct([...offerProduct, item])
}
}



const removeItem = (id) =>{
let s = offerProduct.filter((item)=>{
return item._id !== id

})
setofferProduct(s)
 }

  
  

useEffect(()=>{
  axios.get(keyUri.BACKEND_URI + `/current-store-categories/${vendor._id}`, config).then((data=>{
    setCategories(data.data.categories)
  }))
  vendor && dispatch(fetchAllOrders(vendor._id))
offer && setimg(offer.offer_product.offer_image)  
}, [dispatch])
  


  useEffect(()=>{
  
    // dispatch(fetchCurrentOffer(id))

    axios.get(keyUri.BACKEND_URI +`/offer/${id}`, config).then(({data})=>{
      setCurrOffer(data.offer)
      setimg(data.offer.offer_image)
    })

    }, [dispatch])


    useEffect(()=>{
      offer && setofferProduct(offer.offer_product)
      offer &&     form.setFieldsValue({
                    offer_title:offer.offer_title,
                    offer_image: offer.offer_image,
                    offer_backgroundimage: offer.offer_backgroundimage,
                  offer: offer.offer,
                  current_price: offer.current_price,
                  offer_Discription: offer.offer_Discription,
                  link: offer.link,
                  offer_product: offer.offer_product,
                  offer_theme: offer.offer_theme,
        
                  });
            }, [offer])
      

            const  onChange = async (value) => {
              setLoading(true)
            
                  const {data} = await axios.get(keyUri.BACKEND_URI +`/current-category/${value}`, config)
            
            
                  data && setProducts(data.products);
                  data && setLoading(false)
              }
            
            
              const onRadioChange = e => {
                setTheme(e.target.value)
              };
            
           

              const normFile = e => {
                console.log('Upload event:', e);
        
                if(e.file.status === 'removed')
              {
                setImgStatus(true)
              }
              if(e.file.status === 'done')
              {
                setImgStatus(false)
              }
        
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList[0];
                
              };


            
              const fileList =  [  
                {
                  uid: '-1',
                  name: 'Current image',
                  status: 'done',
                  url: keyUri.BACKEND_URI + `/offer-image/${img}`,
                  thumbUrl: keyUri.BACKEND_URI + `/offer-image/${img}`,
                }];

  
              
    

const onFinish = (values) => {

    console.log(values);
    const offerdata = {
        offer_title:values.offer_title,
        offer_image:values.offer_image ? values.offer_image : offer.offer_image,
        offer:values.offer,
        current_price:totalPrice,
        offer_Discription:values.offer_Discription,
        link:values.link,
        offer_product: offerProduct, 
        offer_theme: values.offer_theme
    }

    dispatch(updateOffer(id, offerdata))
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};


  const onSearch = (val) => {
    console.log('search:', val);
  }

    return (
        <CreateBlogWrap >
          <h5 >Edit Offers</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>

            <Row>
            <Col span={15} style={{overflowY:'auto', height:'75vh', overflowX:'hidden' }}>
            <div className="container ml-3">
             
             <Form
      {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
       label="Offer Title"
       name='offer_title'
       rules={[{ required: true, message: 'offer title is required' }]}
          >
            <Input style={{ width: '60%' }} placeholder="Offer Title" />
      </Form.Item>

   

<Form.Item
       label="Image"
       rules={[{ required: true, message: 'Offer image is required!' }]}
      >

    {  img &&  <Form.Item
              name='offer_image'
              style={{ width: '40.7%' }}
              valuePropName="file"
              getValueFromEvent={normFile}

            >


              {offer &&  
              <Upload   name="offer_image" action={keyUri.BACKEND_URI +"/offer-image"}  
              maxCount={1}
             listType="picture"  defaultFileList={[...fileList]} >
                <Button disabled={imgStatus ? false : true} icon={<UploadOutlined />}> Offer Image</Button>
              </Upload>
            
            }

              </Form.Item> }    
   
      </Form.Item>

      <Form.Item name="offer_theme" initialValue={isTheme} label="Select Template">
                 <Radio.Group onChange={onRadioChange} value={isTheme} >
                 <Radio value="blue"><img src={blueImage} alt="blue" width="180px"/></Radio>
                 <Radio value="red"><img src={redImage} alt="red" width="180px"/></Radio>
                 <Radio value="text"><img src={yellowImage} alt="text" width="160px" height="55px"/></Radio>

                 </Radio.Group>
          </Form.Item>


     
      <Form.Item
       label="Category"
       rules={[{ required: true, message: 'category is required' }]}
          >
    
<Select
    showSearch
    style={{ width: 200 }}
    placeholder="Select Category"
    optionFilterProp="children"
    onChange={onChange}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
     {
categories && categories.map((item, i)=>{

  return  <Option key={i} value={item._id}>{item.category_name}</Option>
})
    }
  </Select>
  {
    loading ?  <h5 className=" my-3"><Spin className="ml-5" tip="Loading..."/> </h5> : 
    
    products.map((item,i)=>{

      let productExist = offerProduct.findIndex((pr)=>{
        return pr._id === item._id
    })


  return <Fade bottom distance="50px" key={i}>  
      <Row align="middle" justify="start" className="my-2 shadow-sm p-2" style={{ width: '100%' }}> 

      <Col span={12}> 

      <Image
        width="40px"
        src={keyUri.BACKEND_URI + `/product-image/${item.image}`}
        placeholder={
          <Image
            preview={false}
            src="https://i.pinimg.com/originals/2c/bb/5e/2cbb5e95b97aa2b496f6eaec84b9240d.gif"
            width="100%"
          />
        }
      />


      <span className="text-right" style={{fontSize:"1.2rem", marginLeft:"1.5rem"}}>{ item.product_name} </span>
       </Col>

      <Col span={6}><h6> &#x20B9; { item.sale_price}</h6> </Col>
    {
      
      (productExist === -1)  ? <Col span={2}><Button type="ghost" style={{ color: "green", borderColor: "green" }} icon={<PlusOutlined />} onClick={()=>{ addItem(item) }} > Select </Button></Col> :
      <Col span={2}><Button type="primary" style={{ color: "red", background: "transparent", borderColor: "red" }} icon={<DeleteOutlined />} onClick={()=>{ removeItem(item._id) }} > Remove </Button></Col>
    }

      </Row> </Fade>
    })
  }

      </Form.Item>


      <Form.Item
       label="Offer"
       rules={[{ required: true, message: 'Please input Offer!' }]}

      >

      <Input.Group compact>
        
    
          <Form.Item
            name='offer'
             rules={[{ required: true, message: 'Offer is required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="Offer %" />
          </Form.Item>

          <Form.Item
            name='current_price'
            className="ml-4"
          >
            <InputNumber style={{ width: '90%' }} placeholder={totalPrice} disabled />
          </Form.Item>    
      </Input.Group>
      </Form.Item>
      
   
      <Form.Item
       label="Description "
       name='offer_Discription'
       rules={[{  message: 'Offer Description is required' }]}
          >
            <Input.TextArea style={{ width: '60%' }} placeholder="Offer Description" />
      </Form.Item>


         <Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div>
      </Col>

      <Col span={8} className="shadow-sm rounded" style={{ backgroundColor:"#fbfbfb", padding:'20px 10px 20px 50px', height:'18vh' }}>
      <h6>Selected Product:</h6>
      {
               (offer && offerProduct.length<1) ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :offer && offerProduct.map((item,i)=>{
                    return <Fade bottom distance="50px" key={i}>  
               
                 <Row align="middle" justify="start" className="my-2 shadow-sm p-0" style={{ width: '100%' }}> 

                 <Col span={12}> 

                <Image
                  width="20px"
                  src={keyUri.BACKEND_URI + `/product-image/${item.image}`}
                  placeholder={
                    <Image
                      preview={false}
                      src="https://i.pinimg.com/originals/2c/bb/5e/2cbb5e95b97aa2b496f6eaec84b9240d.gif"
                      width="100%"
                    />
                  }
                />

                    <span className="text-center" style={{fontSize:"0.7rem", marginLeft:"1rem"}}>{ item.product_name} </span>
                    </Col>

                    <Col span={5}><h6> &#x20B9; { item.sale_price}</h6> </Col>
                    <Col span={1}><Button type="primary" style={{ color: "red", background: "transparent", borderColor: "red" }} icon={<DeleteOutlined />} onClick={()=>{ removeItem(item._id) }} >  Remove </Button></Col>

                   
                    </Row> </Fade>

                })
              }
      </Col>
        </Row>
        </CreateBlogWrap>
    )
}
const CreateBlogWrap = styled.div`
`