import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import { keyUri, config } from '../key';
import {fetchOneStore} from './settings'
import {logoutVendor} from './auth'

const initialState = {

    categorys: [],
    current_category: null,
    loading: false,
    hasError: false,
    category: null,

}


export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {

        getCategory: state => {
            state.loading = true;
        },

        getCategorySuccess: (state, { payload }) => {
            state.loading = false
            state.categorys = payload
        },


        getCategoryFailure: (state) => {

            state.loading = false
            state.hasError = true
        },

        getCurrentCategory: (state, { payload }) => {
            state.loading = false
            state.category = payload.category
        },

    },
});

export const { getCategory, getCategorySuccess, getCategoryFailure, getCurrentCategory } = categorySlice.actions;
export const categorySelector = state => state.categorys;



export const fetchCategory = () => async dispatch => {
  dispatch(getCategory())

 try {
  const {data} = await axios.get(keyUri.BACKEND_URI +`/category`, config)
  dispatch(getCategorySuccess(data.supervisor));
   
 } catch (error) {

dispatch(getCategoryFailure())
  
 }
}

export const createSingleCategory = (values,id) => async dispatch => {
    dispatch(getCategory())
    const key = 'create';
    message.loading({ content: 'loading...', key })

    try {
        const { data } = await axios.post(keyUri.BACKEND_URI + `/single-category/${id}`, values, config)
     

        data && message.success({ content: data.msg, key, duration: 2 })

        dispatch(fetchCategory())
        // dispatch(fetchOneStore(id))

    } catch ({ response }) {
        response.data && message.error({ content: response.data.msg, key, duration: 2 });
        response.data && dispatch(logoutVendor())
    }
}



// export const deleteSupervisor = (id) => async dispatch => {

//     dispatch(getSupervisor())

//     const key = 'delete';
//     message.loading({ content: 'loading...', key })

//     try {
//         const { data } = await axios.delete(keyUri.BACKEND_URI + `/supervisor/${id}`)
//         data && message.success({ content: data.msg, key, duration: 2 })
//         dispatch(fetchSupervisor())
//     } catch ({ response }) {

//         response.data && message.error({ content: response.data.msg, key, duration: 2 })
//     }
// }


export const fetchCurrentCategory = (id) => async dispatch => {
    dispatch(getCategory())
    try {
     const {data} = await axios.get(keyUri.BACKEND_URI +`/current-category/${id}`, config)
  
     dispatch(getCurrentCategory(data));

    } catch (error) {

      dispatch(getCategoryFailure())
    }
   };
  


   
export const  updateCategory = (values, id, vendor ) => async dispatch =>{
    const key = "supervisor"
    dispatch(getCategory())
    message.loading({ content: 'loading...', key })
  
  try {
      const {data} = await axios.put(keyUri.BACKEND_URI +`/update-single-category/${id}`, values, config);
      
      data && message.success({ content: data.msg, key, duration: 2 });
    //   dispatch(fetchCurrentCategory(id))
      dispatch(fetchCategory())
      dispatch(fetchOneStore(vendor))

  
  } catch ({response}) {
      dispatch(getCategoryFailure())
      response.data && message.error({ content: response.data.msg, key, duration: 2 });
      response.data && dispatch(logoutVendor())

  }
  }


export default categorySlice.reducer;

