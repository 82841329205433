import React, { useEffect, useState } from 'react'
import { useSelector} from 'react-redux'
import DataTable from './datatable'
import {Button, Input} from 'antd'
import { Select } from 'antd';
import {vendorAuthSelector} from '../../../api/auth'
import { keyUri, config } from '../../../key';


import axios from 'axios'
import ExportExcel from './excelBtn'


const { Option } = Select;


export default function VenderIndex() {

const [products, setProducts] = useState([])
const [filter, setFilter] = useState('product_name')
const [categories, setCategories] = useState(null)
const [currentCat, setCurreentCat] = useState(null)
const [searchvalue, setSearchvalue] = useState('')

const {vendor} = useSelector(vendorAuthSelector)
const [loading, setLoading] = useState(false)

const [pagination, setPagination] = useState({
  current: 1,
  pageSize: 10,

})

console.log(pagination);    

  useEffect(()=>{

      fetch2({ pagination })

          axios.get(keyUri.BACKEND_URI +  `/current-store-categories/${vendor._id}`, config).then((data=>{

            setCategories(data.data.categories)

          }))
          
  },[])



  const onChangeCategory = (value) =>{
    setCurreentCat(value)

    if(searchvalue) {

      fetch2({

        pagination,
        category:value,
        search:searchvalue,

      });
          }

          fetch2({

            pagination,
            category:value,

          });

  }

  const onChange = (value) =>{
   
      setFilter(value)

  }
  


  const  fetch2 = (params = {}) => {
    const {current,  pageSize, } = params.pagination
    const {category,  search, filter} = params
    
        setLoading(true)
       axios.get(keyUri.BACKEND_URI + `/store-product/${vendor._id}?category=${category}&search=${search}&skip=${current}&limit=${pageSize}&filter=${filter}`, config)
        .then(({data}) => {
          setLoading(false)
          setProducts(data.product);
          setPagination({
            ...params.pagination,
               total: data.numberproduct
              })
    
        });
      };
    
    
    
      const handleTableChange = (pagination) => {
    
        fetch2({
       
        pagination,
        category:currentCat,
        search:searchvalue,
        filter:filter
        });
    
      };
    
    


  const onSearch = e => {

    const searchvalue = e.target.value
    setSearchvalue(searchvalue)

if(currentCat){

  fetch2({

    pagination,
    category:currentCat,
    search:searchvalue
 
  });
}

fetch2({

  pagination,
  category:currentCat,
  search:searchvalue,
  filter:filter

});

};


 

  const showAllProducts = () => {

    window.location.reload()
  };

    return (
        <div>

 <div className="d-flex  mb-4 align-items-center justify-content-between">
   

<div className="d-flex align-items-center justify-content-start ">

<Input
      placeholder="Input search text"
      allowClear
      enterButton="Search"
      size="middle"
      onChange={onSearch}
      // onSearch={onSearch}
      className="mr-3"
      style={{ width: 250 }}
    />

<Select
   
    style={{ width: 150 }}
    placeholder="Select filter"
    onChange={onChange}
    defaultValue="product_name"
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    <Option  value="product_name">Product Name</Option>
  {/* {currentCat && <Option value="categories">Category</Option>}   */}
    <Option value="barcode">Barcode</Option>
  </Select>


</div>


<div className="d-flex align-items-center justify-content-start ">
  
<ExportExcel data={products} />

  
<Select
    showSearch
    style={{ width: 150 }}
    className="mr-3"
    placeholder="Select Category"
    
    optionFilterProp="children"
    onChange={onChangeCategory}
 
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {

categories && categories.map((item, i)=>{

        return <Option key={i} value={item._id}>{item.category_name}</Option>
      })
    }
    
  </Select>

  <Button type="ghost"  onClick={showAllProducts}>All Products </Button>
    
            </div>
    </div>
     

        
            <DataTable   
            
            products={ products } 
            pagination={pagination}
            loading={loading}
            handleTableChange={handleTableChange}/>
             
    </div>

    )
}




