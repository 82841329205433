import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Collapse, Layout, Menu, Switch } from 'antd';
import Logo from '../images/logo.png'
import Logo2 from '../images/logo_small.png'
import {fetchAllOrders, productsSelector} from '../api/product'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {vendorAuthSelector} from '../api/auth'

import {
  AppstoreOutlined,
    DiffOutlined,
    SettingFilled,
    BarcodeOutlined,
    PlusOutlined,
    ShopOutlined,
    TeamOutlined,
    ShoppingOutlined,
    HomeFilled,
    GiftOutlined,
    DatabaseOutlined,
    OrderedListOutlined,
    AppstoreAddOutlined,
    DropboxOutlined,
    FileDoneOutlined,
    BarChartOutlined
  } from '@ant-design/icons';


const { SubMenu } = Menu;

const {  Sider } = Layout;

export default function Sidemenu({click, color, collapsed }) {

const {pathname} = useLocation()
const dispatch = useDispatch()
const {orders } = useSelector(productsSelector)
const {vendor } = useSelector(vendorAuthSelector)

useEffect(()=>{

  vendor && dispatch(fetchAllOrders(vendor._id))


}, [dispatch])

let Unfulorders = orders.filter(item=>{

  return item.orderStatus === 'Unfulfilled'
})


    return (
   <SideMenuWrap color={color}>
             <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="py-2 my-2" >
            {
              collapsed ?  <img src={Logo2} className="d-block mx-auto" width="30px" alt="logo"/> :

              <img src={Logo} className="d-block mx-auto" width="160px" alt="logo"/>
            }
            </div>
            
            <div className="d-flex  menuwrap " >

          <Menu
           theme={color? 'dark' : 'light'}
           mode="inline"
           defaultSelectedKeys={[pathname]}
           className="menu "
           selectedKeys={[pathname]}
          >
           
          <Menu.Item key="/dashboard/home" icon={<HomeFilled />} >   
           <Link to="/dashboard/home">Dashboard</Link> 
          </Menu.Item>

          <Menu.Item key="/dashboard/invoice" icon={<BarcodeOutlined />} > 
          <Link to="/dashboard/invoice"> Billing</Link>
          </Menu.Item> 


          <Menu.Item key="/dashboard/orders" icon={ <FileDoneOutlined />} >  
          <Link to="/dashboard/orders"> Orders &nbsp; <b className="px-2 rounded" style={{backgroundColor:"red", color:"white"}}>{Unfulorders.length}</b></Link>
          </Menu.Item> 


       

          <SubMenu key="/dashboard/products" icon={<AppstoreOutlined />} title="Products">
            
          <Menu.ItemGroup key="g1" >
     

              <Menu.Item key="/dashboard/product-list" icon={<OrderedListOutlined />} > 
              <Link to="/dashboard/products-list">Products List</Link>
              </Menu.Item> 

              <Menu.Item key="/dashboard/category-list" icon={<OrderedListOutlined />} > 
              <Link to="/dashboard/category-list">Category List</Link>
              </Menu.Item> 

              
              <Menu.Item key="/dashboard/add-product" icon={<PlusOutlined />} > 
              <Link to="/dashboard/add-product">Add Product</Link>
            
              </Menu.Item> 

              
              <Menu.Item key="/dashboard/create-product" icon={<AppstoreAddOutlined />} > 
              <Link to="/dashboard/create-product">Create Product</Link>
            
              </Menu.Item> 

          </Menu.ItemGroup>
           
          </SubMenu>

          <Menu.Item key="/dashboard/offers" icon={<GiftOutlined />} > 
          <Link to="/dashboard/offers"> Offers</Link>
          </Menu.Item> 
        
          <Menu.Item key="/dashboard/combo" icon={<ShoppingOutlined />} > 
          <Link to="/dashboard/combo">Combo Offers</Link>
          </Menu.Item> 


          <Menu.Item key="/dashboard/inventory" icon={<DatabaseOutlined />} > 
          <Link to="/dashboard/inventory">Inventory</Link>
          </Menu.Item> 

          <Menu.Item key="/dashboard/suppliers" icon={<ShopOutlined />} > 
          <Link to="/dashboard/suppliers">Supplier</Link>          
          </Menu.Item>

          <Menu.Item key="/dashboard/customers" icon={<TeamOutlined />} > 
          <Link to="/dashboard/customers"> Customers</Link>
          </Menu.Item> 

          <Menu.Item key="/dashboard/analytics" icon={ <BarChartOutlined />} > 
          <Link to="/dashboard/analytics">Analytics</Link>         
          </Menu.Item>  

          <Menu.Item key="/dashboard/settings" icon={ <SettingFilled />} > 
          <Link to="/dashboard/settings">Settings</Link>         
          </Menu.Item>  
   

          </Menu>
        
          <div>
          <div className="mode ">
         <Switch    onChange={()=>click()} />
          </div>

         

           <div className="ml-3 mt-3" style={{color:color? '#a6adb4' : 'black'}}>
           {
              collapsed ?  <p >&#169; Piklocal </p> :
              <p> Copyright &#169; 2021 Piklocal. All Rights Reserved</p>
           }
             </div> 
              
             </div>
             </div>
        </Sider>
        </SideMenuWrap>
      
    )
}


const SideMenuWrap = styled.div`

.menuwrap{

  flex-direction: column;
  justify-content: space-between;
  height: 87%;


}

.menu{


font-size:1rem;
svg{

  font-size:1.1rem;
  transform:translate(-5px, -4px);
}

}

.mode{

  justify-content:space-between;
  margin-left: 1.2rem !important;
  
  .ant-switch{

  background-color:${props=>props.color? "grey":"#1890FF"};
}

}



`