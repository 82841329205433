import React, { useEffect, useState} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {fetchProducts,fetchAllOrders, productsSelector} from '../../../api/product'
import BarcodeReader from 'react-barcode-reader'
import { Form, Input, Radio,Button, Select,Switch, InputNumber, Upload, message  } from 'antd';
// import Select from '../../../Shared/select'
import {createProducts,} from '../../../api/product'
import {fetchSupplier, supplierSelector} from '../../../api/supplier'
import { UploadOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../api/auth'
import { keyUri, config } from '../../../key';
import axios from 'axios'

const { Option } = Select;


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };



export default function CreateProducts() {
  const {  vendor, loading} = useSelector(vendorAuthSelector)
  const {  products } = useSelector(productsSelector)


  const [isNewCategory, setNewCategory] = useState('existCategory') 


  const {suplliers} = useSelector(supplierSelector)


  // const {products} = useSelector(productsSelector)

  const [result, setResult] = useState('')
  const [code, setCode] = useState(null)
  // const [recommed, setRecommended] = useState(false);

  const [start, setStart] = useState(false)

  const [barcodeSubmit, setBarcodeSubmit] = useState(false)
const [categories, setCategories] = useState([])
const dispatch = useDispatch();

const [form] = Form.useForm();


  useEffect(()=>{

    axios.get(keyUri.BACKEND_URI + `/current-store-categories/${vendor._id}`, config).then((data=>{

      setCategories(data.data.categories)

    }))
    vendor && dispatch(fetchAllOrders(vendor._id))
    vendor && dispatch(fetchProducts(vendor._id))
  }, [dispatch])
    


  useEffect(()=>{ 
    dispatch(fetchSupplier(vendor._id))
    }, [dispatch])


    const handleScan = (data) =>{
      setResult(data)


  }
    const handleError = (err) => {
     console.log(err);
    }


 
    const onChange = e => {
      // console.log('radio checked', e.target.value);
      setNewCategory(e.target.value)
    };
    

const onFinish = (values) => {

  // if(values.isRecommended){
  //   const p = products.filter(item=>{
  //     return item.isRecommended 
  //  })
  //  if(p.length >= 10){
  //    return message.info("Can't add more than 10 recommended products")
  //  }
  // }


const cImage = (isNewCategory === 'newCategory') ? values.category_image.response.file.id : null
const productdata = {

      category_name:values.category_name,
      product_name:values.product_name,
      sale_price:values.sale_price,
       MRP:values.MRP,
       GST:values.GST,
       quantity:values.quantity,
       supplier:values.supplier,
       image:values.product_image.response.file.id,
       product_description:values.product_description,
       stock:values.stock,
       category_image: cImage,
       barcode:result === ''? code : result,
      //  isRecommended: recommed

    }
            dispatch(createProducts(productdata, vendor._id))
            form.resetFields()
            setCode('')
            // setRecommended(false);

  
};



const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.file;
  };

  const  onSelectCategory = (value) => {
    console.log(`selected ${value}`);
  }

  const  onSelectSupplier = (value) => {
    console.log(`selected ${value}`);
  }

  const  onSelectMeasure = (value) => {
    console.log(`selected ${value}`);
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }

//   const onChangeRecommended = e => {
//     const data = {
//     excahange: e.target.value,
// }
//   setRecommended(e.target.value);
// };


const handleBarCode = (e) =>{

setCode(e.target.value)


}



    return (
        <CreateBlogWrap className="container ml-3">
          <h5 >Create Product</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
             <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
                     
       <Form.Item name="isNewCategory" initialValue={isNewCategory} label="Select Category">
        <Radio.Group onChange={onChange} value={isNewCategory}>
          <Radio value="existCategory">Exist Category</Radio>
          <Radio value="newCategory">New Category</Radio>
        </Radio.Group>
      </Form.Item>
     

      <Form.Item
       label="Category"
       rules={[{ required: true, message: 'Please input Category Name'}]}

      >
      <Input.Group compact>
{
  (isNewCategory !== 'existCategory') ?  <> <Form.Item
  name='category_name'

  rules={[{ required: true, message: 'Category Name is required' }]}
>
  <Input style={{ width: '90%' }} placeholder="Category Name" />
</Form.Item>

<Form.Item
style={{ width: '20%' }}
    name='category_image'

    valuePropName="file"
    getValueFromEvent={normFile}
    rules={[
      {
      required:true,
      message:'Image is required'
      }
    ]}
  >
    <Upload  name="category_image" action= {keyUri.BACKEND_URI +"/category-image"}  listType="picture">
      <Button icon={<UploadOutlined />}> Upload Category Image</Button>
    </Upload>
  </Form.Item> 
  </> : <Form.Item
  name='category_name'

  rules={[{ required: true, message: 'Category Name is required' }]}
>
<Select
    showSearch
    style={{ width: 200 }}
    placeholder="Select Category"
    optionFilterProp="children"
    onChange={onSelectCategory}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
     {
 categories.map((item, i)=>{


  return  <Option key={i} value={item.category_name}>{item.category_name}</Option>

})
    }
  </Select>
  </Form.Item>
}
     

      </Input.Group>
      </Form.Item>


      <Form.Item
       label="Product"
       rules={[{ required: true, message: 'Please input Product' }]}

      >

      <Input.Group compact>

      <Form.Item
            name='product_name'
       
            rules={[{ required: true, message: 'product name is required' }]}
          >
            <Input style={{ width: '90%' }} placeholder="Product Name" />
          </Form.Item>
        
      <Form.Item
            name='sale_price'
       
            rules={[{ required: true, message: 'sale price is required' }]}
            className="mr-4"
          >
            <InputNumber  placeholder="Sale Price" />
          </Form.Item>


          <Form.Item
            name='MRP'
       
            rules={[{ required: true, message: 'MRP is required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="MRP " />
          </Form.Item>

          <Form.Item
            name='GST'
       
            rules={[{ required: true, message: 'GST is required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="GST " />
          </Form.Item>    
      </Input.Group>
      </Form.Item>
   

<Form.Item
       label=" "
       rules={[{ required: true, message: 'quantity is required!' }]}
      
      >

<Input.Group compact>
        
        <Form.Item
              name='product_image'
              style={{ width: '22.7%' }}
              valuePropName="file"
              getValueFromEvent={normFile}
              rules={[
                {
                required:true,
                message:'Image is required'
                }
              ]}
            >
              <Upload   name="product_image" action= {keyUri.BACKEND_URI +"/product-image"} listType="picture">
                <Button  icon={<UploadOutlined />}>Upload Product Image</Button>
              </Upload>
            </Form.Item> 
        
      <Form.Item
            name='quantity'
      
            rules={[{ required: true, message: 'quantity is required' }]}
            className="ml-4"
          >
            <Input style={{ width: '90%' }} placeholder="Quantity" />
          </Form.Item>

 {/* <Form.Item
            name='measure_unit'
            rules={[{ required: true, message: 'Unit is required' }]}
          >
          <Select
              showSearch
              style={{ width: 150, marginRight:'20px' }}
              placeholder="Select Unit"
              optionFilterProp="children"
              onChange={onSelectMeasure}
              onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option.children.indexOf(input) >= 0
              // }
            >
              <Option value="kg">Kilogram</Option>
                <Option value="g">gram</Option>
                
                <Option value="l">liter</Option>
                <Option value="ml">mililiter</Option>
                <Option value="piece">Piece</Option>
            </Select>
            </Form.Item>  */}

          <Form.Item
            name='stock'
       
            rules={[{ required: true, message: 'stock is required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="Stock " />
          </Form.Item>   
      </Input.Group>
      </Form.Item>

     
      
<Form.Item
       label="Supplier"
       rules={[{ required: true, message: 'Please input Category Name'}]}
      >
      <Form.Item
            name='supplier'
            // rules={[{ required: true, message: 'Supplier Name is required' }]}
          >
          <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select Supplier"
              optionFilterProp="children"
              onChange={onSelectSupplier}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.indexOf(input) >= 0
              }
            >
              {
          suplliers && suplliers.map((item, i)=>{

            return  <Option key={i} value={item._id}> {item.supplier_name}</Option>

          })
              }
            </Select>
            </Form.Item>
     
      </Form.Item>


      <Form.Item
        label="Product Description"
        name="product_description"
        >
     <Input.TextArea />
</Form.Item>

{/* <Form.Item
        label="Is Recommended "
        name="isRecommended"
        initialValue={recommed}
        >

<Radio.Group  onChange={onChangeRecommended} value={recommed}>
            <Radio.Button value={false}>No</Radio.Button>
             <Radio.Button value={true}>Yes</Radio.Button>
             </Radio.Group>

</Form.Item> */}


<Form.Item
        label="Barcode"
        
        >
 
   <Input style={{ width: '50%' }} onChange={(e)=>handleBarCode(e)} 
   disabled={result === ''? false :true }
   placeholder={result === ''? 'Scan Or Enter Barcode': result}/>


</Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>

    <BarcodeReader
          onError={handleError}
          onScan={handleScan}
          />


        </CreateBlogWrap>
    )
}


const CreateBlogWrap = styled.div`
`