import React, { useEffect, useState} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {fetchProducts, fetchAllOrders, productsSelector} from '../../../api/product'
import {fetchCombo, comboSelector , createCombo} from '../../../api/combo'

import { Form,Empty , message, Input,Button, Select, InputNumber, Upload, Row, Col, Spin, Image  } from 'antd';
import { UploadOutlined, PlusOutlined,CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../api/auth'
import { keyUri, config } from '../../../key';
import axios from 'axios'
import Fade from 'react-reveal'
 
const { Option } = Select;


const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };



export default function CreateCombo() {
  const {vendor} = useSelector(vendorAuthSelector)
  const [isNewCategory, setNewCategory] = useState('existCategory') 
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [comboProducts, setcomboProducts] = useState([])
  const [categories, setCategories] = useState([])
  const {allcombo, } = useSelector(comboSelector)

  const {currentCategories, products:p} = useSelector(productsSelector)

    const dispatch = useDispatch();


let totalPrice = comboProducts.reduce((total, num )=>{
  return total+num.sale_price
}, 0)



const addItem = (item) => {
let isExists = comboProducts.findIndex((p)=>{
  return(item._id === p._id)
})

if(isExists !== -1){
  return alert("product is already added")
}
setcomboProducts([...comboProducts, item])
    
}



const removeItem = (id) =>{
let s = comboProducts.filter((item)=>{
return item._id !== id

})
setcomboProducts(s)
 }

 const [form] = Form.useForm();



useEffect(()=>{

dispatch(fetchProducts(vendor._id))
  axios.get(keyUri.BACKEND_URI + `/current-store-categories/${vendor._id}`, config).then((data=>{
    setCategories(data.data.categories)
  }))
  vendor && dispatch(fetchAllOrders(vendor._id))
  dispatch(fetchCombo(vendor._id))

}, [dispatch])
  

const [select, setSelect] = useState(null)

const  onChange = async (value) => {
  setSelect(value)
  setLoading(true)

      const {data} = await axios.get(keyUri.BACKEND_URI +`/current-category/${value}`, config)     
      data && setProducts(data.products);
      data && setLoading(false)
  }
  

const onFinish = (values) => {
  if(allcombo.length >=5 ){
    return message.info('Maximum 5 Combos can be created')
  }
    const combodata = {
      combo_title:values.combo_title,
      combo_image: values.combo_image.response.file.id,
      offer:values.offer,
      current_total:totalPrice,
      combo_product: comboProducts,
      store: vendor._id
        
    }
dispatch(createCombo(combodata, vendor._id))
form.resetFields()

};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};


const normFile = e => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.file;
};



  const onSearch = (val) => {

  }

   const productSeach = (e) => {

    let patt = new RegExp(`${e.target.value}`, 'gi');

if(select){

  const result =  p.filter(item =>{
 
        return item.categories &&  (item.categories._id === select && item.product_name.match(patt));

     })

 return    setProducts(result)


} else {

  const result =  p.filter(item =>{
 
        return  item.product_name.match(patt);

     })

 return    setProducts(result)
  
}
  

    

  }

    return (
      <CreateBlogWrap>
     <br/>
     

            <Row>
            <Col span={15} style={{overflowY:'auto', height:'85vh', overflowX:'hidden' }}>
          <div className="container ml-3">
          
             <Form
      {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

      <Form.Item
       label="Combo Title"
       name='combo_title'
       rules={[{ required: true, message: 'combo title is required' }]}
          >
            <Input style={{ width: '60%' }} placeholder="Combo Title" />
      </Form.Item>
  

<Form.Item
       label="Combo Image"
       rules={[{ required: true, message: 'quantity is required!' }]}

      >

<Input.Group compact>
        
        
      <Form.Item
        name="combo_image"
        valuePropName="file"
        getValueFromEvent={normFile}
        rules={[
          {
            required: true,
            message: 'image is required!',
          },
        ]}
      >
        <Upload   name="combo_image" action= {keyUri.BACKEND_URI +"/combo-image"} listType="picture">
        <Button icon={<UploadOutlined />}>Upload Combo Image</Button>
          </Upload>
      </Form.Item>
      
      
   </Input.Group>
 </Form.Item>


      <Form.Item
       label="Product"
       rules={[{ required: true, message: 'category is required' }]}
          >
            
            <Form.Item
  name='combo_product'

  // rules={[{ required: true, message: 'add products' }]}
>
<Select
    showSearch
    style={{ width: 200 }}
    placeholder="Select Category"
    optionFilterProp="children"
    onChange={onChange}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
     {
categories && categories.map((item, i)=>{


  return  <Option key={i} value={item._id}>{item.category_name}</Option>

})
    }
  </Select>

 <Form.Item style={{width:"90%", marginTop:"1rem"}}>
   <Input placeholder="product serch" onChange={productSeach}/>
 </Form.Item>

  {
    loading ?  <h5 className=" my-3"><Spin className="ml-5" tip="Loading..."/> </h5> : 
    
    products.map((item,i)=>{
      
      let productExist = comboProducts.findIndex((pr)=>{
          return pr._id === item._id
      })


      return <Fade bottom distance="50px" key={i}>  
      <Row align="middle" justify="start" className="my-2 shadow-sm p-2" style={{ width: '90%' }}> 

      <Col span={17}> 

      <Image
        width="40px"
        src={keyUri.BACKEND_URI + `/product-image/${item.image}`}
        placeholder={
          <Image
            preview={false}
            src="https://i.pinimg.com/originals/2c/bb/5e/2cbb5e95b97aa2b496f6eaec84b9240d.gif"
            width="100%"
          />
        }
      />


      {/* <img src={keyUri.BACKEND_URI + `/product-image/${item.image}`} alt="product-image" width="40px" /> */}
      <span style={{fontSize:"1rem", marginLeft:"1.5rem"}}>{ item.product_name} </span>
       </Col>

      <Col span={3}><h6> &#x20B9; { item.sale_price}</h6> </Col>
    {
      
      (productExist === -1)  ? <Col span={2}><Button size="small" type="ghost" style={{ color: "green", borderColor: "green" }} icon={<PlusOutlined />} onClick={()=>{ addItem(item) }} > Add </Button></Col> :
      <Col span={4}><Button size="small" type="primary" style={{ color: "red", background: "transparent", borderColor: "red" }} icon={<DeleteOutlined />} onClick={()=>{ removeItem(item._id) }} > Remove </Button></Col>
    }

      </Row> </Fade>

// onClick={()=>{  dispatch(logoutVendor()) }}

    } )
  }
  </Form.Item>


      </Form.Item>
<div>



</div>

      <Form.Item
       label="Total Price"
       name='current_total'
       style={{width:"92%"}}
       className='mr-5'
      >
            <InputNumber   placeholder={totalPrice} disabled/>     
      </Form.Item>
      
      <Form.Item
       label="Offer %"
       name='offer'
       style={{width:"92%"}}

       className='mr-5'
       rules={[{ required: true, message: 'offer price is required' }]}
          >
            <InputNumber  placeholder="% " />
      </Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
  
    </Form>
        </div>
          </Col>


          <Col span={8} className="shadow-sm rounded" style={{ backgroundColor:"#fbfbfb", padding:'20px 10px 20px 50px', overflowY:'auto', height:'85vh' }}>
            <h6>Selected Combo Products:</h6>
           
              {
               (comboProducts.length<1) ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : comboProducts.map((item,i)=>{
                    return <Fade bottom distance="50px" key={i}>  
               
                 <Row align="middle" justify="start" className="my-2 shadow-sm p-2" style={{ width: '90%' }}> 

                 <Col span={16}> 

                <Image
                  width="40px"
                  src={keyUri.BACKEND_URI + `/product-image/${item.image}`}
                  placeholder={
                    <Image
                      preview={false}
                      src="https://i.pinimg.com/originals/2c/bb/5e/2cbb5e95b97aa2b496f6eaec84b9240d.gif"
                      width="100%"
                    />
                  }
                />

                    <span style={{fontSize:"1.2rem", marginLeft:"1.5rem"}}>{ item.product_name} </span>
                    </Col>

                    <Col span={8}><h6> &#x20B9; { item.sale_price}</h6> </Col>

                   
                    </Row> </Fade>

                })
              }
         
          </Col>
        </Row>

      </CreateBlogWrap>
       
    )
}


const CreateBlogWrap = styled.div`


`