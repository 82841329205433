function removeDuplicate (data=[]) {

 
    // books = [ 
    //     { title: "C++", author: "Bjarne" }, 
    //     { title: "Java", author: "James" }, 
    //     { title: "Python", author: "Guido" }, 
    //     { title: "Java", author: "James" }, 
    // ]; 
  
    
  let  jsonObject = data.map(JSON.stringify); 
    
    
  let  uniqueSet = new Set(jsonObject); 
  let  uniqueArray = Array.from(uniqueSet).map(JSON.parse); 
   
 
   return uniqueArray

}

export default removeDuplicate
