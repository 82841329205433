import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import { keyUri, config } from '../key';
import {logoutVendor} from './auth'
const initialState = {
    
    combo:null,
    loading:false,
    hasError:false,
    currentCombo:[],
    allcombo:[],

}


export const comboSlice = createSlice({
    name: 'combo',
    initialState,
    reducers: {
  
      getCombo: state => {
        state.loading = true;
      },
      getComboSuccess: (state, {payload}) => {

        state.loading = false
        state.allcombo = payload
      },


  
      getCurrentCombo:(state, {payload}) => {
        state.loading = false
        state.combo = payload.combo
      },
  
      getComboFailure: (state) => {
  
        state.loading = false
        state.hasError = true
      },
  
    },
  });
  

export const { getCombo , getCurrentCombo,getComboSuccess, getComboFailure } = comboSlice.actions;
export const comboSelector = state => state.combo;

  
  export const fetchCombo = (id) => async dispatch => {
    dispatch(getCombo())
   try {

    const {data} = await axios.get(keyUri.BACKEND_URI + `/combos/${id}`, config)
    
    dispatch(getComboSuccess(data));
     
   } catch (error) {
  dispatch(getComboFailure())
   }
  }
  
  export const fetchCurrentCombo = (id) => async dispatch => {
      dispatch(getCombo())
      try {
     
       const {data} = await axios.get(keyUri.BACKEND_URI +`/combo/${id}`, config)
    
       dispatch(getCurrentCombo(data));
      } catch (error) {
    
     dispatch(getComboFailure())
     
      }
     };
    

  export const createCombo = (values, id) => async dispatch => {
    dispatch(getCombo())  
    const key = 'create';
      message.loading({ content: 'loading...', key })
   
    try {
   
     const {data} = await axios.post(keyUri.BACKEND_URI +`/combo/${id}`,values,config)
 
     data && message.success({content:data.msg, key, duration:2})
     dispatch(fetchCombo(data))

     data && window.location.reload()
  
    } catch ({response}) {
      response.data && message.error({ content: response.data.msg, key, duration: 2 });
      response.data && dispatch(logoutVendor())
    }
   }
  


export const deleteCombo = (id) => async dispatch =>{
    dispatch(getCombo())

    const key = 'create';
    message.loading({ content: 'loading...', key })

try {
    const {data} = await axios.delete(keyUri.BACKEND_URI +`/combo/${id}`, config)
    window.location.reload()
    data && message.success({content:data.msg, key, duration:2})

} catch (response) {
  response.data && message.error({ content: response.data.msg, key, duration: 2 });
  response.data && dispatch(logoutVendor())
  dispatch(getComboFailure())

}
}


export const  updateCombo = (id, values) => async dispatch =>{
    const key = "combo"
    dispatch(getCombo())
    message.loading({ content: 'loading...', key })
  
  try {
      const {data} = await axios.put(keyUri.BACKEND_URI +`/combo/${id}`, values, config);
      data && message.success({ content: data.msg, key, duration: 2 });
      dispatch(fetchCombo())
  
  } catch ({response}) {
    response.data && message.error({ content: response.data.msg, key, duration: 2 });
    response.data && dispatch(logoutVendor())
    dispatch(getComboFailure())
  
  }
  }
  
  export default comboSlice.reducer;
  