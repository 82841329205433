import React, {useEffect, useState} from 'react'
import { Form, Input, Button, InputNumber,Select, Upload , Radio, Space , Switch, message} from 'antd';
// import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {fetchSupplier, supplierSelector} from '../../../api/supplier'
import {updateProduct, productsSelector,fetchAllOrders,fetchProducts, fetchCurrentStore, fetchCurrentProduct} from '../../../api/product'
import {useParams} from 'react-router-dom'
import Loader from '../../shared/loader';
import { UploadOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../api/auth'
import axios from 'axios'
import { keyUri, config } from '../../../key';


const { Option } = Select;


const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};


export default function EditProduct() {
  
 
  const dispatch = useDispatch()

  const { vendor, loading} = useSelector(vendorAuthSelector)
  const {products} = useSelector(productsSelector)
  const [form] = Form.useForm();
  const {id} = useParams()
  const [categories, setCategories] = useState([])
  const {suplliers} = useSelector(supplierSelector)

  const [currProduct, setCurrProduct] = useState(null)
  const [imgStatus, setImgStatus] = useState(false)
  const [isSupplierChange, setIsSupplierChange] = useState(false)

  const [result, setResult] = useState('')
  const [code, setCode] = useState(null)


useEffect(()=>{

    axios.get(keyUri.BACKEND_URI + `/current-store-categories/${vendor._id}`, config).then((data=>{

      setCategories(data.data.categories)

    }))
    currProduct && setCode(currProduct.barcode)

  }, [dispatch])


  useEffect(()=>{
       axios.get(keyUri.BACKEND_URI + `/one_product/${id}`, config).then((data)=>{
        setCurrProduct(data.data)
       })
      vendor && dispatch(fetchAllOrders(vendor._id))
      }, [dispatch])
  

      useEffect(()=>{
        dispatch(fetchSupplier(vendor._id))
     
        dispatch(fetchProducts(vendor._id))
       

       }, [dispatch])
    
      useEffect(()=>{
          form.setFieldsValue({
            category_name:currProduct && currProduct.categories.category_name,
            product_name:currProduct && currProduct.product_name,           
            sale_price:currProduct && currProduct.sale_price,
            MRP:currProduct && currProduct.MRP,
            GST:currProduct && currProduct.GST,
            quantity:currProduct && currProduct.quantity,
            supplier:currProduct && currProduct.supplier ? currProduct.supplier.supplier_name : null,
            // image:currProduct && currProduct.image,
            product_description:currProduct && currProduct.product_description,
            stock:currProduct && currProduct.stock,
            barcode: currProduct && currProduct.barcode,
            // isRecommended: currProduct && currProduct.isRecommended

            });
  
          
      }, [currProduct])

  
   
      const normFile = e => {
        console.log('Upload event:', e);

        if(e.file.status === 'removed')
      {
        setImgStatus(true)
      }
      if(e.file.status === 'done')
      {
        setImgStatus(false)
      }

        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList[0];
        
      };



  const handleScan = (data) =>{
     setResult(data)
    }
    
const handleBarCode = (e) =>{

  setCode(e.target.value)  
  }
  
  const handleError = (err) => {
      console.log(err);
     }
 
      
  const  onSelectCategory = (value) => {
    console.log(`selected ${value}`);
  }

  const  onSelectSupplier = (value) => {
    console.log(`selected ${value}`);
    setIsSupplierChange(true)
  }


  const onSearch = (val) => {
    console.log('search:', val);
  }


      const onFinish = (values) => {

      //   if(values.isRecommended){
      //     const p = products.filter(item=>{
      //       return item.isRecommended 
      //    })
      //    if(p.length >= 10){
      //        return message.info("Can't add more than 10 recommended products")
      //   }
      // }


        const imgdata = {

          category_name:values.category_name,
          product_name:values.product_name,
          sale_price:values.sale_price,
          MRP:values.MRP,
          GST:values.GST,
          quantity:values.quantity,
          supplier: isSupplierChange ? values.supplier : currProduct.supplier && currProduct.supplier._id ,

          image: values.image ? values.image.response.file.id : currProduct.image,

          product_description:values.product_description,
          stock:values.stock,
          barcode:result === ''? code : result,
          // isRecommended: values.isRecommended

        }

        dispatch(updateProduct(id, imgdata))

        };

        const onFinishFailed = (errorInfo) => {
          console.log('Failed:', errorInfo);
        };


        const onChangeRecommended = e => {
          const data = {
          excahange: e.target.value,
      }
      };
      


        const fileList =  [  
          {
            uid: '-1',
            name: 'Current image',
            status: 'done',
            url: keyUri.BACKEND_URI + `/product-image/${currProduct && currProduct.image}`,
            thumbUrl: keyUri.BACKEND_URI + `/product-image/${currProduct && currProduct.image}`,
          }];
        
    
          
    return (          
    <>  
           <div className="container ml-3">
              <h5 className="mb-5">Update Product</h5>
              <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
      <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      form={form}
      onFinishFailed={onFinishFailed}
    >
      
      <Form.Item
       label="Category"
       rules={[{ required: true, message: 'Please input Category Name'}]}
      >
      <Input.Group compact>
              <Form.Item
              name='category_name'
              rules={[{ required: true, message: 'Category Name is required' }]}
              
               >
            <Select
            disabled
                showSearch
                style={{ width: 200 }}
                placeholder="Select Category"
                optionFilterProp="children"
                onChange={onSelectCategory}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
            {
            categories.map((item, i)=>{
              return  <Option key={i} value={item.category_name}>{item.category_name}</Option>
            })
            }
          </Select>
          </Form.Item>

      </Input.Group>
      </Form.Item>



      <Form.Item
       label="Product"
       rules={[{ required: true, message: 'Please input Store Location!' }]}

      >
      <Input.Group compact>
        

      <Form.Item
            name='product_name'
       
            rules={[{ required: false, message: 'product name is required' }]}
          >
            <Input style={{ width: '90%' }} placeholder="product name" />
          </Form.Item>

      <Form.Item
            name='sale_price'
       
            rules={[{ required: true, message: 'sale price is required' }]}
            className="mr-4"
          >
            <InputNumber placeholder="Sale price" />
          </Form.Item>


          <Form.Item
            name='MRP'
       
            rules={[{ required: true, message: 'MRP is required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="MRP " />
          </Form.Item>

          <Form.Item
            name='GST'
       
            rules={[{ required: true, message: 'GST is required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="GST " />
          </Form.Item>    
      </Input.Group>
      </Form.Item>
   

<Form.Item
       label=" "
       rules={[{ required: true, message: 'quantity is required!' }]}

      >

<Input.Group compact>
          

        <Form.Item
              name='image'
              style={{ width: '22.7%' }}
              valuePropName="file"
              getValueFromEvent={normFile}
            >
             {currProduct &&  
              <Upload   name="product_image" action= {keyUri.BACKEND_URI +"/product-image"} 
              maxCount={1}
             listType="picture"  defaultFileList={[...fileList]} >
                <Button disabled={imgStatus ? false : true} icon={<UploadOutlined />}>Upload Product Image</Button>
              </Upload>
            
}
            </Form.Item> 
              {/* {currProduct && <img className="mt-2 mx-3" style={{width:"80px", height:"90px", objectFit:"cover"}} src={keyUri.BACKEND_URI + `/product-image/${currProduct.image}`} />}  */}



        <Form.Item
            name='quantity'
      
            rules={[{ required: true, message: 'quantity is required' }]}
            className="ml-4"
          >
            <Input style={{ width: '90%' }} placeholder="quantity" />
          </Form.Item>


          <Form.Item
            name='stock'
            rules={[{ required: true, message: 'stock is required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="stock " />
          </Form.Item>   
        </Input.Group>
      </Form.Item>


   
    
        
<Form.Item
       label="Supplier"
       rules={[{ required: true, message: 'Please input Category Name'}]}
      >
      <Input.Group compact>
      <Form.Item
            name='supplier'
            // rules={[{ required: true, message: 'Supplier Name is required' }]}
          >
          <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select Supplier"
              optionFilterProp="children"
              onChange={onSelectSupplier}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.indexOf(input) >= 0
              }
            >
              {
          suplliers && suplliers.map((item, i)=>{

            return  <Option key={i} value={item._id}> {item.supplier_name}</Option>

          })
              }
            </Select>
            </Form.Item>   
            </Input.Group>
          </Form.Item>

      
      <Form.Item
        label="Product Description"
        name="product_description"
        rules={[{  message: 'Please input your product description!' }]}>
     <Input.TextArea />
</Form.Item>

{/* <Form.Item
        label="Is Recommended "
        name="isRecommended"
        >

<Radio.Group  onChange={onChangeRecommended} >
            <Radio.Button value={false}>No</Radio.Button>
             <Radio.Button value={true}>Yes</Radio.Button>
             </Radio.Group>

</Form.Item> */}

<Form.Item
        label="Barcode" 
        name="barcode"
        >
 
   <Input style={{ width: '50%' }} onChange={(e)=>handleBarCode(e)} 
   disabled={result === ''? false :true }
   placeholder={result === ''? 'scan or enter barcode': result}/>

</Form.Item>

<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
   

    </Form>

           </div>
       
       
               </>
           ) 
           }
       