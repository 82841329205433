import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../shared/loader'
import BarcodeReader from 'react-barcode-reader'
import DataTable from './datatable'
import Fade from 'react-reveal/Fade'
import {Button, Image,Alert, Row, Col, Select,Modal,InputNumber, Form, Radio, Input, message} from 'antd'
import {Link} from 'react-router-dom'
import {PlusOutlined} from '@ant-design/icons'
import {vendorAuthSelector} from '../../../api/auth'
import { productsSelector, fetchAllOrders, createOrder, posArrayProduct} from '../../../api/product'
import Axios from 'axios';
import {keyUri, config} from '../../../key'

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};


const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 10,
  },
};

export default function InvoiceIndex() {
    const [visible, setVisible] = useState(false);  
    const [result, setResult] = useState('')
    const [msg, setMsg] = useState(null)
    const [press, setPress] = useState(false)
    const [isNotfound, setIsNotfound] = useState(false)
    const [stock, setStock] = useState(0)
    const [product, setProducts] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isGlobal, setGlobal] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [saleprice, setSaleprice] = useState(0)


    const [isModalVisible, setIsModalVisible] = useState(false);
    const {vendor} = useSelector(vendorAuthSelector)

    
    const [isPayment, setPayment] = useState('Cash') 
    
    const [addProduct, setAddProducts] = useState([])
    // const [arrayProducts, setarrayProducts] = useState([])
    const {arrayProducts} = useSelector(productsSelector)


    const dispatch = useDispatch();

    const [form] = Form.useForm();

    !msg && setTimeout(()=>{  
      setMsg(null)
    }, 6000)
    
      const removeItem = (id) =>{
      let s = arrayProducts.filter((item)=>{
      return item._id !== id
      })
      // setarrayProducts(s)
      dispatch(posArrayProduct(s))
       }
  
      

    
useEffect(()=>{
  vendor && dispatch(fetchAllOrders(vendor._id))

  }, [visible, stock])
  
    
  useEffect(()=>{
    Axios.get(keyUri.BACKEND_URI + `/products/${vendor._id}`, config).then((data=>{

      setAddProducts(data.data.product)
      setPress(true)
    }))
  }, [dispatch])


const onChange = e => {
  setPayment(e.target.value)
};

const showAddModal = () => {
  setIsModalVisible(true);
};

const closeAddModal = () => {
  setIsModalVisible(false);
  setStock(0)
  setQuantity(0)
  setSaleprice(0)
  setProducts(null)
}



  const handleScan = (scandata) =>{
    if(scandata){   

       Axios.get(keyUri.BACKEND_URI + `/billing-product/${scandata}?store=${vendor._id}`, config).then((data=>{


      if(data.data.isNotfound){
        message.info('Product not Found')
      }
       

      if(data.data.product.stock < 1){

       
        return message.warn('Out of stock!')

      }
        setIsNotfound(data.data.isNotfound)
        if(!data.data.isNotfound) {
  
          setProducts(data.data.product)
          !data.data.isglobal && setStock(data.data.product.stock)
          setGlobal(data.data.isglobal)
  
          let p={
            ...data.data.product, 
            unit: 0,
            sale_price: data.data.product.sale_price,
            discount: 0
          }

          

          if(p.stock === 0){
            return message.info('Out of Stock')
          }
          
          dispatch(posArrayProduct([...arrayProducts, p], p))

          form.resetFields();
  
        }
    }));  

      // setResult(scandata)
      // setVisible(true)
      }
      setPress(true)
  
}


  const handleError = (err) => {
    console.error(err)
  }


const closeModal = () =>{
  setVisible(false)
  setStock(0)
    }


  

    const onFinish = async (values) =>{
      setLoading(true)
      if(press) {
        
        let p={
          ...product, 
          unit:values.units,
          sale_price:values.sale_price,
          discount:values.discount ? values.discount : 0
        }
        // setarrayProducts([...arrayProducts, p])
        dispatch(posArrayProduct([...arrayProducts, p]))

        
        setQuantity(0)
        setSaleprice(0)
        setProducts(null)
      setLoading(false)
      closeModal()
      }
      form.resetFields();
      setPress(true) 
    }


    
    const onAddFinish = async (values) =>{
      setLoading(true)
      if(press) {
        let p={
          ...product, 
          unit:values.units,
          sale_price:values.sale_price,
          discount:values.discount ? values.discount : 0
        }
        

      if(p.unit === undefined) {

        setLoading(false)
   
   return   message.error('required!');

      }

       setQuantity(0)
        setSaleprice(0)
      setProducts(null)
      dispatch(posArrayProduct([...arrayProducts, p]))
      setLoading(false)
      closeAddModal()
      }
      form.resetFields();
      setPress(true) 
    }

    

const onFinishPayment = async(values) =>{
 
form.resetFields();
setPayment('Cash')
  dispatch(createOrder(values, arrayProducts))
  
}




  const onFinishFailed = (errorInfo) => {
  

  console.log('Failed:', errorInfo);
  };



  const deleteProduct = (id) =>{
 
const afterDelete =   arrayProducts.filter(item => {

  return item._id !== id
})


dispatch(posArrayProduct(afterDelete))
  }


  const onBlur = () => {
  }
  
  const onFocus = () => {
  }

  const onSearchSelect = (value) => {
    console.log('search:', value);
  }

  
  
  const  onSelectProduct = (value) => {    
    Axios.get(keyUri.BACKEND_URI + `/one_product/${value}`, config).then((data=>{
   

      let p={
        ...data.data, 
        unit: 0,
        sale_price: data.data.sale_price,
        discount: 0
      }

if(p.stock === 0){
  return message.info('Out of Stock')
}
      dispatch(posArrayProduct([...arrayProducts, p], p))

      form.resetFields();
    }))
      
    closeAddModal()
    form.resetFields();

  }



    return (

    <div>
    <div className="d-flex mb-4 align-items-center">
       
        <Button type="ghost" icon={<PlusOutlined />} onClick={showAddModal}>
            Add Item </Button>
    
      <Modal 
      title="Add Product"
      footer={false}
      visible={isModalVisible} 
      onCancel={closeAddModal} 
      closable = {false}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onAddFinish}
         
          onFinishFailed={onFinishFailed}
          form={form}
            >
      <Form.Item
            label="Product"
            rules={[{ required: true, message: 'Please input Product Name'}]}
            >
            <Input.Group compact>
        
            <Select
                showSearch
                style={{ width: 200 }}
                className="mr-3"
                placeholder="Select product"                
                optionFilterProp="children"
                onChange={onSelectProduct}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearchSelect}
                rules={[{ required: true, message: 'Enter product' }]}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                    addProduct && addProduct.map((item, i)=>{
                    return <Option key={i} value={item._id}>{item.product_name}</Option>
                  })
                }
                
                </Select>
            </Input.Group>
            </Form.Item>
 
        
     </Form>
      </Modal>



        <BarcodeReader
          onError={handleError}
          onScan={handleScan}
          />  
        <Modal
        footer={false}
        visible={visible}
        onCancel={closeModal}
        closable = {false}
        >
        
{
(isNotfound || isGlobal)  && <div>
<h3 className="text-danger text-center">Product Not Found!</h3>
 
</div>
}


{

!isNotfound && msg &&  <Fade><Alert className="mb-3" message={msg} type="success"   />
</Fade>
}
<div className="d-flex mb-3 align-items-center justify-content-around">
<p>BarCode: <b className="text-secondary">{result}</b></p>

 { product && !isNotfound && !isGlobal  && <p>Current Stock : <b className="text-success">{ stock}</b></p>}

</div>

{

loading ? "Loading..." :  !isNotfound && product && !isGlobal  &&   <Form
   {...layout}
   name="basic"
   initialValues={{ remember: true }}
   onFinish={onFinish}
   onFinishFailed={onFinishFailed}
   form={form}
     >
<div style={{paddingLeft:'25%'}}>
<Image 
      width="40px"
       src={keyUri.BACKEND_URI +`/product-image/${product.image}`}
       
        placeholder={
          <Image
            preview={false}
            src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif"
            width="100%"
          />
        }
      />
      { product && <b style={{fontSize:'1.5rem', paddingLeft:'5%'}}>{ product.product_name}</b>}
      </div>
   

    <Form.Item
       label="Quantity"
       name="units"
       initialValue={1}
       style={{paddingTop:'20px'}}
       rules={[{ required: true, message: 'Enter Quantity' }]}
     >
       <InputNumber  min={1} max={stock} style={{ width: '30%' }}/>
     </Form.Item>

     <Form.Item
       label="Sales price"
       name="sale_price"
       initialValue={product.sale_price}
       rules={[{ required: true, message: 'Enter Sales price' }]}
     >
       <InputNumber style={{ width: '30%' }}/>
     </Form.Item>

     <Form.Item
       label="Discount %"
       name="discount"
      //  rules={[{ required: true, message: 'Enter Discount' }]}
      

     >
       <InputNumber style={{ width: '30%' }}/>
     </Form.Item>
   

     <Form.Item {...tailLayout}>
        <Button type="primary"  htmlType="submit" > Submit</Button>
      </Form.Item>          
</Form>

}
        </Modal>

    </div>
         <Row gutter={20}>
            <Col  xl={18} xxl={19}  >

                {
               
                loading ? <Loader/> : <DataTable click={deleteProduct} data={ arrayProducts } />

                } 

            </Col>
            <Col  xl={6} xxl={5} className=" shadow-sm rounded pb-3 p-0" style={{ backgroundColor:"#fafafa", height:'100%', }}>
            
            
             <div style={{ backgroundColor: "#605e75", height:'5vh', textAlign:'center', paddingTop:'8px',  }}>
               <h5 className='text-white'> Billing </h5></div>
               <div className="py-3 px-2">
              <div >
                 <h6>Total : <b style={{paddingRight:'15%', float:'right'}}>&#x20B9; {
                   arrayProducts.reduce((total, num)=>{
                     return total+(num.sale_price*num.unit)
                   },0)}</b></h6>

           

                 <h6>Discount: <b style={{paddingRight:'15%', float:'right'}}>{
                   arrayProducts.reduce((total, num)=>{
                     return total+num.discount
                   },0)}% </b></h6>

                 

                 <h5 style={{ margin:'0 0 15px 0', borderTop:'1px solid #6E6D6D', paddingTop:'10px'}}>Net Total: 
                 <b style={{paddingRight:'15%', float:'right'}}>&#x20B9; {
                   arrayProducts.reduce((total, num)=>{
                     return total + parseInt(num.sale_price*(((100 - num.discount)/100)*num.unit) )
                   },0)}</b>
                 </h5>

                 <div style={{padding:'15px 5px 0 0px' }}>
                <Form
                {...layout}
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinishPayment}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                    label="Name:"
                    name='name'
                    >
                  <Input style={{ width: '90%' }} placeholder="Name" />
                </Form.Item>
                <Form.Item
                    label="Phone:"
                    name='phone_number'
                  
                    rules={[
                      {required:true},
                      {min: 10},
                      {max:10},
                      {pattern:"[0-9]", message:"Only Numbers"}
                    ]} >
                  <Input style={{ width: '90%' }} placeholder="Phone Number" />
                </Form.Item>
                <Form.Item
                    label="Email ID:"
                    name='email'>
                  <Input style={{ width: '90%' }} placeholder="Email Id" />
                </Form.Item>
         
                 <h6 style={{ margin:'15px 0 0 0'}}>pay via :</h6>
                
                 <Form.Item name="payment" initialValue={isPayment} >
                 <div className="d-flex my-2 align-items-center justify-content-between">
                 <Radio.Group onChange={onChange} value={isPayment}>
                 <Radio value="Cash">Cash</Radio>
                 <Radio value="Card">Card</Radio>
                 <Radio value="UPI">UPI</Radio>
                 </Radio.Group>
                 </div>
                 </Form.Item>

                 <Button type="primary" htmlType="submit" disabled={arrayProducts.length < 1} style={{marginLeft:'25%'}}>PAY AMOUNT </Button>
                 </Form>
    </div>
      </div>

                
            </div>

          

           </Col>
        </Row>  

    </div>

    )
}