import React, {useState, useEffect} from 'react'
import { Layout, Affix } from 'antd';
import styled from 'styled-components'
import SideBar from './sidebar'
import Header from './header'
import {  Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateProduct from './pages/products/createProduct'
import EditProducts from './pages/products/editProduct'
import Products from './pages/products'

import Category from './pages/category'

import AddProduct from './pages/products/addProduct'

import Suppliers from './pages/supplier'
import CreateSupplier from './pages/supplier/createSupplier'
import EditSupplier from './pages/supplier/editSupplier'

import Home from './pages/dashboard'
import Inventory from './pages/inventory'
import EditInventory from './pages/inventory/editInventory'

import Settings from './pages/settings'

import Combo from './pages/combo'
import CreateCombo from './pages/combo/createCombo'
import EditCombo from './pages/combo/editCombo'

import Offer from './pages/offer'
import CreateOffer from './pages/offer/createOffer'
import EditOffer from './pages/offer/editOffer'

import Invoice from './pages/invoice'
import {fetchVendorProfile, vendorAuthSelector} from '../api/auth'
import Orders from './pages/order'
import OrderView from './pages/order/orderView'

import Customer from './pages/customers/index'
import OrderAnalytics from './pages/dashboard/anaytics'


import Loader from './shared/loader'


import {useDispatch, useSelector} from 'react-redux'
import Chat from './pages/chat';

const {  Content } = Layout;


export default function Admin({history, location}) {

  let { path } = useRouteMatch();


  const istheme = JSON.parse(localStorage.getItem('theme'))  

    const [collapsed, setCollapsed] = useState(false)
    const [theme, setTheme] = useState(true)
    const [currentpath, setCurrentPath] = useState(location.pathname)


const dispatch = useDispatch()
  const {isAuthenticate, vendor, token, loading} = useSelector(vendorAuthSelector)


  

useEffect(()=>{
setTheme(istheme)
dispatch(fetchVendorProfile(token));

if(loading) {
  return <Loader/>
}

if (!isAuthenticate && !loading) {

return  history.push('/');
}

history.push(location.pathname);
  
}, [dispatch, isAuthenticate, history ])




    const  toggle = () => {

        setCollapsed(!collapsed)

      
      };

      const changeTheme = () =>{

        localStorage.setItem('theme', !theme)
        setTheme(!theme)
    
    }
 

 
 console.log(theme);

    return (
        <AdminWrap color={theme}>
        <Layout>
     
        <Affix offsetTop={0} onChange={affixed => console.log(affixed)}>

     <SideBar collapsed={collapsed} color={theme}  click={changeTheme}/>
     </Affix>

        <Layout className="site-layout">
        
        <Header click={toggle} collapsed={collapsed}/>


     {vendor  &&  <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
        
        <Switch>

<Route  exact path={`${path}/home`}  component={Home} />
<Route  exact path={`${path}/category-list`}  component={Category} />
<Route  exact path={`${path}/edit-products/:id`}  component={EditProducts} />
<Route  exact path={`${path}/edit-products/:id`}  component={EditProducts} />
<Route  exact path={`${path}/suppliers`}  component={Suppliers} />
<Route  exact path={`${path}/create-supplier`}  component={CreateSupplier} />
<Route  exact path={`${path}/edit-supplier/:id`}  component={EditSupplier} />
<Route  exact path={`${path}/combo`}  component={Combo} />
<Route  exact path={`${path}/create-combo`}  component={CreateCombo} />
<Route  exact path={`${path}/edit-combo/:id`}  component={EditCombo} />
<Route  exact path={`${path}/offers`}  component={Offer} />
<Route  exact path={`${path}/create-offer`}  component={CreateOffer} />
<Route  exact path={`${path}/edit-offer/:id`}  component={EditOffer} />
<Route  exact path={`${path}/invoice`}  component={Invoice} />
<Route  exact path={`${path}/products-list`}  component={Products} />
<Route  exact path={`${path}/create-product`}  component={CreateProduct} />
<Route  exact path={`${path}/add-product`}  component={AddProduct} />
<Route  exact path={`${path}/orders`}  component={Orders} />
<Route  exact path={`${path}/order-view/:id`}  component={OrderView} />
<Route  exact path={`${path}/chat`}  component={Chat} />
<Route  exact path={`${path}/settings`}  component={Settings} />      
<Route  exact path={`${path}/inventory`}  component={Inventory} />          
<Route  exact path={`${path}/edit-inventory/:id`}  component={EditInventory} />
<Route  exact path={`${path}/customers`}  component={Customer} />
<Route  exact path={`${path}/analytics`}  component={OrderAnalytics} />

</Switch>
          </Content> }
        </Layout>
      </Layout>
      </AdminWrap>
    )
}


const AdminWrap = styled.div`


.ant-layout {

.ant-layout-sider{
    height: 100vh;
    overflow-y: auto;
    background:${props => props.color ? "#001529" : "#ffffff"};
}
}



.logo {
height: 32px;
background: rgba(255, 255, 255, 0.2);
margin: 16px;
}



#components-layout-demo-custom-trigger .logo {
height: 32px;
background: rgba(255, 255, 255, 0.2);
margin: 16px;
}

.site-layout .site-layout-background {
background: #fff;
}

`