import React,{useEffect} from 'react'
import styled from 'styled-components'
import { fetchVendorlogin,  vendorAuthSelector} from '../../api/auth'
import {  useDispatch, useSelector } from 'react-redux';
import LoginSection from '../shared/login'
import backimg from '../../images/background.jpg'


export default function Login({history}) {


  const dispatch = useDispatch()
  const {isAuthenticate, loading} = useSelector(vendorAuthSelector)


  useEffect(()=>{


if(loading && !isAuthenticate) {

  return <h1>Loading.....</h1>

}

        if(!isAuthenticate && !loading){
            history.push('/')
        }
        else{
            window.location.href = '/dashboard/home'
        }
  },[history, isAuthenticate])

    const onFinish = values => {

         dispatch(fetchVendorlogin(values))
        
     
      };
    
      // const onFinishFailed = errorInfo => {
      //   console.log('Failed:', errorInfo);
      // };
     

    return (
      <LoginWrap style={{backgroundImage:`url('${backimg}')`}}>
       

<LoginSection onFinish={onFinish} title="Vendor Login Form" urllink="/register"/>

  
   </LoginWrap>
    )
}


const LoginWrap = styled.div`

height:100vh;
background-size:cover;
position:relative;

&:after{

  width:100%;
  height:100%;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  content:"";
  background-color:#00000073;


}


`