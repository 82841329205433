import React, {useEffect} from 'react'
import {fetchAllOrders,productsSelector} from '../../../api/product'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../shared/loader'
import {vendorAuthSelector} from '../../../api/auth'
import DataTable from './datatable'
import { Button } from 'antd'
import { Link } from 'react-router-dom'



// const {FormItem} = Form
export default function Products() {

  const dispatch = useDispatch()
  const {orders, loading } = useSelector(productsSelector)
  const {vendor} = useSelector(vendorAuthSelector)



useEffect(()=>{

dispatch(fetchAllOrders(vendor._id))

}, [dispatch])



    return (<>

    {/* <div className="flex align-items-center justify-content-between">
    
<h4>Orders</h4>
</div> */}
{
  
loading? <Loader/> : <DataTable data = {orders}/>

}
    </>
       )
}  







