import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import {Table, Select, Button } from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import {productsSelector,updateOrder,fetchAllOrders, updateProductOrder,updateOrderPayment, fetchCurrentOrder} from '../../../api/product'
import moment from 'moment'
import Vendorchat from './vendorchat'
import DeleteConfirm from '../../shared/deleteConfirm'
import {vendorAuthSelector} from '../../../api/auth'


const { Option } = Select;

export default function OrderView() {
    const {id} = useParams()
    const dispatch = useDispatch()
    const { vendor} = useSelector(vendorAuthSelector)

    const {currentOrder, loading} = useSelector(productsSelector)
const [status, setStatus] =  useState()
 

    useEffect(()=>{
        dispatch(fetchCurrentOrder(id))
        vendor && dispatch(fetchAllOrders(vendor._id))

    }, [dispatch])



  
//   const  onSelectPaymentStatus = () => {
//       const orderdata = {
//           paymentStatus:'Paid',
//           user:currentOrder.user._id
//         }
//     dispatch(updateOrderPayment(currentOrder._id, orderdata ))
//     };


    const confirmpaid = () => {

        const orderdata = {
            paymentStatus:'Paid',
            user:currentOrder.user._id
          }
          dispatch(updateOrderPayment(currentOrder._id, orderdata ))


}


   const  onSelectOrderStatus = (e) => {

        setStatus(e.target.innerText)
        };
    
const confirm = () => {

     if(status === 'Reject') {


        let reason = prompt('Please Mention Reason');

        if(reason) {

             const orderdata = {
        orderStatus:status,
        user:currentOrder.user._id,
        reason:reason
           }
  return  dispatch(updateOrder(currentOrder._id, orderdata ))
        }

     }

    const orderdata = {
        orderStatus:status,
        user:currentOrder.user._id
    }
    dispatch(updateOrder(currentOrder._id, orderdata ))


}

    const icolumns = [
        {
            title:"Product Name",
            dataIndex:"name",           
        },
        {
            title:"Quantity",
            dataIndex:"quantity",
           
        },
        {   
            title:"Unit Price ",
            dataIndex:"value",  
            key: 'value',
            render:(value) =>{

                return <p>&#x20B9;{value} </p>
              }
        }
    ]

    return (
        <SDataModelWrap>
            {currentOrder && <div>
         <div className="d-flex justify-content-between align-items-start">
            <div>
                <h6>Order ID :&nbsp; { currentOrder.orderId}</h6>
                <h6 >Order Type : &nbsp;<b className="text-info">{currentOrder.isTakeAway }</b> </h6>

                {
                        (currentOrder.user) ?
                        <div>
                            <h6 >Phone No :  &nbsp;{currentOrder.user.phone_number } </h6>
                        </div> :''
                    }
                {
                    currentOrder.user && currentOrder.isTakeAway === 'homedelivery' ?
                     <h6>Delivery Address :&nbsp; {currentOrder.address.door_number}, {currentOrder.address.building_name},{ currentOrder.address.address}</h6>

                    :''
                }

                    
                {
                (!currentOrder.user) ? <div>
                    <h6>Name:&nbsp; { currentOrder.offline_userName }</h6>
                    <h6>Email: &nbsp; { currentOrder.offline_user ?  currentOrder.offline_user : "null"} </h6>
                   <h6> Phone No:&nbsp; {currentOrder.offline_userPhone ? currentOrder.offline_userPhone :'null'} </h6>

                </div> : <div>
                </div>

                }

            </div>
            <div>
                  <h6>{moment(currentOrder.createdAt).format('MMM Do YY, h:mm a')}</h6>
            </div>
          </div>
          <div className="d-flex my-4 justify-content-between align-items-start">
                     <div>                        
                    <h6>Sub Total : &#x20B9; {currentOrder.total}</h6>
                    <h6>Delivery : &#x20B9; {currentOrder.delivery_charge}</h6>
                    <h5>Total : &#x20B9; {(currentOrder.total + currentOrder.delivery_charge)}</h5> 
                    <br/>
                
                    </div>
                    </div>
                  <div className="d-flex justify-content-start align-items-center mb-3">
                
         


  <DeleteConfirm disabled={(currentOrder.orderStatus === 'Delivered' || currentOrder.orderStatus === 'Reject')? true : false }   confirm={confirm} action="update" title="order status">
<Button style={{transform:"translateY(-3px)", 
backgroundColor:currentOrder.orderStatus === 'Reject'? "#b31515":currentOrder.orderStatus === 'Delivered' ? "#f5f5f5":"green",
 borderColor:currentOrder.orderStatus === 'Reject'?
  "#b31515":currentOrder.orderStatus === 'Delivered' ? "#f5f5f5":"green"}} type="primary"
  disabled={(currentOrder.orderStatus === 'Delivered')? true : false }   onClick={onSelectOrderStatus}>
   

                                {(currentOrder.data_state === 0) && "Accept" } 
                                {(currentOrder.data_state === 1) && "Packing" } 
                                {(currentOrder.data_state === 2) && (currentOrder.isTakeAway === 'homedelivery'?
                                'Out for delivery' : 'Ready to Delivered')
                                } 
                                {(currentOrder.data_state === 3 ) && 'Delivered'} 
                                {(currentOrder.data_state === 4) && "Delivered" } 
                                {(currentOrder.data_state === -1) && "Rejected" } 

                            </Button>

                       </DeleteConfirm> 

                    

{                       (currentOrder.orderStatus === 'Unfulfilled') && 
<DeleteConfirm   confirm={confirm} action="Reject" title="order status">
                            <Button on className="ml-2" danger style={{transform:"translateY(-3px)"}} type="primary" disabled={(currentOrder.orderStatus !== 'Unfulfilled')? true : false }   onClick={onSelectOrderStatus}>
                               Reject
                    
                            </Button>
                       </DeleteConfirm>


}                       

       {  (currentOrder.data_state !== 4 && currentOrder.data_state !== -1 ) &&
                  <div className="pl-4">
                        <label> Payment Status : </label> &nbsp;
                        <DeleteConfirm   confirm={confirmpaid} action="Paid" title="Payment Status">

                         <Button 
                        
                        disabled = {currentOrder && (currentOrder.paymentStatus === 'Unpaid') ? false  : true }
                        type="primary">
                            
                            {currentOrder && currentOrder.paymentStatus}
                            </Button>   </DeleteConfirm>  
                        </div>}

                     
                       
          </div>
             {
                   <Table columns={icolumns} dataSource={currentOrder.productOrder} />
              }



            </div>}
        

          
        </SDataModelWrap>

        
    )
}


const SDataModelWrap = styled.div`

position:relative;

`