import React from 'react'
import Loader from '../../shared/loader'
import {Row, Col, Button, Empty, Space} from 'antd'

import moment from 'moment'
import styled from 'styled-components'
import {Link} from 'react-router-dom'


export default function Orders({data, loading}) {


    if(data.length < 1) {

        return  ( <div className="pt-4">
               {
                loading ? <Loader/> : <Empty description={"No New Orders"} /> 
               }
            
            </div>)

    }

    return (
        <OrderWrap className="container my-3 ">
            <div>
                { 
                  data.map((item, i)=>{ 
                   return  <div key={i} className="row bg-white shadow-sm rounded p-2 my-2">
                       <div className="col-sm-3">
                            <p className="mb-0">{ moment(item.createdAt).format(" ddd hh:mma")}</p>
                          </div>
                          <div className="col-sm-4">

                            <h6 className="text-left mb-0" style={{color:(item.isTakeAway=== 'homedelivery' ) ? 'red' : 'green'   }} className="mx-2 text-capitalize" >{item.isTakeAway} </h6>
  </div>
  <div className="col-sm-3">
                            <h6 className="mx-2 mb-0" >{item.orderId} </h6>
                            </div>
                            <div className="col-sm-2">

                            <Space>
    
                             <Link to={`/dashboard/order-view/${item._id}`}>
                             <Button type="primary" className=" bg-success px-3 ml-3" style={{borderColor:"#28A745"}} >View</Button>
                             </Link>
                        </Space>
                        </div>
                        </div>

                    })
                }
                  </div>

             </OrderWrap>
    )
}


const OrderWrap =  styled.div`


`