import React from 'react'
import { Table, Tag, Space, Descriptions } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../../shared/deleteConfirm'
import {useDispatch} from 'react-redux'
import {deleteSupplier} from '../../../api/supplier'
import {Link} from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import moment from 'moment'
import DescTable from '../../shared/insidetableview'
export default function Datatable({data}) {

   

    const dispatch = useDispatch()

    const confirm = (e, id) => {
    
        dispatch(deleteSupplier(id._id))
       
      }
      
      const cancel = (e) =>{
        return null
      }


    const columns = [
        {
          title: 'Name',
          dataIndex: 'supplier_name',
          key: 'supplier_name',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
        {
          title: 'Product',
          dataIndex: 'product',
          key: 'product',
        },
        {
          title: 'Phone No.',
          dataIndex: 'phone_number',
          key: 'phone_number',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        
        {
          title: 'Action',
          key: 'action',
          render: (id) => (
            <Space size="middle">
              
            <h5 className="text-secondary">
            <Link to={`/dashboard/edit-supplier/${id._id}`}>

              <FaRegEdit/> 
               </Link> 
              
              
              </h5>
          <h5 className="text-danger">
              <DeleteConfirm confirm={(e)=>confirm(e, id)} title="supplier" cancel={cancel} >
                  <FaRegTrashAlt style={{cursor:"pointer"}} />
              </DeleteConfirm>
          </h5>

          </Space>
          ),
        },
      ];


   

    return (
      <Fade duration = {1000} >
        <div>
  
  <Table 
  rowKey={record => record._id} 
  columns={columns} 

  expandable={{
    expandedRowRender: record => {
      return <div style={{width:'500px'}}>
      {/* <Descriptions title="Products " > */}
        {/* <h6>Products</h6> */}

        <DescTable data = {record.products}/>



      {/* </Descriptions> */}
      </div>
  }, 
    rowExpandable: record => record.combo  !== null,
  }}

  dataSource={data} />
 
        </div>
          </Fade>
    )
}
