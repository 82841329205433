import React, {useEffect}  from 'react'
import { Form, Input, InputNumber, Button } from 'antd';
import {useDispatch, useSelector} from 'react-redux'
import {vendorAuthSelector} from '../../../api/auth'
import {createSupplier} from '../../../api/supplier'
import {fetchAllOrders} from '../../../api/product'

const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 12 },
  };

export default function CreateSupplier() {
  const { vendor} = useSelector(vendorAuthSelector)
  const [form] = Form.useForm();

const dispatch = useDispatch()

useEffect(()=>{
  vendor && dispatch(fetchAllOrders(vendor._id))
   }, [dispatch])
  

    const onFinish = values => {


        dispatch(createSupplier(values, vendor._id))

        form.resetFields()
    
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

    return (
        <div>
            <h5 >Create Supplier</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
   <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}    >
      <Form.Item
        label="Supplier name"
        name="supplier_name"
        rules={[{ required: true, message: 'supplier name is required' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="address"
        name="address"
        rules={[{ required: true, message: 'address is required' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Phone Number"
        name="phone_number"
      
        rules={[{ required: true },
      {min: 10},
      {max:10},
      {pattern:"[0-9]", message:"Only Numbers"}
      ]}
      >
        <Input />
      </Form.Item>


      <Form.Item
        label="product"
        name="product"
        rules={[{ required: true, message: 'product is required' }]}
      >
        <Input />
      </Form.Item>



  

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
        </div>
    )
}
