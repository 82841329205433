import React, {useEffect, useState} from 'react'
import { Form, Input, Button, InputNumber,Select, Upload  } from 'antd';
// import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {updateProductStock, productsSelector, fetchCurrentStore, fetchCurrentProduct} from '../../../api/product'
import {useParams} from 'react-router-dom'
import Loader from '../../shared/loader';
import { UploadOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../api/auth'
import axios from 'axios'
import { keyUri, config } from '../../../key';

const { Option } = Select;


const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};


export default function EditProduct() {
  
 
  const dispatch = useDispatch()

  const { currProduct} = useSelector(productsSelector)
  const [form] = Form.useForm();
  const {id} = useParams()


      // useEffect(()=>{
      //     form.setFieldsValue({
      //       stock:currProduct && currProduct.stock,          
      //       });
      // }, [currProduct])


      useEffect(()=>{
        dispatch(fetchCurrentProduct(id))
      }, [dispatch])
  

      const onFinish = (values) => {
        const imgdata = {
          stock:values.stock,
        }
        dispatch(updateProductStock(id, imgdata))
        form.resetFields()
        };

        const onFinishFailed = (errorInfo) => {
          console.log('Failed:', errorInfo);
        };

        
    return (          
    <>  
    {
      currProduct &&
   
           <div className="container ml-3">
              <h5 className="mb-5">Update Stock</h5>
              <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
      <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      form={form}
      onFinishFailed={onFinishFailed}
    >
      
 



          <Form.Item
          label={ <h6 style={{margin:0}}> stock number of units <span className="text-success">&#40;    {currProduct.stock}   &#41;</span> </h6>  }

            name='stock'
       
            rules={[{ required: true, message: 'stock is required' }]}
          >
            <InputNumber style={{ width:'50%' }} placeholder="Add new units" />
          </Form.Item>   
 




<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
 
    </Form>
           </div>
        }
               </>
           ) 
           }
       