import React, { useEffect, useState } from 'react'
import scanimg from '../../../images/scannerimage.PNG'
import {Modal, Button, Alert, Form, Input,Select, InputNumber, Radio, Upload} from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import BarcodeReader from 'react-barcode-reader'
import Axios from 'axios';
import { keyUri, config }  from '../../../key'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom';
import {vendorAuthSelector} from '../../../api/auth'
import {useSelector, useDispatch} from 'react-redux'
import {fetchCurrentStore,fetchAllOrders, productsSelector} from '../../../api/product'
const { Option } = Select;

export default function AddProduct() {
  const dispatch = useDispatch()
  const { vendor} = useSelector(vendorAuthSelector)
const [visible, setVisible] = useState(false);
const [isNewCategory, setNewCategory] = useState('existCategory') 

const [result, setResult] = useState('')
const [product, setProducts] = useState(null)
const [loading, setLoading] = useState(false)
const [msg, setMsg] = useState(null)
const [press, setPress] = useState(false)
const [isGlobal, setGlobal] = useState(false)
const [stock, setStock] = useState(0)
const [isNotfound, setIsNotfound] = useState(false)
const {currentCategories} = useSelector(productsSelector)



const [form] = Form.useForm();


!msg && setTimeout(()=>{

  setMsg(null)
  

}, 6000)




useEffect(()=>{
  vendor && dispatch(fetchAllOrders(vendor._id))

visible &&  Axios.get(keyUri.BACKEND_URI + `/product/${result}`, config).then((data=>{

    setIsNotfound(data.data.isNotfound)

    if(!data.data.isNotfound) {

      setProducts(data.data.product)


      !data.data.isglobal && setStock(data.data.product.stock)
   
      setGlobal(data.data.isglobal)
  
    console.log(data.data.product.category_name);
  
    form.setFieldsValue({
     
        quantity:data.data.product.quantity,
        product_name:data.data.product.product_name,
        category_name:  data.data.product.category_name 

      })
    }

}));

}, [visible, stock])


const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 12,
  },
};

const handleScan = (data) =>{

    if(data){   
    setResult(data)
    setVisible(true)
    }
    setPress(true)

}
  const handleError = (err) => {
    console.error(err)
  }



  const onChange = e => {
    // console.log('radio checked', e.target.value);
    setNewCategory(e.target.value)
  };

  const onFinish = async (values) =>{

    setLoading(true)
    if(press) {
       ;
       const {data} = await Axios.post(keyUri.BACKEND_URI + `/add-product/${product.barcode}`,
       { stock:values.stock, sale_price:values.sale_price , store:vendor._id, category_name:values.category_name, category_image:values.category_image }, config)
        
       
    setProducts(data.product)
    setStock(data.product.stock)
    setMsg(data.msg)  
    setGlobal(data.isglobal)
     setLoading(false)
    
    }
  
    setPress(true)

  
  }

  const onFinishFailed = (errorInfo) =>{
    console.log('Failed:', errorInfo);
  }

  const normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.file;
  };

  const  onSelectCategory = (value) => {
    console.log(`selected ${value}`);
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }




const closeModal = () =>{
setVisible(false)
setStock(0)
}



  return (
        <div  className="d-flex  justify-content-center align-items-center" height="100vh">
            <div >
            <h2 className="text-center text-success">Scan Product</h2>
          <img src={scanimg} width="100%" alt="scan"/>
          </div>

          <BarcodeReader
          onError={handleError}
          onScan={handleScan}
          />       

<Modal
footer={false}
visible={visible}
onCancel={closeModal}
closable = {false}
>

{

isNotfound && <div>

<h3 className="text-danger text-center">Product Not Found!</h3>

  <Link to='/dashboard/create-product'>
  <Button className="d-block mx-auto my-2" type="primary">  Create New Product</Button>
    
    </Link> 

</div>
}


{

!isNotfound && msg &&  <Fade><Alert className="mb-3" message={msg} type="success"   />
</Fade>
}


<div className="d-flex mb-3 align-items-center justify-content-around">
<p>BarCode: <b className="text-secondary">{result}</b></p>

 { product &&  <p>Current Stock : <b className="text-success">{ stock}</b></p>}

</div>


{

 loading ? "Loading..." :  !isNotfound && product   &&   <Form
    {...layout}
    name="basic"
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    form={form}
      >

<Form.Item
        label="enter stock"
        name="stock"
        rules={[{ required: true, message: 'Please input your stock!' }]}
      >
        <InputNumber  />
      </Form.Item>
{

isGlobal && <>

<Form.Item
        label="Sale Price"
        name="sale_price"

        rules={[{ required: true, message: 'Please input your stock!' }]}
      >
        <InputNumber  />
      </Form.Item>

                      
      <Form.Item name="isNewCategory" initialValue={isNewCategory} label="Select Category">
        <Radio.Group onChange={onChange} value={isNewCategory}>
          
          <Radio value="existCategory">Exist Category</Radio>
          <Radio value="newCategory">New Category</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
       label="Category"
       rules={[{ required: true, message: 'Please input Category Name'}]}

      >
      <Input.Group compact>
{
  (isNewCategory !== 'existCategory') ?  <> <Form.Item
  name='category_name'
  initialValue='cat'
  rules={[{ required: true, message: 'Category Name is required' }]}
>
  <Input style={{ width: '90%' }} placeholder="Category Name" />
</Form.Item>


  </> : <Form.Item
  name='category_name'
  
  rules={[{ required: true, message: 'Category Name is required' }]}
>
  
<Select
    showSearch
    style={{ width: 200 }}
    placeholder="Select Category"
    optionFilterProp="children"
    onChange={onSelectCategory}
    onSearch={onSearch}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
     {
currentCategories && currentCategories.map((item, i)=>{


  return  <Option key={i} value={item.category_name}>{item.category_name}</Option>

})
    }
  </Select>
  </Form.Item>
}
     

      </Input.Group>
      </Form.Item>


</>
}


      
      <Form.Item
        label="product Name"
        name="product_name"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input className="text-secondary bg-light" disabled={true} />
      </Form.Item>


      <Form.Item
      
        label="quantity"
        name="quantity"
        rules={[{ required: true, message: 'Please input quantity!' }]}
      >

        <InputNumber className="text-secondary bg-light" disabled={true} />

      </Form.Item>

      
   
<Form.Item {...tailLayout}>
        <Button type="primary" 
        
         htmlType="submit">
          Submit
        </Button>
      </Form.Item>          
</Form>



}



</Modal>



        </div>
    )
}



