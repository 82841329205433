import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import { keyUri, config } from '../key';
import {saveAs} from 'file-saver' 
import { logDOM } from '@testing-library/react';
import { LineHeightOutlined } from '@ant-design/icons';
import {logoutVendor} from './auth'


// let vendorChat = localStorage.getItem('vendorchat') ? 

// JSON.parse(localStorage.getItem('vendorchat')) : []



const initialState = {

    products:[],
    loading:false,
    hasError:false,
    currentCategories:[],
    currentSuppliers:[],
    allProducts:[],
    allCategories:[],
    currentOrder : null,
    orders : [],
    currProduct : null,


    users:[],
arrayProducts:[],

    liveOrders:[],
    currentChat:null,
    chatNotication: []

}


export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {

    getProducts: state => {
      state.loading = true;
    },
    getProductsSuccess: (state, {payload}) => {

      state.loading = false
      state.products = payload
    },

    getCategorySuccess: (state, {payload}) => {

      state.loading = false
      state.allCategories = payload
    },

    getCurrentProduct:  (state, {payload}) => {

      state.loading = false
      state.currProduct = payload
    },


    getProductByBarCode :(state) =>{
      state.loading = false
    },

    getCurrentStore:(state, {payload}) => {

      state.loading = false
      state.allProducts = payload.products
      state.currentCategories = payload.categories
      state.currentSuppliers = payload.supplier
      state.storeInfo = payload.store
    },

    getProductFailure: (state) => {

      state.loading = false
      state.hasError = true
    },


    getOrders: state => {
      state.loading = true
  },
  
  getCurrentOrder: (state, { payload }) => {
      state.loading = false
      state.currentOrder = payload

  },
  getOrderPdf: (state) => {

      state.loading = false
 },

  getOrderSuccess: (state, { payload }) => {

      state.orders = payload.order
      state.loading = false
      state.hasErrors = false
  },
  
  getLiveOrders : (state, {payload}) =>{

      state.loading = false
      state.liveOrders = [...state.liveOrders, payload] 
      state.currentChat = state.liveOrders[state.liveOrders.length - 1].order


  },

  getOrderFailure: state => {

      state.loading = false
      state.hasErrors = true
  },    

  getUsers: state => {
    state.loading = true
},

getUserSuccess: (state, { payload }) => {

  state.users = payload
  state.loading = false
  state.hasErrors = false
},  

getUserFailure: state => {

  state.loading = false
  state.hasErrors = true
},  

getChatNotification: (state,{payload}) =>{


 

  state.chatNotication =  [...state.chatNotication, payload];
  state.loading =false;

},

getArrayProduct: (state,{payload}) => {
  state.loading = false
  state.arrayProducts = payload
}

  },
});


export const {getArrayProduct,getCategorySuccess, getUserSuccess, getChatNotification, getUserFailure, getUsers, getProducts ,getLiveOrders, getCurrentStore,getProductsSuccess,getCurrentProduct, getProductFailure,getOrders,getCurrentOrder,getOrderSuccess, getOrderPdf, getOrderFailure } = productsSlice.actions;

export const productsSelector = state => state.products;



export const posArrayProduct = (values, p) => async dispatch => {


  dispatch(getProducts())
 try {
  
if(p)
 { const unique = values.filter((thing, index, self) =>
  index === self.findIndex((t) => (
    t._id === thing._id )) )


const pp =  unique.map((item) =>{

  if (item._id === p._id) {
if(item.unit < p.stock) {

 const updatedItem = {
          ...item,
          unit: item.unit + 1
        };


        return updatedItem;

}

message.warn('Out of Stock!')
return item;

       
  }
  return item;
})

  dispatch(getArrayProduct(pp));
  } 
  
else{
  dispatch(getArrayProduct(values));
}

 } catch (error) {
dispatch(getProductFailure())

 }
}


export const fetchProducts = (storeid) => async dispatch => {
  dispatch(getProducts())
 try {
  const {data} = await axios.get(keyUri.BACKEND_URI + `/store-product/${storeid}`, config)
 
  dispatch(getProductsSuccess(data.product));
 } catch (error) {
dispatch(getProductFailure())

 }
}

export const fetchCurrentProduct = (id) => async dispatch => {
  const key = 'loading';
  dispatch(getProducts())
  try {
      const { data } = await axios.get(keyUri.BACKEND_URI + `/one_product/${id}`, config);
      dispatch(getCurrentProduct(data));


    } catch (error) {
      dispatch(getProductFailure())
       }
      }



export const createProducts = (values, id) => async dispatch => {
  dispatch(getProducts())
  
  const key = 'create';
    message.loading({ content: 'loading...', key })
 
  try {
 
   const {data} = await axios.post(keyUri.BACKEND_URI +`/category/${id}`,values,config)
   data && message.success({content:data.msg, key, duration:2})
   dispatch(fetchProducts(data))
  //  window.location.reload()


  } catch ({response}) {
 response.data && message.error({content:response.data.msg, key, duration:2}) 
        response.data && dispatch(logoutVendor())
  }
 }


 export const deleteProducts = (id) => async dispatch =>{
    dispatch(getProducts())

    const key = 'create';
    message.loading({ content: 'loading...', key })

try {
    const {data} = await axios.delete(keyUri.BACKEND_URI +`/category/${id}`, config)
    window.location.reload()
    data && message.success({content:data.msg, key, duration:2})

} catch (error) {
  dispatch(getProductFailure())
  dispatch(logoutVendor())
}
}


export const deleteCurrentCategory = (id) => async dispatch =>{
  dispatch(getProducts())

  const key = 'create';
  message.loading({ content: 'loading...', key })

try {
  const {data} = await axios.delete(keyUri.BACKEND_URI +`/curr-category/${id}`, config)

  window.location.reload()
  data && message.success({content:data.msg, key, duration:2})

} catch (error) {
dispatch(getProductFailure())
    dispatch(logoutVendor())
}

}

export const fetchCurrentStore = (id) => async dispatch => {
  dispatch(getProducts())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/current-store/${id}`, config)

   dispatch(getCurrentStore(data));
  } catch (error) {

 dispatch(getProductFailure())
 
  }
 };


 export const  updateProduct = (id, values) => async dispatch =>{
  const key = "product"
  dispatch(getProducts())
  message.loading({ content: 'loading...', key })

  console.log(config);

try {
    const {data} = await axios.put(keyUri.BACKEND_URI +`/category/${id}`, values, config);
    data && message.success({ content: data.msg, key, duration: 2 });
    // dispatch(fetchProducts())
    // window.location.reload()

} catch ({response}) {

  console.log(response);
    dispatch(getProductFailure())
    response.data && message.error({ content: response.data.msg, key, duration: 2 });
    // response.data && dispatch(logoutVendor())
}
}


export const  updateProductStock = (id, values) => async dispatch =>{
  const key = "product"
  dispatch(getProducts())
  message.loading({ content: 'loading...', key })
try {
    const {data} = await axios.put(keyUri.BACKEND_URI +`/stock/${id}`, values, config);
    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(fetchProducts())
    window.location.reload()

} catch ({response}) {

    dispatch(getProductFailure())
    response.data && message.error({ content: response.data.msg, key, duration: 2 });
    response.data && dispatch(logoutVendor())

}
}

export const fetchAllOrders = (id) => async dispatch => {
  const key = 'loading';
  dispatch(getOrders())
  try {
      const { data } = await axios.get(keyUri.BACKEND_URI +`/orders/${id}`, config); 
      dispatch(getOrderSuccess(data))
  } catch (error) {

      console.log(error);

      // response.data.msg && message.error({ content: response.data.msg, key, duration: 2 });
      dispatch(getOrderFailure())
  }
}




export const createOrderPdf = (pdfValues) => async dispatch => {
  axios.post(keyUri.BACKEND_URI +'/create-pdf', pdfValues, config)
  .then(() => axios.get(keyUri.BACKEND_URI +'/fetch-pdf', { responseType: 'blob' }, config)) 
  .then((res) => {  
      const pdfBlob = new Blob([res.data], 
          { type: 'application/pdf' });
  saveAs(pdfBlob, `${pdfValues.orderId}.pdf`);      
}
)
}


export const fetchCurrentOrder = (id) => async dispatch => {
  const key = 'loading';

  dispatch(getOrders())
  try {
      const { data } = await axios.get(keyUri.BACKEND_URI + `/order/${id}`, config);
      dispatch(getCurrentOrder(data))

  } catch ({ response }) {
      // response.data.msg && message.error({ content: response.data.msg, key, duration: 2 });
      dispatch(getOrderFailure())

  }

}



export const fetchOrder = (id) => async dispatch => {
  dispatch(getOrders())
 try {
  const {data} = await axios.get(keyUri.BACKEND_URI + `/order/${id}`, config)
  
  dispatch(getOrderSuccess(data.products));
   
 } catch (error) {
dispatch(getOrderFailure())
 }
}



export const createOrder = (values,arrayProducts) => async dispatch => {
  let orderData = {
      orderProducts:arrayProducts,
      user:values
  }
  dispatch(getOrders())  
  const key = 'create';
    message.loading({ content: 'loading...', key })
  try {
   const {data} = await axios.post(keyUri.BACKEND_URI +`/offline_order`,orderData,config)
   data && message.success({content:data.msg, key, duration:2})
   dispatch(getArrayProduct([]))

  } 
  catch ({response}) {

 response.data && message.error({content:response.data.msg, key, duration:2})   
  }
 }



 export const  updateOrder = (id, values, store) => async dispatch =>{
    const key = "order"
    dispatch(getOrders())
    message.loading({ content: 'loading...', key })
  
  try {
      const {data} = await axios.put(keyUri.BACKEND_URI +`/order/${id}`, values, config);
   
      dispatch(fetchCurrentOrder(id))
      data && message.success({ content: data.msg, key, duration: 2 });

  
  } catch ({response}) {
      dispatch(getOrderFailure())
      response.data && message.error({ content: response.data.msg, key, duration: 2 });
      response.data && dispatch(logoutVendor())
  }
  }


  export const  updateOrderPayment = (id, values, store) => async dispatch =>{
    const key = "order"
    dispatch(getOrders())
    message.loading({ content: 'loading...', key })
  
  try {
      const {data} = await axios.put(keyUri.BACKEND_URI +`/order-payment/${id}`, values, config);
      data && message.success({ content: data.msg, key, duration: 2 });
      dispatch(fetchCurrentOrder(id))
  
  } catch ({response}) {
      dispatch(getOrderFailure())
      response.data && message.error({ content: response.data.msg, key, duration: 2 });
      response.data && dispatch(logoutVendor())
  }
  }
  

  export const  updateProductOrder = (id, values) => async dispatch =>{
      const key = "order"
      dispatch(getOrders())
      message.loading({ content: 'loading...', key })
    
    try {
        const {data} = await axios.put(keyUri.BACKEND_URI +`/product_order/${id}`, values, config);
        data && message.success({ content: data.msg, key, duration: 2 });
        // (fetchOrder(data))
    
    } catch ({response}) {
        dispatch(getOrderFailure())
        response.data && message.error({ content: response.data.msg, key, duration: 2 });
        response.data && dispatch(logoutVendor())
    }
    }
    
    export const fetchUsers = (id) => async dispatch => {
      dispatch(getUsers())
     try {
      const {data} = await axios.get(keyUri.BACKEND_URI + `/users/${id}`, config)
           dispatch(getUserSuccess(data));
       
     } catch (error) {
    dispatch(getUserFailure())
     }
    }
    

    
  export const  fetchLiveorder = (values) => async dispatch =>{
 
    try {

      dispatch(getLiveOrders(values))
   
    } catch (err) {

        dispatch(getOrderFailure())
    
    }
    }
    
 export const  fetchChatNotification = (values) => async dispatch =>{
 
    
    try {

      dispatch(getChatNotification(values))
    
    } catch (err) {

        dispatch(getOrderFailure())
    
    }
    }

 

export default productsSlice.reducer;


