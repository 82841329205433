import React, { useEffect, useState } from 'react'
// import {fetchProducts, productsSelector} from '../../../../api/adminPik/stores'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../shared/loader'
import DataTable from './datatable2'
import {Button, Input, Upload, message} from 'antd'
import { FilterOutlined, UploadOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom'
import {PlusOutlined} from '@ant-design/icons'
import { Select, Image } from 'antd';
import {vendorAuthSelector} from '../../../api/auth'
import {fetchProducts,fetchAllOrders, productsSelector} from '../../../api/product'
import { keyUri, config } from '../../../key';
import ExportExcel from './excelBtn'
import axios from 'axios'

const { Option } = Select;


export default function VenderIndex() {


const [filter, setFilter] = useState('product_name')
const [categories, setCategories] = useState(null)
const [currentCat, setCurreentCat] = useState()
const [searchvalue, setSearchvalue] = useState('')
const [loading, setLoading] = useState(false)
const [products, setProducts] = useState([])
const [pagination, setPagination] = useState({
  current: 1,
  pageSize: 10,

})


const {vendor} = useSelector(vendorAuthSelector)


  const dispatch = useDispatch()

console.log(vendor._id);
    

  useEffect(()=>{

    vendor && dispatch(fetchAllOrders(vendor._id))


  fetch2({ pagination })

  axios.get(keyUri.BACKEND_URI +  `/current-store-categories/${vendor._id}`, config).then((data=>{

    setCategories(data.data.categories)
  }))
 
    
  },[dispatch])

  
  const onChangeCategory = (value) =>{

    setCurreentCat(value)

    if(searchvalue) {

fetch2({

  pagination,
  category:value,
  search:searchvalue,



});

    }

    fetch2({

      pagination,
      category:value,

    });

  }

  const onChange = (value) =>{
   
      setFilter(value)
    
  }
  

  const onSearch = e => {
    const searchvalue = e.target.value
    setSearchvalue(searchvalue)

if(currentCat){

  fetch2({

    pagination,
    category:currentCat,
    search:searchvalue
 

  });


}


fetch2({

  pagination,
  category:currentCat,
  search:searchvalue,
  filter:filter

});

};


const uploadChange =  (info) =>{

  console.log(info);
  if(info.file.status === "error"){
    
      message.error({ content: 'Excel file only' });

    setTimeout(()=>{

     window.location.reload() 
    }, 1000) 
    
  } else if(info.file.status === "done") {

 message.success({ content: 'Extracted product succefully!' });
 
  setTimeout(()=>{
     window.location.reload() 
    }, 1000) 
  }

}



const showAllProducts = () => {
    window.location.reload()
  };



const  fetch2 = (params = {}) => {
const {current,  pageSize, } = params.pagination
const {category,  search, filter} = params


    setLoading(true)
   axios.get(keyUri.BACKEND_URI + `/store-product/${vendor._id}?category=${category}&search=${search}&skip=${current}&limit=${pageSize}&filter=${filter}`, config)
    .then(({data}) => {

      if(searchvalue){

    pagination.current = 1
    
      }
    
      setLoading(false)
      setProducts(data.product);
      setPagination({
        ...params.pagination,
           total: data.numberproduct
          })

    });
  };




  const handleTableChange = (pagination) => {

    fetch2({
   
    pagination,
    category:currentCat,
    search:searchvalue,
    filter:filter
    });

  };


console.log(pagination);


    return (
        <div>

 <div className="d-flex  mb-4 align-items-center justify-content-between">
   

<div className="d-flex align-items-center justify-content-start ">

<Input
      placeholder="Search Product"
      allowClear
      enterButton="Search"
      size="middle"
      onChange={onSearch}
      // onSearch={onSearch}
      className="mr-3"
      style={{ width: 200 }}
    />

<Select 
    style={{ width: 150 }}
    placeholder="Select filter"
    onChange={onChange}
    defaultValue="product_name"
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    <Option  value="product_name">Product Name</Option>
  {/* {currentCat && <Option value="categories">Category</Option>}   */}
    {/* <Option value="barcode">Barcode</Option> */}
  </Select>


</div>

<div className="d-flex align-items-center justify-content-start ">


<Select
    showSearch
    style={{ width: 150 }}
    className="mr-3"
    placeholder="Select Category"
    optionFilterProp="children"
    onChange={onChangeCategory}

    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {

categories && categories.map((item, i)=>{

        return <Option key={i} value={item._id}>{item.category_name}</Option>
      })
    }
    
  </Select>

  <div className="mr-3"> 
<Button type="ghost"  onClick={showAllProducts}>All Products </Button>
</div>


    <Upload
        onChange={uploadChange}
        action={keyUri.BACKEND_URI +`/categoryByExel/${vendor._id}`}
        listType="file"
        name="file"
        className="mr-3"
      >
      <Button icon={<UploadOutlined />}>Upload file</Button>
    </Upload> 


    <ExportExcel data={products}/>

    <Link to="/dashboard/create-product">
      <Button type="ghost" icon={<PlusOutlined />}>
            Create Product </Button></Link>

            </div>
    </div>
           
  <DataTable
    products={ products } 
    pagination={pagination}
    loading={loading}
    handleTableChange={handleTableChange}
    />
            
    </div>

    )
}





