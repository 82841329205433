import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import { keyUri, config } from '../key';
import {fetchVendorProfile} from './auth'
import {logoutVendor} from './auth'

const initialState = {

    current_store:null,
    loading:false,
    hasError:false,
    categories: []
  
}


export const settingSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {

    getStores: state => {
      state.loading = true;
    },



    getCurrentSuccess: (state, {payload}) =>{
        state.loading = false
        state.current_store = payload.store  
       state.categories = payload.categories
      },



        get_stores_Failure: (state) => {

          state.loading = false
          state.hasError = true
        },

      },
    })


export const { getStores ,getUpdate,  getCurrentSuccess,   get_stores_Failure } = settingSlice.actions;



export const settingsSelector = state => state.settings;




 export const fetchOneStore = (id) => async dispatch => {

  dispatch(getStores())
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI + `/current-store/${id}`, config)
   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_stores_Failure())
  }
 };



 export const  updateStore = (id, values, token) => async dispatch =>{

  const key = "store"
  dispatch(getStores())
  message.loading({ content: 'loading...', key })
  try {

    const {data} = await axios.put(keyUri.BACKEND_URI + `/store-setting/${id}`, values, config);
  
    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(fetchVendorProfile(token));
   
} catch ({response}) {

    dispatch(get_stores_Failure())
    response.data && message.error({ content: response.data.msg, key, duration: 2 });
    response.data && dispatch(logoutVendor())
}
}

export default settingSlice.reducer;
