import React from 'react'
import logo from './images/logo.png'
export default function Mobilestatic() {
    return (
        <div className="d-flex justify-content-center align-items-center bg-light" style={{height:"100vh"}}>
            <div>
            <img className="d-block mx-auto" src={logo} alt="logo" width="80%" />
            <h5 className="text-info text-center">Loading...</h5>
            </div>
        </div>
    )
}
