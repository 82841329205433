import React, {useEffect, useState} from 'react'
import { Form, Input, Button,Radio, Upload, InputNumber, Switch } from 'antd';
import { updateStore,fetchOneStore, settingsSelector} from '../../../api/settings'
import {vendorAuthSelector} from '../../../api/auth'
import {fetchAllOrders} from '../../../api/product'

import {useDispatch,useSelector } from 'react-redux'

import styled from 'styled-components'

const token = localStorage.getItem('token') ? localStorage.getItem('token') : null

export default function EditStore() {


  const layout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 4,
      span: 16,
    },
  };

  
  const dispatch = useDispatch()
  const {vendor} = useSelector(vendorAuthSelector)

  const {current_store} = useSelector(settingsSelector)

  const [available, setAvailable] = useState();
  const [delivery, setDelivery] = useState();
  const [exchange, setExchange] = useState();
  const [payment, setPayment] = useState();


  const [form] = Form.useForm();
  

  
  useEffect(()=>{
    vendor && dispatch(fetchAllOrders(vendor._id))

      dispatch(fetchOneStore(vendor._id ))
          
          form.setFieldsValue({
          
                 distance:vendor && vendor.distance / 1000,
                 deliveryCharges:vendor && vendor.deliveryCharges ,
                 minOrder:vendor && vendor.minOrder,
                 upi: vendor && vendor.upi
     
            });
            setAvailable(vendor.isAvailaible)
            setExchange(vendor.excahange)
            setDelivery(vendor.delivery)
            setPayment(vendor.payment)
  
            // window.location.reload()
      }, [dispatch,vendor])


  

  //     const normFile = e => {
  //       console.log('Upload event:', e);
  //       if (Array.isArray(e)) {
  //         return e;
  //       }
  //       return e && e.file;
  //     };



      const onFinish = (values) => {

        const storedata = {
          deliveryCharges:values.deliveryCharges,
          distance:values.distance * 1000,
          old_password:values.old_password,
          new_password:values.new_password,
          minOrder:values.minOrder,
          upi:values.upi


        }
        dispatch(updateStore(vendor._id, storedata, token))
        };


        const onFinishFailed = (errorInfo) => {
          console.log('Failed:', errorInfo);
        };



        const onChangeIsAvailaible = e => {
            const data = {
            isAvailaible: e.target.value,
            
        }
          dispatch(updateStore(vendor._id, data , token))
          setAvailable(e.target.value);
          
        };

          const onChangeDelivery = e => {
              const data = {
              delivery: e.target.value,
              
          }
            dispatch(updateStore(vendor._id, data, token ))
            setDelivery(e.target.value);
          };


          const onChangePayment = e => {
              const data = {
              payment: e.target.value,
          }
            dispatch(updateStore(vendor._id, data, token ))
            setPayment(e.target.value);
          };

          const onChangeExchange = e => {
              const data = {
              excahange: e.target.value,
          }
            dispatch(updateStore(vendor._id, data, token ))
            setExchange(e.target.value);
          };

    return (
      
      <SettingBlogWrap >

      <div className="d-flex px-2 align-items-center justify-content-between ">
      <div >
                  <h4 className="text-capitalize text-info"><b> {vendor.store_name}</b></h4>
                  <p>{vendor.store_address} </p>

                 
      </div>
      <div>
      <h6>Phone : {vendor.contact_number} </h6>
      <h6>Email : {vendor.email}  </h6>
      </div>

      </div>
            <hr style={{height:"0.1rem"}} className="mb-4 mt-1 bg-light border-0"/>
          <div className="ml-4 pl-5">
        <div > 

            <span>IsAvailaible : &nbsp; &nbsp;</span>
             <Radio.Group onChange={onChangeIsAvailaible} value={available}>
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
             </Radio.Group>

           </div>
       <br/>

     
           <div>
           <label> Exchange Accept : &nbsp; &nbsp; </label>
             <Radio.Group  onChange={onChangeExchange} value={exchange}>
             <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
             </Radio.Group>
           </div>
          
           <br/>


           <div>
           <label> Payment Enable :  &nbsp; &nbsp; </label>
             <Radio.Group  onChange={onChangePayment} value={payment}>
            <Radio.Button value="online">Online</Radio.Button>
            <Radio.Button value="pay_letter">Pay Letter</Radio.Button>
             </Radio.Group>
           </div>

           <br/>
           <div>
           <label> Delivery Available: &nbsp; &nbsp; </label>
             <Radio.Group  onChange={onChangeDelivery} value={delivery}>
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
             </Radio.Group>
           </div>       
        
           </div>
           <br/>
           <br/>


                 <Form
        {...layout}
        form={form}

        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

 {
   (delivery)?
   <>
   

     <Form.Item
       label="Delivery Charges"
       rules={[{ required: true, message: 'Please input delivery charges' }]}
      >
      <Input.Group compact>

      <Form.Item
            name={['deliveryCharges','above_one']}
            rules={[{ required: true, message: 'required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="above 1km" />
       </Form.Item>


       <Form.Item
            name={['deliveryCharges','above_two']}
       
            rules={[{ required: true, message: 'required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="above 2km" />
       </Form.Item>


          <Form.Item
            name={['deliveryCharges','above_three']}
       
            rules={[{ required: true, message: 'required' }]}
          >
            <InputNumber style={{ width: '90%' }} placeholder="above 3km" />
          </Form.Item>
         </Input.Group>
         </Form.Item>  
        
         <Form.Item
         label="Minimum Order"
            name='minOrder'
            rules={[{ required: true, message: 'Minimum order is required' }]}
          >
            <InputNumber style={{ width: '20%' }} placeholder="Minimum order Amount for free delivery" />
          </Form.Item>

         </>:<>
          </>
 }

         <Form.Item
         label="Store Visible Distance in km"
            name='distance'
            rules={[{ required: true, message: 'distance is required' }]}
          >
            <InputNumber style={{ width: '20%' }} placeholder="Distance in km" />
          </Form.Item>
          
          
         <Form.Item
         label="UPI ID"
            name='upi'
            // rules={[{ required: true, message: ' is required' }]}
          >
            <Input style={{ width: '30%' }} placeholder="Enter UPI ID" />
          </Form.Item>
 
          <Form.Item
       label="Change Password"
       rules={[{ required: true, message: 'Please Enter password' }]}
      >
      <Input.Group compact>

      <Form.Item
            name="old_password"
            // rules={[{ required: true, message: 'required' }]}
          >
            <Input.Password style={{ width: '90%' }} placeholder="Old Password" />
       </Form.Item>


          <Form.Item
            name="new_password"      
            // rules={[{ required: true, message: 'required' }]}
          >
             <Input.Password style={{ width: '90%' }} placeholder="New Password" />
          </Form.Item>
         </Input.Group>
         </Form.Item>  
       


<Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item> 





    </Form> 

    </SettingBlogWrap>
  
    ) 
    }

    
    const SettingBlogWrap = styled.div`
      `