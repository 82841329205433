import React, {useEffect, useState} from 'react'
import { Form,Empty, Input,Button, Select, InputNumber, Upload, Row, Col, Spin, Image} from 'antd';
import {useDispatch, useSelector} from 'react-redux'
import {updateCombo, comboSelector, fetchCurrentCombo} from '../../../api/combo'
import { productsSelector, fetchAllOrders} from '../../../api/product'
import {vendorAuthSelector} from '../../../api/auth'
import styled from 'styled-components'
import {useParams} from 'react-router-dom'
import Loader from '../../shared/loader';
import { UploadOutlined , PlusOutlined, DeleteOutlined} from '@ant-design/icons';
import Fade from 'react-reveal'
import { keyUri, config } from '../../../key';
import axios from 'axios'
const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};


export default function EditCombo() {
  
 
  const dispatch = useDispatch()
  const {currentCategories,allProducts} = useSelector(productsSelector)
  // const {combo} = useSelector(comboSelector)
  const [form] = Form.useForm();
  const {id} = useParams()
  const [products, setProducts] = useState([])
  const {vendor} = useSelector(vendorAuthSelector)
  const [categories, setCategories] = useState([])

  const [combo, setCurrCombo] = useState(null)

  const [loading, setLoading] = useState(false)
  const [comboProducts, setcomboProducts] = useState([])

  const [imgStatus, setImgStatus] = useState(false)
  


  useEffect(()=>{
  
    axios.get(keyUri.BACKEND_URI +`/combo/${id}`, config).then(({data})=>{
      setCurrCombo(data.combo)
    })

    }, [dispatch])


    useEffect(()=>{
      combo && setcomboProducts(combo.combo_product)

      combo  && form.setFieldsValue({
      combo_title:combo && combo.combo_title,
      // combo_image:combo && combo.combo_image,
      
      offer:combo && combo.offer,
      current_total:combo && combo.current_total,
      combo_product:combo && combo.combo_product,
      });

      }, [combo])
  


  let totalPrice = comboProducts.reduce((total, num )=>{
    return total+num.sale_price
  }, 0)
  
  const addItem = (item) => {
    let isExists = comboProducts.findIndex((p)=>{
      return(item._id === p._id)
    })
    if(isExists !== -1){
      return alert("product is already added")
    }
    setcomboProducts([...comboProducts, item])
        
    }
    
    
    const removeItem = (id) =>{
    let s = comboProducts.filter((item)=>{
    return item._id !== id
    
    })

    setcomboProducts(s)
     }
 
     useEffect(()=>{
       
      axios.get(keyUri.BACKEND_URI + `/current-store-categories/${vendor._id}`, config).then((data=>{
        setCategories(data.data.categories)

        dispatch(fetchCurrentCombo(id))
        combo && setcomboProducts(combo.combo_product)
          


      }))

      vendor && dispatch(fetchAllOrders(vendor._id))

    }, [dispatch])
      



  const  onChange = async (value) => {
    setLoading(true)
  
        const {data} = await axios.get(keyUri.BACKEND_URI +`/current-category/${value}`, config)
        
       
        data && setProducts(data.products);
        data && setLoading(false)
    }
    



      const layout = {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 16,
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 4,
          span: 16,
        },
      };
  

      const normFile = e => {
        console.log('Upload event:', e);

        if(e.file.status === 'removed')
      {
        setImgStatus(true)
      }
      if(e.file.status === 'done')
      {
        setImgStatus(false)
      }
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList[0];
        
      };
    
      const fileList =  [  
        {
          uid: '-1',
          name: 'Current image',
          status: 'done', 
          url: keyUri.BACKEND_URI + `/combo-image/${combo && combo.combo_image}`,
          thumbUrl: keyUri.BACKEND_URI + `/combo-image/${combo && combo.combo_image}`,
      }];
    

      const onFinish = (values) => {
        const combodata = {
          combo_title:values.combo_title,
          combo_image: values.combo_image ? values.combo_image.response.file.id : combo.combo_image,
          offer:values.offer,
          current_total:totalPrice,
          combo_product: comboProducts,
        }


        dispatch(updateCombo(id, combodata))
        };

        const onFinishFailed = (errorInfo) => {
          console.log('Failed:', errorInfo);
        };

        const onSearch = (val) => {
          console.log('search:', val);
        }
        
    return (          
<>  
       {
           loading ? <Loader/> : 
           <CreateBlogWrap>
        <h5 >Update Combo Offer</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
       
        <Row >
        <Col span={15} style={{overflowY:'auto', height:'75vh', overflowX:'hidden' }}>
          <div className="container ml-3">
          
        { (combo && combo.combo_title) && <Form
      {...layout}
      name="basic"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
    <Form.Item
       label="Combo Title"
       name='combo_title'
       initialValue={combo.combo_title}
       rules={[{ required: true, message: 'combo title is required' }]}
          >
            <Input style={{ width: '60%' }} placeholder="Combo Title" />
      </Form.Item>

      <Form.Item
       label="Combo Image"
       rules={[{ required: true, message: 'image is required!' }]}
       >

    <Input.Group compact>
 
      <Form.Item
        name="combo_image"
        valuePropName="image"
        getValueFromEvent={normFile}
        // rules={[
        //   {
        //     required: true,
        //     message: 'image is required!',
        //   },
        // ]}
      >
        <Upload name="combo_image" action= {keyUri.BACKEND_URI +"/combo-image"}
        maxCount={1}
        listType="picture"  defaultFileList={[...fileList]} >
        <Button disabled={imgStatus ? false : true} icon={<UploadOutlined />}>Upload Combo Image</Button>
        </Upload>
        
      </Form.Item> 

      </Input.Group>
      </Form.Item>


      <Form.Item
       label="Product"
       rules={[{ required: true, message: 'category is required' }]}
          >
            
            <Form.Item
            name='combo_product'
      >
                
        <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select Category"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
        categories && categories.map((item, i)=>{


          return  <Option key={i} value={item._id}>{item.category_name}</Option>

        })
            }
          </Select>

    {
      loading ?  <h5 className=" my-3"><Spin className="ml-5" tip="Loading..."/> </h5> : 
      
      products.map((item,i)=>{
        
        let productExist = comboProducts.findIndex((pr)=>{
            return pr._id === item._id
        })


      return <Fade bottom distance="50px" key={i}>  
      <Row align="middle" justify="start" className="my-2 shadow-sm p-2" style={{ width: '95%' }}> 

      <Col span={12}> 

      <Image
        width="40px"
        src={keyUri.BACKEND_URI + `/product-image/${item.image}`}
        placeholder={
          <Image
            preview={false}
            src="https://i.pinimg.com/originals/2c/bb/5e/2cbb5e95b97aa2b496f6eaec84b9240d.gif"
            width="100%"
          />
        }
      />

      <span style={{fontSize:"1.2rem", marginLeft:"1.5rem"}}>{ item.product_name} </span>
       </Col>

      <Col span={6}><h6> &#x20B9; { item.sale_price}</h6> </Col>
    {
      
      (productExist === -1)  ? <Col span={2}><Button type="ghost" style={{ color: "green", borderColor: "green" }} icon={<PlusOutlined />} onClick={()=>{ addItem(item) }} > Add </Button></Col> :
      <Col span={2}><Button type="primary" style={{ color: "red", background: "transparent", borderColor: "red" }} icon={<DeleteOutlined />} onClick={()=>{ removeItem(item._id) }} > Remove </Button></Col>
    }

      </Row> 
      </Fade>


    } )
  }
  </Form.Item>
 </Form.Item>


 <Form.Item
       label="Total Price"
       name='current_total'
       initialValue={combo.current_total}
      >
      
            <Input  style={{ width: '25%' }} placeholder={totalPrice} disabled/>
            
      </Form.Item>


      <Form.Item
       label="Offer %"
       name='offer'
       initialValue={combo.offer}
       rules={[{ required: true, message: 'offer price is required' }]}
          >
            <Input style={{ width: '25%' }} placeholder="% " />
      </Form.Item>


<Form.Item wrapperCol={{ span: 20, offset: 4}}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
   
  
          </Form> }
        </div>
          </Col>  
        <Col span={8} className="shadow-sm rounded" style={{ backgroundColor:"#fbfbfb", padding:'20px 10px 20px 50px', overflowY:'auto', height:'75vh' }}>
            <h6>Selected Combo Products:</h6>

              {             

           ( combo && comboProducts.length < 1) ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : combo && comboProducts.map((item,i)=>{

                    return <Fade bottom distance="50px" key={i}>  
               
                 <Row align="middle" justify="start" className="my-2 shadow-sm p-2" style={{ width: '90%' }}> 

                 <Col span={12}> 

                <Image
                  width="40px"
                  src={keyUri.BACKEND_URI + `/product-image/${item.image}`}
                  placeholder={
                    <Image
                      preview={false}
                      src="https://i.pinimg.com/originals/2c/bb/5e/2cbb5e95b97aa2b496f6eaec84b9240d.gif"
                      width="100%"
                    />
                  }
                />


                    <span style={{fontSize:"1.2rem", marginLeft:"1.5rem"}}>{ item.product_name} </span>
                    </Col>

                    <Col span={5}><h6> &#x20B9; { item.sale_price}</h6> </Col>

                    <Col span={2}><Button type="primary" style={{ color: "red", background: "transparent", borderColor: "red" }} icon={<DeleteOutlined />} onClick={()=>{ removeItem(item._id) }} >  Remove </Button></Col>

                    </Row> </Fade>

                })
              }
         
          </Col>  
            
        </Row>
       

      </CreateBlogWrap>
       
       }
               </>
           ) 
           }
           const CreateBlogWrap = styled.div`


           `