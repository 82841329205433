import React from 'react'
import { Table,  Space } from 'antd';
import {useDispatch} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {Link} from 'react-router-dom'
import {deleteProducts} from '../../../api/product'


export default function Datatable({products, pagination, loading, handleTableChange }) {


        const dispatch = useDispatch()


        const confirm = (e, id) => {
            dispatch(deleteProducts(id._id))         
          }
          
          const cancel = (e) =>{
            return null
          }



    const columns = [
        {
          title: 'Product',
          dataIndex: 'product_name',
          key: 'product_name',
          render: text => <h6 style={{color:'var(--acentColor)'}} className="text-capitalize">{text}</h6>,
        },
    
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          render:(quantity) =>{
            return <p>{quantity} </p>
          }   
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            render: (stock)=>{

              return stock <= 0 ? <h6 style={{color:'red'}}>{stock}</h6> : stock < 10 ? <h6 style={{color:'#f58142'}}>{stock}</h6> : <h6 style={{color:'green'}}>{stock}</h6>
              }
        },
    // {
    //       title: 'MRP',
    //       dataIndex: 'MRP',
    //       key: 'MRP',
    //       render:(MRP) =>{

    //         return <p>&#x20B9;{MRP} </p>
    //       }
    //     },
        {
          title: 'Action',
          key: 'action',
          render: (id) => {


          return  <Space size="middle">
                <h5 className="text-secondary">
               <Link to={`/dashboard/edit-inventory/${id._id}`}>
                <FaRegEdit/> 
                 </Link>  
                   {/* <h5 className="text-secondary" onClick={()=>showModal(value)}> <FaRegEdit/>  </h5> */}
                 </h5>

            <h5 className="text-danger">
                <DeleteConfirm confirm={(e)=>confirm(e, id)} title="product" action="edit" cancel={cancel} >
                    <FaRegTrashAlt style={{cursor:"pointer"}} />
                </DeleteConfirm>
            </h5>

            </Space>
        },
        },
      ];

    return (
      <Table
  columns={columns}

  dataSource={products}
  pagination={pagination}
  loading={loading}
  onChange={handleTableChange}
/>
    )
}
