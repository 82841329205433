import React, { useEffect, useState } from 'react'
import Loader from '../../shared/loader'
import styled from 'styled-components'
import { BarChartOutlined, FundOutlined, DiffOutlined, TeamOutlined } from '@ant-design/icons';
import {vendorAuthSelector} from '../../../api/auth'
import {Link} from 'react-router-dom'
import socket from '../../../socket'
import {Affix, message} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {Button,Avatar, Row, Col, notification} from 'antd'
import Orders from './orders'

import MonthOrderGraph from './monthOrderGraph'

import axios from 'axios'
import { keyUri, config } from '../../../key';
import {fetchLiveorder, fetchAllOrders, fetchChatNotification,fetchUsers, productsSelector} from '../../../api/product'
import removeDuplicate from '../../shared/removeDuplicate'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import ExportMonthExcel from './monthExcel'
import { AiFillStar } from 'react-icons/ai';

export default function DashIndex() {


const [monthChart, setMonthChartData] = useState([])

const {vendor} = useSelector(vendorAuthSelector)
const { loading, orders, currentChat , users} = useSelector(productsSelector)
const [copied, setCopied] = useState(false)
const [currentOrder, setCurrentOrder] = useState()

const [curr_onlineCustomer, SetCurrOnlineCustomer] = useState([])

const [curr_offlineCustomer, SetCurrofflineCustomer] = useState([])
const [onlineUserRate, SetOnlineUserRate] = useState([])
const [offlineUserRate, SetOfflineUserRate] = useState([])

const [saleRate, SetsalesRate] = useState([])
const [orderRate, SetOrderRate] = useState([])

const [curr_monthOrder, SetCurr_monthOrder] = useState([])
const [curr_sales, Setcurr_sales] = useState(null)


const dispatch = useDispatch()


useEffect(()=>{

  axios.get(keyUri.BACKEND_URI + `/sales-data/${vendor._id}`, config).then((data=>{
    Setcurr_sales(data.data.curr_sales)
    SetCurr_monthOrder(data.data.curr_order)

    SetOrderRate(data.data.orderRate)
    SetsalesRate(data.data.saleRate)

  })) 

  axios.get(keyUri.BACKEND_URI + `/users-stats/${vendor._id}`, config).then((data=>{
    SetCurrofflineCustomer(data.data.curr_offlineunique)
    SetCurrOnlineCustomer(data.data.curr_onlineunique)

    SetOnlineUserRate(data.data.onlineUserRate)
    SetOfflineUserRate(data.data.offlineUserRate)

})) 



    axios.get(keyUri.BACKEND_URI + `/fetch-monthchart/${vendor._id}`, config).then((data=>{
      setMonthChartData(data.data)
  })) 


  },[])



  useEffect(()=>{
    dispatch(fetchAllOrders(vendor._id))
    dispatch(fetchUsers(vendor._id))

  },[dispatch])



  const openNotification = (data) => {

    const args = {
      message: 'New Order Placed',
      duration: 0,
      btn: <a href={`/dashboard/order-view/${data.order._id}`}>view order</a>
    };
    notification.success(args);
  };

  let Unfulorders = orders.filter(item=>{

    return item.orderStatus === 'Unfulfilled'
  })
 


    let dashcard = [
        {
            title:<span>Orders</span>,
            icon:<BarChartOutlined/>,
            stat:curr_monthOrder && curr_monthOrder.length,
            desc:orderRate > 0 ? <span className="text-success">+ {orderRate}% from last month</span> :  
                               <span className="text-danger"> {orderRate}% from last month</span> ,
            backColor:"#263055",
            link:`orders`
        },
        {
            title:<span> Online Customers</span>,
            icon:<TeamOutlined/>,
            stat: curr_onlineCustomer && curr_onlineCustomer.length,
            desc: onlineUserRate > 0 ? <span className="text-success">+ {onlineUserRate}% from last month</span> :
                                     <span className="text-danger"> {onlineUserRate}% from last month</span>,
            backColor:"#1BC943",
            link:`customers`
        },
        {
            title:<span> Offline Customers</span>,
            icon:<TeamOutlined/>,
            stat:curr_offlineCustomer && curr_offlineCustomer.length,
            desc: offlineUserRate >0 ? <span className="text-success">+ {offlineUserRate}% from last month</span>:
                           <span className="text-danger"> {offlineUserRate}% from last month</span>,
            backColor:"#F83245",
            link:`customers`
        },
        {
            title:<span>Sales</span>,
            icon:<DiffOutlined/>,
            stat: <span> &#x20B9;{curr_sales}</span>,
            desc: saleRate >0 ? <span className="text-success">+ {saleRate}% from last month</span>:
                     <span className="text-danger"> {saleRate}% from last month</span>,
            backColor:"#5383FF",
            link:`analytics`
        }
    ]


    const [affixui, setAffixUi] = useState(false);
const [container, setContainer] = useState(null);
const [connected, setConnected] = useState(null);


useEffect(()=>{


    socket.on(vendor.email, (data)=>{

        if(data.isnotify){
           
            
 fetchChatNotification(data)


 return  openNotification(data)


        }
        openNotification(data)

        setConnected(true)
        setCurrentOrder(data.orderId)
        dispatch(fetchLiveorder(data))


    });


    // unsubscribe from event for preventing memory leaks
    return () => {
       socket.off(vendor._id, ()=>{

        setConnected(true)


       });
    };

}, [currentOrder])

const rating = vendor.rating.reduce((total, current)=>{

  return total =+ current

}, 0)

console.log(affixui);

    return (
        <HomwWrap>
           { vendor && <div className='d-flex justify-content-between'> 
             <h5 className="text-uppercase"> welcome <b className="text-info">{vendor.store_name}</b></h5>
              
            <div className="d-flex align-items-center"> 
              <div className="mr-4">
                              <h6 className="mb-0">Store Rating:&nbsp; <b style={{color:rating >= 4? "green" : "red"}}>{rating} <AiFillStar/></b> </h6>

              </div>

            <CopyToClipboard text={`https://stores.piklocal.com/store/${vendor._id}`}  
            onCopy={() => {setCopied(true) 
              message.info('Copied to clipboard')  }}>
            <Button type="text" className=" px-2 shadow-sm"  > Share your Store <CopyOutlined style={{transform:'translateY(-2px)'}} /></Button>
            </CopyToClipboard> 
            </div>
            </div> }

              <hr style={{height:"0.1rem"}} className="mt-3 bg-light border-0"/>

              <h5 className="mx-2 text-secondary mb-2"><b className="text-info">{moment().format('MMMM')}</b> Month Statistics </h5>

            <Row gutter={20}>          
               {
                 dashcard.map((item, i)=>{

                    return <Col key={i} span={6}>
                    <Link to={item.link}>
                    <div className=" customcard">

                    <div>

                      <h6 className="text-secondary">
                           <Avatar size="large" className="mr-1" style={{backgroundColor:item.backColor}} icon={item.icon} /> {item.title}
                           
                    </h6> 
                    </div>
                      <div className="text-center">
                     <h1 className="mb-2">{item.stat}</h1>
                     <p className="text-secondary">{item.desc}</p>
                    </div>
                    </div>
                    </Link>  
                    </Col>

                 })
               }
            </Row>
            <br/>
   <div className="row  sec2">         
  
               <div className="col-sm-7  order bg-light" ref={setContainer}>
<Affix target={() => container}  onChange={affixed => setAffixUi(affixed)}>
                    <h3 style={{padding:"10px"}} className={affixui && 'shadow-sm  bg-white'}>Orders</h3>
</Affix>
                   <Orders loading={loading} data={Unfulorders}  />
                    
              </div>
    

              <div className=" col-sm-5    graph">
                <h3 className="mx-3 text-secondary mb-0"><b className="text-info">{moment().format('MMMM')}</b> Month Order  <ExportMonthExcel data={monthChart}/></h3>
               <br/>
               <br/>
                <MonthOrderGraph data={monthChart} />
                </div>

               
            </div>


       
        </HomwWrap>

            

    )
}



const HomwWrap =  styled.div`
.sec2{
 .order, .graph {
padding:1rem;
border-radius:0.5rem;
/* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); */
height: 46vh;
    overflow-y: auto;
    overflow-x: hidden;

    h3{

      font-size: 1.3rem;
    }

  } 

 
}

.customcard {
padding:1rem;
border-radius:0.5rem;
box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
h1{
    font-weight:300;
    color:#070919;
}

svg {
    color:white;
    transform:translateY(-6px);
    font-size:1.1rem;
}    
}
`