import React,  {useEffect, useState} from 'react'
import { Form, Input, InputNumber, Button,message } from 'antd';
import {useDispatch, useSelector} from 'react-redux'
import {vendorAuthSelector} from '../../../api/auth'
import {useParams} from 'react-router-dom'
import {supplierSelector, fetchCurrentSupplier, updateSupplier} from '../../../api/supplier'
import {fetchAllOrders} from '../../../api/product'

const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 12 },
  };

export default function EditSupplier() {
  const { vendor} = useSelector(vendorAuthSelector)
  const {supplier} = useSelector(supplierSelector)
  const [form] = Form.useForm();
  const {id} = useParams()


const dispatch = useDispatch()


useEffect(()=>{
  dispatch(fetchCurrentSupplier(id))  
  vendor && dispatch(fetchAllOrders(vendor._id))

  }, [dispatch])

  useEffect(()=>{
   
            form.setFieldsValue({
              supplier_name:supplier && supplier.supplier_name,
              address:supplier && supplier.address,
              phone_number:supplier && supplier.phone_number,
              product:supplier && supplier.product,
              });
        }, [supplier])
  
            
      const onFinish = (values) => {
      
      var numbers = /^[0-9]+$/;
        if(!values.phone_number.match(numbers))
        {
          message.error("Phone number must be only numbers")
          return true;
        }
        if(values.phone_number.length !== 10){
          message.error("Phone number must be 10 numbers")
        }
else{


        const supplierdata = {
          supplier_name:values.supplier_name,
          address:values.address,
          phone_number:values.phone_number,
          product:values.product,
        
        }

  dispatch(updateSupplier(id, supplierdata))
      }
};
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

    return (
        <div>
            <h5 >Edit Supplier</h5>
            <hr style={{height:"0.1rem"}} className="my-4 bg-light border-0"/>
   <Form
      {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Supplier name"
        name="supplier_name"
        rules={[{ required: true, message: 'Please input supplier name!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Address"
        name="address"
        rules={[{ required: true, message: 'Please input your address!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="phone Number"
        name="phone_number"
        rules={[{ required: true, message: 'Please input your phone Number!' }]}
      >
        <Input />
      </Form.Item>


      <Form.Item
        label="Product"
        name="product"
        rules={[{ required: true, message: 'Please input product!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
        </div>
    )
}
